<template>
  <div v-if="editorKey">
    <div class="field grid">
        <label for="year" class="col-12 md:col-2">{{ $t('common.year') }}</label>
        <div class="col md:col-6">
        <YearEditor v-model="year" />
        </div>
    </div>
    <div class="field grid">
      <label for="licensor" class="col-12 mb-2 md:col-2 md:mb-0">{{ $t('medias.licensor') }}</label>
      <div class="col-2">
        <AutoCompleteSearch
          id="licensor"
          v-model="licensor"
          itemValue="id"
          field="name"
          dropdown
          dropdownIcon="search"
          :suggestions="listingLicensors"
          :suggestionsTotal="listingLicensorsTotal"
          @complete="searchLicensors($event)"
        />
      </div>
    </div>
    <div class="field grid">
      <label for="content_owner" class="col-12 mb-2 md:col-2 md:mb-0">{{ $t('medias.contentOwner') }}</label>
      <div class="col-2">
        <AutoCompleteSearch
          id="content_owner"
          v-model="contentOwner"
          itemValue="id"
          field="name"
          dropdown
          dropdownIcon="search"
          :suggestions="listingContentOwners"
          :suggestionsTotal="listingContentOwnersTotal"
          @complete="searchContentOwners($event)"
        />
      </div>
    </div>
    <div class="field grid">
      <label for="provider" class="col-12 mb-2 md:col-2 md:mb-0">{{ $t('medias.provider') }}</label>
      <div class="col-2">
        <AutoCompleteSearch
          id="provider"
          v-model="provider"
          itemValue="id"
          field="name"
          dropdown
          dropdownIcon="search"
          :suggestions="listingProviders"
          :suggestionsTotal="listingProvidersTotal"
          @complete="searchProviders($event)"
        />
      </div>
    </div>
    <div class="field grid">
        <label for="copyright" class="col-12 mb-2 md:col-2 md:mb-0">{{ $t('common.copyright') }}</label>
        <div class="col md:col-8">
          <InputText id="copyright" type="text" class="w-full" v-model="copyright" />
        </div>
    </div>
  </div>
</template>

<script>

import InputText from 'primevue/inputtext'
import AutoCompleteSearch from '@/components/base/AutoCompleteSearch.vue'
import { mapActions, mapGetters } from 'vuex'

import YearEditor from '@/components/resource/YearEditor.vue'

import { useFlowCruder, useFlowEditor } from '@/compositions'

const KOptionsName = 'EditingMediaPdf'

export default {
  name: KOptionsName,
  props: { editorKey: String },
  setup (props) {

    const {setError, ...use} = useFlowEditor(props.editorKey)

    return {
      ...use,
    //   v$,
      setError,
      ...useFlowCruder()
    }
  },
  components: {
    InputText, AutoCompleteSearch,
    YearEditor
  },
  data: () => ({
    listingLicensors: [],
    listingLicensorsTotal: 0,
    listingContentOwners: [],
    listingContentOwnersTotal: 0,
    listingProviders: [],
    listingProvidersTotal: 0
  }),
  watch: {
    'v$.$invalid': function (val) {
      val ? this.setError(new Error('InvalidForm'), KOptionsName) : this.setError(null, KOptionsName)
    }
  },
  computed: {
    ...mapGetters('flowPortalmanagerMedia', ['doc', 'fieldGet']),
    year: {
      get () { return this.fieldGet('media.year') },
      set (val) { this.fieldSet({field: 'media.year', value: val}) }
    },
    licensor: {
      get () { return this.fieldGet('licensor') },
      set (val) { this.fieldSet({field: 'licensor', value: val}) }
    },
    contentOwner: {
      get () { return this.fieldGet('contentOwner') },
      set (val) { this.fieldSet({field: 'contentOwner', value: val}) }
    },
    provider: {
      get () { return this.fieldGet('provider') },
      set (val) { this.fieldSet({field: 'provider', value: val}) }
    },
    copyright: {
      get () { return this.fieldGet('copyright') },
      set (val) { this.fieldSet({field: 'copyright', value: val}) }
    },
  },
  methods: {
    ...mapActions('flowPortalmanagerMedia', ['fieldSet', 'refresh', 'force']),
    async searchLicensors ({query}) {
      try {
        const qryBuilder = this.$pxstream.tools.createQueryBuilder()
        qryBuilder.setFilterSearch(query)
        qryBuilder.addFilter('contentTypes.id', 'contains', [this.fieldGet('contentType.id')])
        qryBuilder.sortField = 'name'
        qryBuilder.sortOrder = 1
        const {data, total} = await this.$pxstream.resource.getLicensors(qryBuilder.build())
        if (total <= 0) {
          this.listingLicensors = []
        } else {
          this.listingLicensors = data
          this.listingLicensorsTotal = total
        }
      } catch (err) {
        this.$toast.add({severity: 'error', summary: 'Failed to get licensors', detail: this.$pxstream.http.getHttpErrorShortMessage(err), life: 4000})
      }
    },
    async searchContentOwners ({query}) {
      try {
        const qryBuilder = this.$pxstream.tools.createQueryBuilder()
        qryBuilder.setFilterSearch(query)
        qryBuilder.addFilter('contentTypes.id', 'contains', [this.fieldGet('contentType.id')])
        qryBuilder.sortField = 'name'
        qryBuilder.sortOrder = 1
        const {data, total} = await this.$pxstream.resource.getContentOwners(qryBuilder.build())
        if (total <= 0) {
          this.listingContentOwners = []
        } else {
          this.listingContentOwners = data
          this.listingContentOwnersTotal = total
        }
      } catch (err) {
        this.$toast.add({severity: 'error', summary: 'Failed to get content owners', detail: this.$pxstream.http.getHttpErrorShortMessage(err), life: 4000})
      }
    },
    async searchProviders ({query}) {
      try {
        const qryBuilder = this.$pxstream.tools.createQueryBuilder()
        qryBuilder.setFilterSearch(query)
        qryBuilder.addFilter('contentTypes.id', 'contains', [this.fieldGet('contentType.id')])
        qryBuilder.sortField = 'name'
        qryBuilder.sortOrder = 1
        const {data, total} = await this.$pxstream.resource.getProviders(qryBuilder.build())
        if (total <= 0) {
          this.listingProviders = []
        } else {
          this.listingProviders = data
          this.listingProvidersTotal = total
        }
      } catch (err) {
        this.$toast.add({severity: 'error', summary: 'Failed to get providers', detail: this.$pxstream.http.getHttpErrorShortMessage(err), life: 4000})
      }
    }
  }
}
</script>