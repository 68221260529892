<template>
  <div v-if="editorKey" class="grid">
    <div class="col-12 md:col-7">

      <div class="field grid">
        <label for="cast" class="col-12 md:col-3 font-bold">Cast</label>
        <div class="col md:col-8">
          <span class="p-fluid flex">
            <AutoCompleteSearch
              v-model="cast"
              itemValue="id"
              field="name"
              dropdown
              dropdownIcon="search"
              multiple
              :suggestions="listingActors"
              :suggestionsTotal="listingActorsTotal"
              @complete="searchActors($event)"
            />
            <Button icon="pi pi-plus" class="p-button ml-1" @click="openNewActorForm('cast')"/>
          </span>
        </div>
      </div>

      <div class="field grid">
        <label for="directors" class="col-12 md:col-3">Directors</label>
        <div class="col md:col-8">
          <span class="p-fluid flex">
            <AutoCompleteSearch
              v-model="directors"
              itemValue="id"
              field="name"
              dropdown
              dropdownIcon="search"
              multiple
              :suggestions="listingDirectors"
              :suggestionsTotal="listingDirectorsTotal"
              @complete="searchDirectors($event)"
            />
            <Button icon="pi pi-plus" class="p-button ml-1" @click="openNewActorForm('director')"/>
          </span>
        </div>
      </div>

      <div class="field grid">
        <label for="genres" class="col-12 md:col-3">Genres</label>
        <div class="col md:col-8">
          <span class="p-fluid">
            <AutoCompleteSearch
              v-model="genres"
              itemValue="id"
              field="name"
              dropdown
              dropdownIcon="search"
              multiple
              :suggestions="listingGenres"
              :suggestionsTotal="listingGenresTotal"
              @complete="searchGenres($event)"
             />
          </span>
        </div>
      </div>

      <div class="field grid">
        <label for="ratingUS" class="col-12 md:col-3">{{ $t('medias.ratingUs') }}</label>
        <div class="col md:col-8">
          <span class="p-fluid">
            <AutoComplete
              v-model="ratingUS"
              itemValue="id"
              field="name"
              dropdown
              dropdownIcon="search"
              :suggestions="listingRatingsUS"
              @complete="searchRatingsUS($event)"
              @update:modelValue="checkRatingUS()"
              class="w-full" />
          </span>
        </div>
      </div>

      <div class="field grid">
        <label for="rating" class="col-12 md:col-3">{{ $t('medias.rating') }}</label>
        <div class="col md:col-8">
          <RatingEditor v-model="rating" />
        </div>
      </div>

      <div class="field grid">
        <label for="hasViolence" class="col-12 md:col-3">Violence</label>
        <div class="col md:col-8">
          <InputSwitch v-model="hasViolence" id="hasViolence" :disabled="ratingUS.id === 'PG13'" />
        </div>
      </div>

      <div class="field grid">
        <label for="hasOffensiveLanguage" class="col-12 md:col-3">{{ $t('medias.offensiveLanguage') }}</label>
        <div class="col md:col-8">
          <InputSwitch v-model="hasOffensiveLanguage" id="hasOffensiveLanguage" :disabled="ratingUS.id === 'PG13'" />
        </div>
      </div>

      <div class="field grid">
        <label for="hasSex" class="col-12 md:col-3">{{ $t('medias.sex') }}</label>
        <div class="col md:col-8">
          <InputSwitch v-model="hasSex" id="hasSex" :disabled="ratingUS.id === 'PG13'"/>
        </div>
      </div>


    </div>
    <div class="col-12 md:col-5">
      <div class="field grid">
        <label for="year" class="col-12 md:col-6">{{ $t('common.year') }}</label>
        <div class="col md:col-6">
          <YearEditor v-model="year" />
        </div>
      </div>

      <div class="field grid">
        <label for="country" class="col-12 md:col-6">{{ $t('common.countries') }}</label>
        <div class="col md:col-6">
          <span class="p-fluid">
            <AutoCompleteSearch
              v-model="countries"
              itemValue="id"
              field="name"
              dropdown
              dropdownIcon="search"
              multiple
              :suggestions="listingCountries"
              @complete="searchCountries($event)"
              :suggestionsTotal="listingCountriesTotal"
            />
          </span>
        </div>
      </div>


      <div class="field grid">
        <label for="runtime" :class="{'p-error':runtimeError, 'col-12 md:col-6 font-bold': true}">{{ $t('medias.runtime') }}</label>
        <div class="col md:col-6">
          <RuntimeEditor :invalid="runtimeError" v-model="runtime" />
        </div>
      </div>


      <div class="field grid">
        <label for="version" :class="{'p-error': versionError, 'col-12 md:col-6 font-bold': true}">Version</label>
        <div class="col md:col-6">
          <span class="p-fluid">
            <AutoComplete
              v-model="version"
              itemValue="name"
              field="name"
              dropdown
              :suggestions="listingVersions"
              @complete="searchVersion($event)"
              :class="{'w-full': true, 'p-invalid': versionError}" />
            <small v-if="versionError" class="p-error">{{versionError}}</small>
          </span>
        </div>
      </div>

    </div>
    <ActorNew v-model="this.ui.showCreateActor" @newActor="addActor($event)" no-router />
  </div>
</template>

<script>
import { computed } from 'vue'

import AutoComplete from 'primevue/autocomplete'
import AutoCompleteSearch from '@/components/base/AutoCompleteSearch.vue'
import InputSwitch from 'primevue/inputswitch'

import RatingEditor from '@/components/resource/RatingEditor.vue'
import YearEditor from '@/components/resource/YearEditor.vue'
import RuntimeEditor from '@/components/resource/RuntimeEditor.vue'

import ActorNew from '@/pages/resources/actors/components/ActorNew.vue'

import { required, minValue } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

import { useFlowCruder, useFlowEditor } from '@/compositions'

const KFlow = 'resource:actor'
const KOptionsName = 'EditingMediaVideo'
const listingRatingsUS = [
  { id: 'R', name: 'R' },
  { id: 'NR', name: 'NR' },
  { id: 'NR17', name: 'NR-17' },
  { id: 'G', name: 'G' },
  { id: 'PG', name: 'PG' },
  { id: 'PG13', name: 'PG-13' }
]

const listingVersions = [
  { name: 'Theatrical' },
  { name: 'Edited' }
]

export default {
  name: KOptionsName,
  props: { editorKey: String },
  setup (props) {
    // hasUpdate, isSaving, fieldGet, fieldSet, fieldPush, fieldSplice, checkHasUpdate, save
    const {setError, ...use} = useFlowEditor(props.editorKey)

    const version = computed({
      get () { return use.fieldGet('media.version') },
      set (val) { use.fieldSet({field: 'media.version', value: val.name}) }
    })

    const runtime = computed({
      get () { return use.fieldGet('media.runtime') },
      set (val) { use.fieldSet({field: 'media.runtime', value: val}) }
    })

    const rules = {
      version: {
        required,
      },
      runtime: {
        required,
        minValue: minValue(1)
      }
    }

    const v$ = useVuelidate(rules, { version, runtime }, { $scope: false })

    if (v$.value.$invalid) {
      setError(new Error('InvalidForm'), KOptionsName)
    }

    return {
      ...use,
      version,
      runtime,
      v$,
      setError,
      ...useFlowCruder()
    }
  },
  components: {
    AutoComplete,
    AutoCompleteSearch,
    InputSwitch,
    RatingEditor,
    RuntimeEditor,
    YearEditor,
    ActorNew
  },
  data: () => ({
    listingActors: [],
    listingActorsTotal: 0,
    listingCountries: [],
    listingCountriesTotal: 0,
    listingDirectors: [],
    listingDirectorsTotal: 0,
    listingGenres: [],
    listingGenresTotal: 0,
    listingRatingsUS,
    listingVersions,
    changeActorType: '',
    ui: {
      showCreateActor: false,
    }
  }),
  watch: {
    'v$.$invalid': function (val) {
      val ? this.setError(new Error('InvalidForm'), KOptionsName) : this.setError(null, KOptionsName)
    }
  },
  computed: {
    rating: {
      get () { return this.fieldGet('media.rating') },
      set (val) { this.fieldSet({field: 'media.rating', value: val}) }
    },
    year: {
      get () { return this.fieldGet('media.year') },
      set (val) { this.fieldSet({field: 'media.year', value: val}) }
    },
    name: {
      get () { return this.fieldGet('name') },
      set (val) { this.fieldSet({field: 'name', value: val}) }
    },
    cast: {
      get () { return this.fieldGet('media.cast') },
      set (val) { this.fieldSet({field: 'media.cast', value: val}) }
    },
    directors: {
      get () { return this.fieldGet('media.directors') },
      set (val) { this.fieldSet({field: 'media.directors', value: val}) }
    },
    genres: {
      get () { return this.fieldGet('media.genres') },
      set (val) { this.fieldSet({field: 'media.genres', value: val}) }
    },
    countries: {
      get () { return this.fieldGet('media.countries') },
      set (val) { this.fieldSet({field: 'media.countries', value: val}) }
    },
    hasViolence: {
      get () { return this.fieldGet('media.hasViolence') },
      set (val) { this.fieldSet({field: 'media.hasViolence', value: val || false}) }
    },
    hasOffensiveLanguage: {
      get () { return this.fieldGet('media.hasOffensiveLanguage') },
      set (val) { this.fieldSet({field: 'media.hasOffensiveLanguage', value: val || false}) }
    },
    hasSex: {
      get () { return this.fieldGet('media.hasSex') },
      set (val) { this.fieldSet({field: 'media.hasSex', value: val || false}) }
    },
    ratingUS: {
      get () {
        const ratingUS =  this.fieldGet('media.ratingUS')
        return listingRatingsUS.find(rating => rating.id === ratingUS) || ratingUS
      },
      set (val) { this.fieldSet({field: 'media.ratingUS', value: val.id}) }
    },
    versionError () {
      if (this.v$.version.required.$invalid) return this.$t('forms.rules.required', {field: 'Version'})
      return ''
    },
    runtimeError () {
      if (this.v$.runtime.required.$invalid || this.v$.runtime.minValue.$invalid) return this.$t('forms.rules.required', {field: this.$t('medias.runtime')})
      return ''
    }
  },
  methods: {
    async searchActors ({query}) {
      try {
        const qryBuilder = this.$pxstream.tools.createQueryBuilder()
        qryBuilder.setFilterSearch(query)
        const {data, total} = await this.$pxstream.resource.getActors(qryBuilder.build())
        if (total <= 0) {
          this.listingActors = []
        } else {
          this.listingActors = data
          this.listingActorsTotal = total
        }
      } catch (err) {
        this.$toast.add({severity: 'error', summary: 'Failed to get actors', detail: this.$pxstream.http.getHttpErrorShortMessage(err), life: 4000})
      }
    },
    async searchCountries ({query}) {
      try {
        const qryBuilder = this.$pxstream.tools.createQueryBuilder()
        qryBuilder.setFilterSearch(query)
        if (this.countries && this.countries.length !== 0) {
          qryBuilder.addFilter('id', 'nin', this.countries.map((country) => country.id))
        }
        const {data, total} = await this.$pxstream.resource.getCountries(qryBuilder.build())
        if (total <= 0) {
          this.listingCountries = []
        } else {
          this.listingCountries = data
          this.listingCountriesTotal = total
        }
      } catch (err) {
        this.$toast.add({severity: 'error', summary: 'Failed to get countries', detail: this.$pxstream.http.getHttpErrorShortMessage(err), life: 4000})
      }
    },
    async searchDirectors ({query}) {
      try {
        const qryBuilder = this.$pxstream.tools.createQueryBuilder()
        qryBuilder.setFilterSearch(query)
        const {data, total} = await this.$pxstream.resource.getActors(qryBuilder.build())
        if (total <= 0) {
          this.listingDirectors = []
        } else {
          this.listingDirectors = data
          this.listingDirectorsTotal = total
        }
      } catch (err) {
        this.$toast.add({severity: 'error', summary: 'Failed to get actors', detail: this.$pxstream.http.getHttpErrorShortMessage(err), life: 4000})
      }
    },
    async searchGenres ({query}) {
      try {
        const qryBuilder = this.$pxstream.tools.createQueryBuilder()
        qryBuilder.setFilterSearch(query)
        qryBuilder.addFilter('contentTypes.id', 'contains', [this.fieldGet('contentType.id')])
        qryBuilder.addFilter('isBlocked', 'contains', ['false'])
        const {data, total} = await this.$pxstream.resource.getGenres(qryBuilder.build())
        if (total <= 0) {
          this.listingGenres = []
        } else {
          this.listingGenres = data
          this.listingGenresTotal = total
        }
      } catch (err) {
        this.$toast.add({severity: 'error', summary: 'Failed to get genres', detail: this.$pxstream.http.getHttpErrorShortMessage(err), life: 4000})
      }
    },
    searchRatingsUS ({query}) {
      this.listingRatingsUS = listingRatingsUS.filter((v) => v.name.includes(query))
    },
    searchVersion ({query}) {
      this.listingVersions = listingVersions.filter((v) => v.name.includes(query))
    },
    checkRatingUS () {
      if (this.ratingUS.id === 'PG13') {
        this.hasViolence = true
        this.hasOffensiveLanguage = true
        this.hasSex = true
      }
    },
    async addActor(event) {
      const { data } = await this.flowGet(KFlow, event)
      const name = `${data.firstname} ${data.name}`

      let newActors = []
      switch (this.changeActorType) {
        case 'cast':
          newActors = JSON.parse(JSON.stringify(this.cast)) || []
          newActors.push({id: event, name})
          this.cast = newActors
          this.$toast.add({severity: 'success', detail: 'Actor saved with success', summary: data.name, life: 4000})
          break;
        case 'director':
          newActors = JSON.parse(JSON.stringify(this.directors)) || []
          newActors.push({id: event, name})
          this.directors = newActors
          this.$toast.add({severity: 'success', detail: 'Director saved with success', summary: data.name, life: 4000})
          break;
        default:
          break;
      }
      this.ui.showCreateActor = false
      this.changeActorType = ''
    },
    openNewActorForm (type) {
      this.ui.showCreateActor = true
      this.changeActorType = type
    }
  }
}
</script>
