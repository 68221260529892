<template>
  <div>
    <slot name="header"
      :hasUpdate="hasUpdate"
      :hasError="hasError"
      :hasLinksError="hasLinksError"
      :saveFlowBuild="saveFlowBuild" />
    <TabEditor :editorKey="editorKey" :editorMain="editorMain">
      <Tab :content="mediaComponent">
        <template #header>
          <span :class="{'p-error': hasError}">
            <i v-if="hasError" class="mr-1 pi pi-exclamation-triangle"></i>
            {{generalAndRights && !noRights ? 'General & Rights' : $t('tabs.generalInformation') }}
          </span>
        </template>
      </Tab>
      <Tab v-if="!generalAndRights && !noRights" content="EditingRights">
        <template #header>{{ $t('tabs.rights') }}</template>
      </Tab>
      <Tab content="EditingMetadata">
        <template #header>{{ $t('tabs.metadata') }}</template>
      </Tab>
      <Tab>
        <template #header>{{ $t('tabs.pictures') }}</template>
        <template #content>Content III</template>
      </Tab>
      <Tab v-if="hasTabTracks">
        <template #header>
          <div class="flex" :class="{'p-error': hasLinksError('*')}" style="height:16px">
            <i v-if="hasLinksError()" class="pi pi-exclamation-triangle"></i>
            <div class="mx-2">{{ $t('medias.tracks') }}</div>
            <Badge v-if="nbTracks" :value="nbTracks" severity="info"></Badge>
          </div>
        </template>
        <template #content><EditingTracks :editorKey="editorKey" /></template>
      </Tab>
      <Tab v-if="hasTabSeasons">
        <template #header>
          <div class="flex" :class="{'p-error': hasLinksError('*')}" style="height:16px">
            <i v-if="hasLinksError('*')" class="pi pi-exclamation-triangle"></i>
            <div class="mx-2">Seasons</div>
            <Badge v-if="nbSeasons" :value="nbSeasons" severity="info"></Badge>
          </div>
        </template>
        <template #content><EditingSeasons :editorKey="editorKey" /></template>
      </Tab>
      <Tab v-if="hasPanelMediafiles" content="EditingMediaFiles">
        <template #header>
          <div class="flex" style="height:16px">
            <div class="mx-2">Mediafiles</div>
            <Badge v-if="nbMediafiles" :value="nbMediafiles" severity="info"></Badge>
          </div>
        </template>
      </Tab>
      <Tab content="EditingHistory">
        <template #header>{{ $t('tabs.history') }}</template>
      </Tab>
    </TabEditor>
  </div>
</template>

<script>
import Badge from 'primevue/badge'
import TabEditor from '@/components/base/TabEditor.vue'

import EditingTracks from './collections/EditingTracks.vue'
import EditingSeasons from './collections/EditingSeasons.vue'

import { useFlowBuilder } from '@/compositions/useFlowEditor'
import Log from '@/services/logger'

function onCreate (doc) {
  // const isSeason = (d) => ('episodes' in d)
  return {
    name: doc.name,
    contentType: doc.contentType.id,
    defaultLanguage: doc.defaultLanguage.id
  }
}

export default {
  name: 'EditingCore',
  props: {
    doc: Object,
    flow: String,
    editorMain: String
  },
  emits: ['has-update', 'field-change'],
  components: {
    Badge,
    TabEditor,
    Tab: TabEditor.components.Tab,
    EditingTracks,
    EditingSeasons,
  },
  setup (props, { emit }) {
    return {
      ...useFlowBuilder(props.flow, props.doc, {
        editorMain: props.editorMain,
        onCreate: onCreate,
        onFieldChange: (field, value) => {
          emit('field-change', {field, value})
        },
        onUpdateChange: ({hasUpdate, at}) => {
          emit('has-update', {hasUpdate, at})
        },
        actionFields: [
          { field: 'media.mediafiles', action: 'links-to-ids' },
          { field: 'media.tracks', action: 'links-to-ids' },
          { field: 'media.seasons', action: 'links-to-ids' },
        ]
      })
    }
  },
  computed: {
    mediaComponent () {
      let mediaComponent = 'EditingMediaUnknown'

      switch (this.fieldGet('mediaType')) {
        case 'audio-simple': mediaComponent = 'EditingMediaAudioSimple'; break;
        case 'audio-podcast': mediaComponent = 'EditingMediaPodcast'; break;
        case 'audio-radio': mediaComponent = 'EditingMediaRadio'; break;
        case 'audio-album': mediaComponent = 'EditingMediaAlbum'; break;
        case 'short-movie':
        case 'movie': mediaComponent = 'EditingMediaVideo'; break;
        case 'trailer': mediaComponent = 'EditingMediaTrailer'; break;
        case 'tv-simple': mediaComponent = 'EditingMediaTvSimple'; break;
        case 'tv-series': mediaComponent = 'EditingMediaTvSeries'; break;
        case 'broadcast-prams': mediaComponent = 'EditingMediaBroadcastAndAdvert'; break;
        case 'image' : mediaComponent = 'EditingMediaImage'; break;
        case 'broadcast-bgm': mediaComponent = 'EditingMediaBroadcastAndAdvert'; break;
        case 'broadcast-vpa': mediaComponent = 'EditingMediaBroadcastAndAdvert'; break;
        case 'broadcast-safety': mediaComponent = 'EditingMediaBroadcastAndAdvert'; break;
        case 'advert': mediaComponent = 'EditingMediaBroadcastAndAdvert'; break;
        case 'document-pdf': mediaComponent = 'EditingMediaPdf'; break;
        case 'webapp-microsite' : mediaComponent = 'EditingMediaMicrosite'; break;
        case 'webapp-game' : mediaComponent = 'EditingMediaGame'; break;
        default: Log.Debug('Unknown media type:', JSON.stringify(this.doc, null, 2));
      }

      return mediaComponent
    },
    hasMediafiles () {
      switch (this.fieldGet('mediaType')) {
        case 'audio-simple':
        case 'movie':
          return true
      }

      return false
    },
    noRights () {
      if (this.fieldGet('mediaType') === 'image') {
        return true
      }
      return false
    },
    generalAndRights () {
      switch (this.fieldGet('mediaType')) {
        case 'broadcast-prams':
        case 'broadcast-bgm':
        case 'broadcast-vpa':
        case 'broadcast-safety':
        case 'advert':
        case 'document-pdf':
          return true
      }

      return false
    },
    hasPanelMediafiles () {
      switch (this.fieldGet('mediaType')) {
        case 'audio-album':
        case 'tv-series':
          return false
      }
      return true
    },
    hasTabTracks () {
      const mediaType = this.fieldGet('mediaType')
      return (mediaType === 'audio-album')
    },
    hasTabSeasons () {
      const mediaType = this.fieldGet('mediaType')
      return (mediaType === 'tv-series')
    },
    nbTracks () {
      const list = this.fieldGet('media.tracks') || []
      return list.length || 0
    },
    nbSeasons () {
      const list = this.fieldGet('media.seasons') || []
      return list.length || 0
    },
    nbMediafiles () {
      const list = this.fieldGet('media.mediafiles')
      return list && list.length ? list.length : 0
    }
  },
  watch: {
    // hasError: function (val) {
    //   this.$emit('has-error', val)
    // },
    // updatedAt (time) {
    //   console.log('Updated At ', time)
    //   this.$emit('flow-save', this.saveFlowBuild())
    // },
    // hasUpdate (newValue) {
    //   this.$emit('has-update', newValue)
    // }
  }
}
</script>
