<template>
  <MultiSelect v-model="selectedLanguage" :options="languages" optionValue="id" placeholder="none">
      <template #value>
        <span>
          {{$t('i18n.selectedLanguage')}} ({{ selectedLanguage.length }})
        </span>
      </template>

      <template #option="slotProps">
        <div class="flex justify-content-between align-items-center">
          <LanguageFlagDisplay :language="slotProps.option" />
        </div>
      </template>
  </MultiSelect>
</template>

<script>
import { mapGetters } from 'vuex';
import MultiSelect from 'primevue/multiselect';
import LanguageFlagDisplay from '@/components/resource/LanguageFlagDisplay.vue'

export default {
  components: {
    MultiSelect,
    LanguageFlagDisplay
  },
  data () {
    return {
      selectedLanguage: []
    }
  },
  computed: {
    ...mapGetters('resource', ['languages', 'languageById']),
  },
}
</script>

<style>

</style>