<template>
  <div class="my-flag" ref=flag v-tooltip.top="noTooltip ? '' : label">
    <slot></slot>
  </div>
</template>

<script>

const defaultFlag = `<?xml version="1.0" encoding="UTF-8"?>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="900" height="600" viewBox="-600 -400 1200 800">
<defs>
<circle id="rb" r="145.663293" fill="none" stroke="#013ba6" stroke-width="39.030362"/>
<circle id="rw" r="145.663293" fill="none" stroke="#fff" stroke-width="17.346827"/>
<clipPath id="o"><path d="M0,-200V200H200V-200zM-200,-200V200H-100V-200z"/></clipPath>
<clipPath id="h"><path d="M-200,-200H200V50H-200z"/></clipPath>
<clipPath id="i1"><path d="M0,0 -200,-150V150z"/></clipPath>
<clipPath id="i2"><path d="M0,0 -200,-200V200z"/></clipPath>
<clipPath id="l1"><path d="M0,0 -50,-200H-200V150z"/></clipPath>
<clipPath id="l2"><path d="M0,0V-200H-200V200z"/></clipPath>
</defs>
<rect width="1200" height="800" x="-600" y="-400" fill="#013ba6"/>
<g id="ro" transform="translate(145.663293,0)">
<use xlink:href="#rb" clip-path="url(#o)"/>
<use xlink:href="#rw"/>
</g>
<use xlink:href="#ro" transform="rotate(-60)"/>
<use xlink:href="#ro" transform="rotate(60)"/>
<use xlink:href="#ro" transform="rotate(120)"/>
<use xlink:href="#ro" transform="rotate(180)"/>
<use xlink:href="#ro" transform="rotate(240)"/>
<g>
<use xlink:href="#rb" clip-path="url(#h)"/>
<use xlink:href="#rw"/>
</g>
<g transform="translate(145.663293,0)">
<use xlink:href="#rb" clip-path="url(#i1)"/>
<use xlink:href="#rw" clip-path="url(#i2)"/>
</g>
<g id="rl" transform="rotate(120) translate(145.663293,0)">
<use xlink:href="#rb" clip-path="url(#l1)"/>
<use xlink:href="#rw" clip-path="url(#l2)"/>
</g>
<use xlink:href="#rl" transform="scale(-1,1)"/>
</svg>`

const flagCache = {}

export default {
  props: {
    id: [String, Number],
    width: String,
    height: String,
    noTooltip: Boolean
  },
  data: () => ({
    current: null
  }),
  computed: {
    label () {
      if (!this.current) {
        return '---'
      }

      return `${this.current.name} - ${this.current.iso_639_1}`
    }
  },
  methods: {
    async loadFlag () {
      if (this.id === '0' || this.id === '') {
        throw new Error('UnknownId')
      }

      if (flagCache[this.id]) {
        return flagCache[this.id]
      }

      const {data} = await this.$pxstream.resource.getOneLanguage(this.id)
      flagCache[data.id] = data

      return data
    },
    async setFlag () {
      try {
        const data = await this.loadFlag()
        this.current = data
        if (this.width) { this.$refs.flag.style.width = this.width }
        if (this.height) { this.$refs.flag.style.height = this.height }

        if (data && data.countries && data.countries.length > 0) {
          this.$refs.flag.style.backgroundImage = `url("data:image/svg+xml,${encodeURIComponent(data.countries[0].flag_4x3)}")`
        } else {
          this.$refs.flag.style.backgroundImage = `url("data:image/svg+xml,${encodeURIComponent(defaultFlag)}")`
        }
      } catch (e) {
        // console.log(e)
      }
    }
  },
  async mounted () {
    await this.setFlag()
  },
  watch: {
    id () {
      this.setFlag()
    }
  }
}
</script>

<style scoped>
.my-flag {
  width: 40px;
  height: 30px;
  background-position: center;
  background-size: contain;
  box-shadow: 0 0 1px black;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
</style>