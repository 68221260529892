<template>
  <div class="fluid my-2">

    <div class="field grid">
      <label class="col-fixed w-6 font-bold">{{ $t('menus.field.cycleName') }}:</label>
      <div class="p-fluid w-4">
        <InputText id="cycleName" type="text" v-model="cycleName" :class="{'p-invalid': cycleNameError}" :disabled="isPreviousCycle" />
        <small v-if="cycleNameError" class="p-error">{{cycleNameError}}</small>
      </div>
    </div>

    <div class="field grid">
      <label class="col-fixed w-6 font-bold">{{ $t('menus.field.cycleStartDate') }}:</label>
      <div class="p-fluid w-4">
        <Calendar id="cycleStartDate" v-model="cycleStartDate" dateFormat="yy-mm-dd" :class="{'p-invalid': cycleStartDateError}" :disabled="isPreviousCycle"/>
        <small v-if="cycleStartDateError" class="p-error">{{cycleStartDateError}}</small>
      </div>
    </div>

    <div class="field grid">
      <label class="col-fixed w-6">{{ $t('menus.field.cycleEntryEndDate') }}:</label>
      <div class="p-fluid w-4">
        <Calendar id="cycleEntryEndDate" v-model="cycleEntryEndDate" dateFormat="yy-mm-dd" :class="{'p-invalid': cycleEntryEndDateError}" :disabled="isPreviousCycle" />
        <small v-if="cycleEntryEndDateError" class="p-error">{{cycleEntryEndDateError}}</small>
      </div>
    </div>

    <div class="field grid">
      <label class="col-fixed w-6 font-bold">{{ $t('menus.field.cycleEndDate') }}:</label>
      <div class="p-fluid w-4">
        <Calendar id="cycleEndDate" v-model="cycleEndDate" dateFormat="yy-mm-dd" :class="{'p-invalid': cycleEndDateError}" :disabled="isPreviousCycle" />
        <small v-if="cycleEndDateError" class="p-error">{{cycleEndDateError}}</small>
      </div>
    </div>

    <div v-if="!isPreviousCycle" class="flex justify-content-center  mt-4" v-tooltip.top="{ value: $t('menu.section.saveBeforeCloseAndCreate'), disabled: !hasUpdate }">
      <Button :label="$t('menus.section.closeAndCreate')" @click="closeAndCreateClick" type="button" :class="{'p-button uppercase': true, 'p-disabled': hasUpdate}" />
    </div>

    <DialogCloseCreateCycle :menuId="menuId" :currentCycleName="cycleName" @newCycleCreated="onNewCycleCreated" v-model="showDialogCloseCreate" />

  </div>
</template>

<script>
import { ref, inject } from 'vue'
import Calendar from "primevue/calendar"
import InputText from "primevue/inputtext"
import Button from 'primevue/button'
import DialogCloseCreateCycle from './DialogCloseCreateCycle.vue'
import { useFlowEditor } from '@/compositions'
import { useVuelidate } from '@vuelidate/core'
import { required, between, minValue, maxValue } from '@vuelidate/validators'

export default {
  name: 'CycleManager',
  props: { editorKey: String },
  components: {
    Calendar, InputText, DialogCloseCreateCycle, Button
  },
  data () {
    return {
      hasPreviousCycle: false
    }
  },
  setup (props) {
    const askReload = inject('askReload', () => { })
    const isPreviousCycle = inject('isPreviousCycle')

    const onNewCycleCreated = () => { askReload() }

    return {
      onNewCycleCreated,
      showDialogCloseCreate: ref(false),
      ...useFlowEditor(props.editorKey),
      v$: useVuelidate(),
      isPreviousCycle
    }
  },
  methods: {
    async closeAndCreateClick () {
      if (!this.hasUpdate) {
        if (this.cycleId) {
          const {data} = await this.$pxstream.portalmanager.isCycleAlreadyExported(this.cycleId)
          if (data !== 0) {
            this.showDialogCloseCreate = true
            return
          }
        }
        this.$confirm.require({
          header: 'This cycle is not exported',
          message: 'Do you want to continue?',
          acceptLabel: this.$t('actions.yes'),
          rejectLabel: this.$t('actions.no'),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.showDialogCloseCreate = true
          },
          reject: () => { /* do nothing */ }
        })
      }
    }
  },
  computed: {
    menuId: {
      get () { return this.fieldGet('id') }
    },
    cycleId: {
      get () { return this.fieldGet('cycle.id') || null }
    },
    cycleName: {
      get () { return this.fieldGet('cycle.name') },
      set (value) { this.fieldSet({field: 'cycle.name', value}) }
    },
    cycleStartDate: {
      get () {
        const date = this.fieldGet('cycle.startDate')
        return date ? new Date(date) : ''
      },
      set (value) { this.fieldSet({field: 'cycle.startDate', value: this.$pxstream.tools.toSimpleDate(value)}) }
    },
    cycleEntryEndDate: {
      get () {
        const date = this.fieldGet('cycle.entryEndDate')
        return date ? new Date(date) : ''
      },
      set (value) { this.fieldSet({field: 'cycle.entryEndDate', value: this.$pxstream.tools.toSimpleDate(value)}) }
    },
    cycleEndDate: {
      get () {
        const date = this.fieldGet('cycle.endDate')
        return date ? new Date(date) : ''
      },
      set (value) { this.fieldSet({field: 'cycle.endDate', value: this.$pxstream.tools.toSimpleDate(value)}) }
    },
    cycleNameError () {
      if (this.v$.cycleName.required.$invalid) return 'Cycle name is required'
      return ''
    },
    cycleStartDateError () {
      if (this.v$.cycleStartDate.required.$invalid) return 'Cycle start date is required'
      if (this.v$.cycleStartDate.maxValue.$invalid) return 'Cycle start date must be prior to end date'
      return ''
    },
    cycleEntryEndDateError () {
      if (this.v$.cycleEntryEndDate.between.$invalid) return 'Cycle entry end date must be between start and end dates'
      return ''
    },
    cycleEndDateError () {
      if (this.v$.cycleEndDate.required.$invalid) return 'Cycle end date is required'
      if (this.v$.cycleEndDate.minValue.$invalid) return 'Cycle end date must be after the start date'
      return ''
    }
  },
  validations () {
    return {
      cycleName: {
        required
      },
      cycleStartDate: {
        required,
        maxValue: maxValue(this.cycleEndDate)
      },
      cycleEntryEndDate: {
        between: between(this.cycleStartDate, this.cycleEndDate)
      },
      cycleEndDate: {
        required,
        minValue: minValue(this.cycleStartDate),
      }
    }
  },
  watch: {
    'v$.cycleName.$invalid': function (val) {
      val ? this.setError(new Error('InvalidForm'), this.$options.name) : this.setError(null, this.$options.name)
    },
    'v$.cycleStartDate.$invalid': function (val) {
      val ? this.setError(new Error('InvalidForm'), this.$options.name) : this.setError(null, this.$options.name)
    },
    'v$.cycleEntryEndDate.$invalid': function (val) {
      val ? this.setError(new Error('InvalidForm'), this.$options.name) : this.setError(null, this.$options.name)
    },
    'v$.cycleEndDate.$invalid': function (val) {
      val ? this.setError(new Error('InvalidForm'), this.$options.name) : this.setError(null, this.$options.name)
    },
    cycleStartDate: function (newValue, oldValue) {
      if (!oldValue || (this.cycleEndDate && (this.cycleEndDate < newValue))) {
        this.cycleEndDate = new Date(newValue.getFullYear(), newValue.getMonth() + 1, 0)
      }
    }
  }
}
</script>

<style lang="postcss">
.updateCycle-alert .p-message-icon {
  display: none !important;
}
</style>
