
class Storage {
  constructor(http, tools) {
    this.http = http
    this.tools = tools
  }

  getFileUrl (uuid) {
    return this.http.getStorageApiUrl(`/pxfile/${uuid}`)
  }

  getPreviewFileUrl (uuid, width, height) {
    const query = []
    if (width !== 0) {
      query.push('Resize[width]='+width)
    }
    if (height !== 0) {
      query.push('Resize[height]='+height)
    }
    return this.http.getStorageApiUrl(`/pxfile/${uuid}/preview?${query.join('&')}`)
  }

  async upload (params) {
    const { data } = await this.http.storage.post(`/pxfile`, params)
    return data
  }

  getExtensionsFieldName (contentType) {
    // TODO: change this with iso
      switch (contentType) {
        case 'Game':
          return 'game_pxfile'
        case 'Microsite':
          return 'microsite_pxfile'
        case 'Audio Simple':
        case 'Podcast':
        case 'Radio':
        case 'Simple':
        case 'BGM':
        case 'Prams':
          return 'audio_pxfile'
        case 'PDF':
          return 'pdf_pxfile'
        case 'Image':
          return 'image_pxfile'
        case 'Movie':
        case 'Trailer':
        case 'Short Movie':
        case 'TV Simple':
          return 'video_pxfile'
        default:
          return ''
      }
  }

  async getExtensions (collection, fieldName) {
    const { data } = await this.http.storage.get(`/pxfile/${collection}/extensions/${fieldName}`)
    return data
  }

  async getInfos (uuid) {
    const { data } = await this.http.storage.get(`/pxfile/${uuid}/info?metadataCast=true`)
    return data
  }
}

export default Storage
