<template>
  <div class="grid" v-if="editorKey">
    <div class="col-12 flex justify-content-end">
      <LanguageSelector v-model="currentLang" :onlyLanguages="languages" :getOnlyID="false" :sort="sort" />
    </div>

    <div class="col-12">
      <div class="field grid">
        <label for="enable" class="col-12 md:col-3 font-bold">{{$t('menus.section.field.enable')}}:</label>
        <div class="col md:col-8">
          <div class="p-fluid">
            <InputSwitch id="enable" v-model="enableToggle" :disabled="isPreviousCycle"/>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="field grid">
        <label for="displayTitle" class="col-12 md:col-3 font-bold">{{$t('menus.section.field.displayTitle')}}:</label>
        <div class="col md:col-8">
          <div class="p-fluid">
            <InputText id="displayTitle" :modelValue="displayTitle" v-debounce:300ms="(val)=>(displayTitle = val)" :placeholder="displayTitlePlaceholder" :disabled="isPreviousCycle" />
            <small v-if="displayTitleError" class="p-error">{{defaultLanguage}} {{$t('forms.titleError')}}</small>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="field grid">
        <label for="type" class="col-12 md:col-3 font-bold">{{$t('menus.section.field.type')}}:</label>
        <div class="col md:col-8">
          <span class="p-fluid capitalize">{{type}}</span>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="field grid align-items-start">
        <label for="description" class="col-12 md:col-3">{{$t('menus.section.field.description')}}:</label>
        <div class="col md:col-8">
          <div class="p-fluid">
            <TextArea id="description" rows="5" cols="30" :modelValue="displayDescription" v-debounce:300ms="(val)=>(displayDescription = val)" :disabled="isPreviousCycle" />
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="field grid">
        <label for="param" class="col-12 md:col-3">{{$t('menus.section.field.param')}}:</label>
        <div class="col md:col-8">
          <div class="p-fluid">
            <InputText id="param" :modelValue="param" v-debounce:300ms="(val)=>(param = val)" :disabled="isPreviousCycle" />
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 md:col-6">
      <div class="field grid flex-direction-column">
        <label for="mediasTypes" class="col-12 md:col-8 font-bold">{{$t('menus.section.field.mediaTypes')}}:</label>
        <div class="col md:col-8">
          <div class="p-fluid">
            <Listbox id="mediasTypes" v-model="mediaTypes" :options="mediaTypeOptions" multiple optionLabel="name" :disabled="isPreviousCycle" />
            <small v-if="mediaTypesError" class="p-error">{{$t('forms.typesError')}}</small>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 md:col-6">
      <div class="field grid flex-direction-column">
        <label for="formats" class="col-12 md:col-3 font-bold">{{$t('menus.section.field.format')}}:</label>
        <div class="col md:col-8">
          <div class="p-fluid">
            <Listbox id="formats" v-model="mediaFormats" :options="mediaFormatOptions" multiple optionLabel="name" :disabled="isPreviousCycle" />
            <small v-if="mediaFormatsError" class="p-error">{{$t('forms.formatsError')}}</small>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="field grid">
        <label for="template" class="col-12 md:col-3">{{$t('menus.section.field.template')}}:</label>
        <div class="col md:col-8">
          <div class="p-fluid">
            <AutoComplete id="template" v-model="template" dropdown :suggestions="templateOptions" @complete="searchTemplate($event)" field="name" itemValue="iso" :disabled="isPreviousCycle" />
          </div>
        </div>
      </div>
    </div>


    <div class="col-12">
      <div class="field grid">
        <label for="typeIcon" class="col-12 md:col-3">{{$t('menus.section.field.typeIcon')}}:</label>
        <div class="col md:col-8">
          <Dropdown id="typeIcon" v-model="iconType" :options="iconTypeOptions" optionValue="iso" optionLabel="name" :showClear="true" :placeholder="$t('menus.section.selectTypeIcon')" class="w-full" />
        </div>
      </div>
    </div>

    <div class="col-12" v-if="isImageFileSelected">
      <div class="grid justify-content-center" >
        <ImageUpload v-model:pxfile="imageFile" collection="portalmanager:menu-item" filename="icon" :height="200" :width="300" :disabled="isPreviousCycle" />
      </div>
    </div>

    <div class="col-12" v-if="isSvgIconSelected">
      <div class="field grid">
        <label for="svgIcon" class="col-12 md:col-3 font-bold">{{$t('menus.section.field.svgIcon')}}:</label>
        <div class="col md:col-8 p-fluid">
          <TextArea id="description" rows="5" cols="30" :modelValue="svgIcon" v-debounce:300ms="(val)=>(svgIcon = val)" :disabled="isPreviousCycle" />
          <small v-if="mediaSvgError" class="p-error">{{ mediaSvgError }}</small>
        </div>
      </div>
    </div>

    <div class="col-12" v-if="iconType === 'CSS' ">
      <div class="field grid">
        <label for="ion-icon" class="col-12 md:col-3">{{$t('menus.section.field.ionIcon')}}:</label>
        <div class="col md:col-8">
          <div class="p-fluid">
            <InputText id="cssIcon" :modelValue="cssIcon" v-debounce:300ms="(val)=>(cssIcon = val)" :disabled="isPreviousCycle" />
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="field grid justify-content-center">
        <label for="image" class="col-12 md:col-3">{{$t('menus.section.field.image')}}:</label>
        <div class="col md:col-8">
          <div class="p-fluid">
            <ImageUpload v-model:pxfile="image" collection="portalmanager:menu-item" filename="logo" :height="200" :width="300" :disabled="isPreviousCycle" />
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="field grid justify-content-center">
        <div class="grid mt-2">
          <div class="flex justify-content-center mt-4" v-tooltip.top="{ value: $t('menus.section.tooltips.deleteChildrenOrMoveIt'), disabled: !hasChildren }">
            <Button icon="pi pi-minus" label="Delete Item" class="p-button-outlined"
              :class="hasChildren ? 'pointer-events-none p-button-secondary' : 'p-button-danger'"
              @click="confirmRemove()" :disabled="isPreviousCycle"/>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div v-else class="grid">
    <div class="col-12 flex justify-content-center"><span>{{$t('menus.section.noSectionSelected')}}</span></div>
  </div>
</template>

<script>
import { ref, watch, computed, inject } from 'vue'
import { mapState } from 'vuex'
import { useFlowEditor } from '@/compositions/useFlowEditor'
import { useI18n } from 'vue-i18n'
import InputSwitch from 'primevue/inputswitch'
import InputText from 'primevue/inputtext'
import TextArea from 'primevue/textarea'
import AutoComplete from 'primevue/autocomplete'
import Listbox from 'primevue/listbox'
import Dropdown from 'primevue/dropdown'
import LanguageSelector from '@/components/resource/LanguageSelector.vue'
import ImageUpload from '@/components/base/ImageUpload.vue'
import { useVuelidate } from '@vuelidate/core'
import pxstream from '@/services/pxstream'
import { getDirective as vueDebounce } from 'vue-debounce'
import { required } from '@vuelidate/validators'

const KFlow = "portalmanager:menu-item"

const defaultI18nValue = {displayTitle: '', description: ''}

const componentName = 'EditingSectionMedia'

export default {
  name: componentName,
  components: { InputSwitch, InputText, TextArea, AutoComplete, Dropdown, Listbox, LanguageSelector, ImageUpload },
  props: { editorKey: String, hasChildren: Boolean },
  directives: {
    debounce: vueDebounce(3)
  },
  setup (props) {
    const onMediaSectionRemove = inject('onMediaSectionRemove')
    const isPreviousCycle = inject('isPreviousCycle')
    const languages = inject('languages')
    const defaultLanguage = inject('defaultLanguage')
    const { t } = useI18n()
    const currentLang = ref(defaultLanguage.value)
    const iconTypeOptions  = ref(RscTypeIcons)
    const mediaFormatOptions = ref([])
    const templateOptions = ref([])
    const showDeleteDialog = ref(false)

    // hasUpdate, isSaving, fieldGet, fieldSet, fieldPush, fieldSplice, checkHasUpdate, save, set/get/has Error
    const { fieldSet, fieldGet, setError, ...flowEditorAPI } = useFlowEditor(props.editorKey)

    const mediaTypes = computed({
      get () { return fieldGet('data.mediaTypes') },
      set (value) {
        fieldSet({ field: 'data.mediaTypes', value })
        searchMediaFormats().then(() => checkAvailableMediaFormats())
      }
    })

    const mediaFormats = computed({
      get: () => { return fieldGet('data.mediaFormats') },
      set: (value) => { fieldSet({ field: 'data.mediaFormats', value }) }
    })

    const i18n = computed({
      get () { return fieldGet('i18n') }
    })

    const iconType = computed({
      get () { return fieldGet('iconType') === "" ? null : fieldGet('iconType') },
      set (value) { fieldSet({ field: 'iconType', value }) }
    })

    const imageFile = computed({
      get: () => (fieldGet('imageFileIcon')),
      set: (value) => (fieldSet({ field: 'imageFileIcon', value }))
    })

    const isSvgIconSelected = computed ({
      get () { return iconType.value === 'SVG' }
    })

    const isImageFileSelected = computed ({
      get () { return iconType.value === 'ImageFile' }
    })

    const svgIcon = computed({
      get () { return fieldGet('svgIcon') },
      set (value) {
        if (isSvgIconSelected.value) {
          fieldSet({ field: 'svgIcon', value })
        }
      }
    })

    const image = computed({
      get: () => (fieldGet('image')),
      set: (value) => (fieldSet({ field: 'image', value }))
    })

    const initI18n = () => {
      const i18n = fieldGet('i18n')
      if (!i18n) {
        if (defaultLanguage.value.id === currentLang.value.id) {
          fieldSet({field: 'i18n', value: {
            [currentLang.value.id]: {displayTitle: fieldGet('name'), description: ''}
          }})
        } else {
          fieldSet({field: 'i18n', value: {
            [currentLang.value.id]: JSON.parse(JSON.stringify(defaultI18nValue))
          }})
        }
      } else if (!i18n[currentLang.value.id]) {
        fieldSet({field: `i18n.${currentLang.value.id}`, value: JSON.parse(JSON.stringify(defaultI18nValue))})
      }
    }

    const searchMediaFormats = async () => {
      const qryBuilder = pxstream.tools.createQueryBuilder()
      qryBuilder.addFilter('contentFormats.id', 'contains', (fieldGet('data.mediaTypes') || []).map(data => data.id))
      qryBuilder.sortField = 'name'
      qryBuilder.sortOrder = 1
      pxstream.resource.getMediaFormats(qryBuilder).then(({data}) => mediaFormatOptions.value = data)
    }

    const checkAvailableMediaFormats = () => {
      let newMediaFormats = mediaFormats.value.filter(formatList => (mediaFormatOptions.value || []).find(checkedFormat => checkedFormat.id === formatList.id))
      mediaFormats.value = newMediaFormats
    }

    watch(currentLang, initI18n)

    initI18n()
    searchMediaFormats()

    flowEditorAPI.v$ = useVuelidate({
      i18n: {
        customRequired: (value) => {
          return value[defaultLanguage.value.id]?.displayTitle
        }
      },
      mediaTypes: {
        required
      },
      mediaFormats: {
        required
      },
      svgIcon: {
        required: (value) => !isSvgIconSelected.value ? true : !!value
      }
    }, { i18n, mediaTypes, mediaFormats, svgIcon })
    flowEditorAPI.v$.value.$invalid ? setError(new Error('InvalidForm'), componentName) : setError(null, componentName)
    return {
      isPreviousCycle,
      onMediaSectionRemove,
      languages,
      defaultLanguage,
      currentLang,
      iconType,
      iconTypeOptions,
      isSvgIconSelected,
      isImageFileSelected,
      svgIcon,
      image,
      imageFile,
      mediaFormatOptions,
      templateOptions,
      showDeleteDialog,
      mediaTypes,
      mediaFormats,
      searchMediaFormats,
      checkAvailableMediaFormats,
      fieldSet,
      fieldGet,
      setError,
      t,
      ...flowEditorAPI
    }
  },
  computed: {
    ...mapState('resource', {
      mediaTypeOptions: state => state.contentFormats.map(({id, name}) => ({id, name}))
    }),
    id: {
      get () { return this.fieldGet('id') }
    },
    name: {
      get () { return this.fieldGet('name') },
      set (value) { this.fieldSet({ field: 'name', value }) }
    },
    enableToggle: {
      get () { return this.fieldGet('isEnabled') },
      set (value) { this.fieldSet({ field: 'isEnabled', value }) }
    },
    displayTitlePlaceholder: { get () { return this.name } },
    displayTitle: {
      get () { return this.fieldGet(`i18n.${this.currentLang.id}.displayTitle`) },
      set (value) {
        this.fieldSet({ field: `i18n.${this.currentLang.id}.displayTitle` , value })
        if (this.currentLang.id === this.defaultLanguage.id) {
          this.name = value
        }
      }
    },
    type: {
      get () { return this.fieldGet('dataType') },
      set (value) { this.fieldSet({ field: 'dataType', value }) }
    },
    displayDescription: {
      get () { return this.fieldGet(`i18n.${this.currentLang.id}.description`) },
      set (value) { this.fieldSet({ field: `i18n.${this.currentLang.id}.description`, value }) }
    },
    param: {
      get () { return this.fieldGet('data.param') },
      set (value) { this.fieldSet({ field: 'data.param', value }) }
    },
    template: {
      get () { return this.fieldGet('data.template') },
      set (value) { this.fieldSet({ field: 'data.template', value }) }
    },
    cssIcon: {
      get () { return this.fieldGet('cssIcon') },
      set (value) { this.fieldSet({ field: 'cssIcon', value }) }
    },
    displayTitleError () {
      if (this.v$.i18n.customRequired.$invalid) return `${this.defaultLanguage.name} display title is required`
      return ''
    },
    mediaTypesError () {
      if (this.v$.mediaTypes.required.$invalid) return 'Please select at least one media type'
      return ''
    },
    mediaFormatsError () {
      if (this.v$.mediaFormats.required.$invalid) return 'Please select at least one media format'
      return ''
    },
    mediaSvgError () {
      if (this.v$.svgIcon.required.$invalid) return this.t('menus.section.field.svgError')
      return ''
    }
  },
  watch: {
    'v$.$invalid': function (val) {
      val ? this.setError(new Error('InvalidForm'), componentName) : this.setError(null, componentName)
    }
  },
  methods: {
    async searchTemplate ({query}) {
      try {
        const qryBuilder = this.$pxstream.tools.createQueryBuilder()
        qryBuilder.setFilterSearch(query)
        const {data} = await this.$pxstream.resource.getTemplates(qryBuilder.build())
        this.templateOptions = data
      } catch (err) {
        this.$toast.add({severity: 'error', summary: 'Failed to get labs', detail: this.$pxstream.http.getHttpErrorShortMessage(err), life: 4000})
      }
    },
    confirmRemove () {
      this.$confirm.require({
        message: `Are you sure you want to delete this media section?`,
        header: `Delete ${this.name}?`,
        acceptLabel: this.$t('actions.yes'),
        rejectLabel: this.$t('actions.no'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.onMediaSectionRemove(this.id, KFlow)
        },
        reject: () => {}
      })
    },
    sort (a, b) {
      return this.languages.findIndex(val => val === a.id) - this.languages.findIndex(val => val === b.id)
    }
  }
}

const RscTypeIcons = [
  { iso: 'ImageFile', name: 'Image File' },
  { iso: 'CSS', name: 'CSS'},
  { iso: 'SVG', name: 'SVG'}
]
</script>
<style scoped>
  .flex-direction-column {
    flex-direction: column;
  }
</style>
