<template>
  <div v-if="editorKey">
    <div class="field grid">
      <label for="year" class="col-12 md:col-2">{{ $t('common.year') }}</label>
      <div class="col md:col-8">
        <YearEditor v-model="year" />
        <small v-if="yearError" class="p-error">{{yearError}}</small>
      </div>
    </div>
    <div class="field grid">
      <label for="source" class="col-12 mb-2 md:col-2 md:mb-0">Source</label>
      <div class="col md:col-8">
        <InputText id="source" type="text" class="w-full" v-model="source" />
      </div>
    </div>
    <div class="field grid">
        <label for="credit" class="col-12 mb-2 md:col-2 md:mb-0">{{ $t('common.credit') }}</label>
        <div class="col md:col-8">
            <InputText id="credit" type="text" class="w-full" v-model="credit" />
        </div>
    </div>
  </div>
</template>

<script>

import { computed } from 'vue'

import InputText from 'primevue/inputtext'
import YearEditor from '@/components/resource/YearEditor.vue'
import { mapActions, mapGetters } from 'vuex'
import { useI18n } from 'vue-i18n'

import { useFlowCruder, useFlowEditor } from '@/compositions'
import { numeric } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

const KOptionsName = 'EditingMediaImage'

export default {
  name: KOptionsName,
  props: { editorKey: String },
  setup (props) {
    const {setError, ...use} = useFlowEditor(props.editorKey)
    const { t } = useI18n()

    const year = computed({
      get () { return use.fieldGet('media.year')},
      set (val) { use.fieldSet({field: 'media.year', value: val})}
    })

    const isLoading = computed( () => use.isSaving )

    const rules = {
      year: {
        numeric
      }
    }
    const v$ = useVuelidate(rules, { year }, { $scope: false })
    if (v$.value.$invalid) {
      setError(new Error('InvalidForm'), KOptionsName)
    }
    const yearError = computed(() => {
      if (v$.value.year.$invalid) {
        return t('medias.yearError')
      } else {
        return ''
      }
    })

    return {
      ...use,
      year,
      yearError,
      isLoading,
      v$,
      setError,
      ...useFlowCruder()
    }
  },
  components: {
    InputText,
    YearEditor

  },
  data: () => ({
    listingLicensors: [],
    listingLicensorsTotal: 0,
    listingContentOwners: [],
    listingContentOwnersTotal: 0,
    listingProviders: [],
    listingProvidersTotal: 0
  }),
  watch: {
    'v$.$invalid': function (val) {
      val ? this.setError(new Error('InvalidForm'), KOptionsName) : this.setError(null, KOptionsName)
    }
  },
  computed: {
    ...mapGetters('flowPortalmanagerMedia', ['doc', 'fieldGet']),
    credit : {
      get () { return this.fieldGet('media.credit')},
      set (val) { this.fieldSet({field: 'media.credit', value: val})}
    },
    source: {
      get () { return this.fieldGet('media.source') },
      set (val) { this.fieldSet({field: 'media.source', value: val})}
    }
  },
  methods: {
    ...mapActions('flowPortalmanagerMedia', ['fieldSet', 'refresh', 'force']),
    async searchLicensors ({query}) {
      try {
        const qryBuilder = this.$pxstream.tools.createQueryBuilder()
        qryBuilder.setFilterSearch(query)
        qryBuilder.addFilter('contentTypes.id', 'contains', [this.fieldGet('contentType.id')])
        qryBuilder.sortField = 'name'
        qryBuilder.sortOrder = 1
        const {data, total} = await this.$pxstream.resource.getLicensors(qryBuilder.build())
        this.listingLicensors = data
        this.listingLicensorsTotal = total
      } catch (err) {
        this.$toast.add({severity: 'error', summary: 'Failed to get licensors', detail: this.$pxstream.http.getHttpErrorShortMessage(err), life: 4000})
      }
    },
    async searchContentOwners ({query}) {
      try {
        const qryBuilder = this.$pxstream.tools.createQueryBuilder()
        qryBuilder.setFilterSearch(query)
        qryBuilder.addFilter('contentTypes.id', 'contains', [this.fieldGet('contentType.id')])
        qryBuilder.sortField = 'name'
        qryBuilder.sortOrder = 1
        const {data, total} = await this.$pxstream.resource.getContentOwners(qryBuilder.build())
        this.listingContentOwners = data
        this.listingContentOwnersTotal = total
      } catch (err) {
        this.$toast.add({severity: 'error', summary: 'Failed to get content owners', detail: this.$pxstream.http.getHttpErrorShortMessage(err), life: 4000})
      }
    },
    async searchProviders ({query}) {
      try {
        const qryBuilder = this.$pxstream.tools.createQueryBuilder()
        qryBuilder.setFilterSearch(query)
        qryBuilder.addFilter('contentTypes.id', 'contains', [this.fieldGet('contentType.id')])
        qryBuilder.sortField = 'name'
        qryBuilder.sortOrder = 1
        const {data, total} = await this.$pxstream.resource.getProviders(qryBuilder.build())
        this.listingProviders = data
        this.listingProvidersTotal = total
      } catch (err) {
        this.$toast.add({severity: 'error', summary: 'Failed to get providers', detail: this.$pxstream.http.getHttpErrorShortMessage(err), life: 4000})
      }
    }
  }
}
</script>