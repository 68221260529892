<template>
  <div class="col-12 center-elements upload-container" :style="{ height: height ? height+'px' : undefined, width: width ? width+'px' : undefined }" v-if="!pxfileUrl">
    <Button icon="pi pi-plus" class="add-button p-button-rounded p-button-outlined hover:text-white" :loading="isUploading" @click="clickUpload()" />
  </div>
  <div class="img-container" v-else>
    <img class="img" :src="pxfileUrl" :style="{ 'max-height':height +'px', 'max-width': width+'px' }" />
    <Button icon="pi pi-upload" class="upload-button p-button-rounded p-button-outlined hover:text-white" :loading="isUploading" @click="clickUpload()" />
    <Button icon="pi pi-times" class="delete-button p-button-rounded p-button-outlined hover:text-white" :loading="isUploading" @click="clickDelete()" />
  </div>
  <FileUpload
    style="visibility: hidden; height: 0;"
    ref="fileUpload"
    mode="basic"
    :auto="true"
    :customUpload="true"
    :disabled="isUploading || disabled"
    :accept="uploadAuthorizedExtensions"
    @uploader="upload"
  />
</template>

<script>
import { ref, computed, inject, watch } from 'vue'
import { useToast } from 'primevue/usetoast'

import FileUpload from 'primevue/fileupload'

export default {
  name: 'ImageUpload',
  components: {
    FileUpload
  },
  props: {
    class: {
      type: String,
      default: () => ''
    },
    collection: {
      type: String,
      default: () => ''
    },
    filename: {
      type: String,
      default: () => ''
    },
    pxfile: {
      type: Object,
      default: () => null
    },
    width: {
      type: Number,
      default: () => 0
    },
    height: {
      type: Number,
      default: () => 0
    },
    disabled: {
      type: Boolean,
      default : () => false
    }
  },
  emits: ['update:pxfile'],
  setup (props, { emit }) {
    const pxstream = inject('pxstream')
    const toast = useToast()
    const fileUpload = ref(null)
    const isUploading = ref(false)
    const uploadAuthorizedExtensions = ref('')

    const pxfileData = computed({
      get: () => (props.pxfile),
      set: (value) => { emit('update:pxfile', value) }
    })

    const pxfileUUID = computed(() => (pxfileData.value?.uuid || ''))
    const pxfileUrl = computed(() => pxfileUUID.value ? pxstream.storage.getPreviewFileUrl(pxfileUUID.value, props.width, props.height) : '')

    const loadExtensions = async () => {
      const extensions = await pxstream.storage.getExtensions(props.collection, props.filename)
      uploadAuthorizedExtensions.value = extensions.map(val => '.'+val).join()
    }

    const clickUpload = () => {
      fileUpload.value.choose()
    }

    const clickDelete = () => {
      pxfileData.value = null
    }

    const upload = async (data) => {
      if (data?.files?.length > 0) {
        isUploading.value = true
        const formData = new FormData()
        formData.append('file', data.files[0])
        formData.append('collection', props.collection)
        formData.append('fieldName', props.filename)
        try {
          const uploadRes = await pxstream.storage.upload(formData)
          pxfileData.value = uploadRes
        } catch (err) {
          console.error(err)
          toast.add({severity: 'error', summary: 'Failed to get upload', detail: pxstream.http.getHttpErrorShortMessage(err), life: 4000})
        }
        isUploading.value = false
      }
      // This is very important. If not after upload ref to fileUpload is null...
      fileUpload.value.clear()
    }

    watch(() => props.collection, () => {
      loadExtensions()
    })

    watch(() => props.filename, () => {
      loadExtensions()
    })

    loadExtensions()

    return {
      fileUpload,
      isUploading,
      uploadAuthorizedExtensions,
      pxfileUrl,
      clickUpload,
      clickDelete,
      upload
    }
  }
}
</script>
<style scoped>
  .center-elements {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .upload-container {
    background-color: rgb(230, 230, 230);
    border-radius: 30px;
  }
  .img {
    border-radius: 30px;
  }
  .img-container {
    position: relative;
  }
  .upload-button {
    position: absolute;
    float: left;
    left: 100%;
    top: 10px;
    width: 32px;
    height: 32px;
  }
  .delete-button {
    position: absolute;
    float: left;
    left: 100%;
    top: 52px;
    width: 32px;
    height: 32px;
  }
  .add-button:hover,
  .delete-button:hover,
  .upload-button:hover
  {
    background: #883cae !important;

  }
</style>