<template>
  <div v-if="editorKey">
    <div class="grid">
      <div class="col-12 md:col-7">

        <div class="field grid">
          <label for="name" :class="{'p-error': nameError, 'col-12 md:col-3 font-bold': true}">{{ $t('common.name') }}</label>
          <div class="col md:col-8">
            <span class="p-fluid">
              <InputText id="name" type="text"
                :modelValue="name"
                v-debounce:300ms="(val) => (name = val)"
                :class="{'p-invalid': nameError}"
              />
              <small v-if="nameError" class="p-error">{{nameError}}</small>
            </span>
          </div>
        </div>

        <div class="field grid">
          <label for="artists" class="col-12 md:col-3">{{ $t('medias.artists') }}</label>
          <div class="col md:col-8">
            <span class="p-fluid flex">
              <AutoCompleteSearch
                v-model="artists"
                itemValue="id"
                field="name"
                :multiple="true"
                dropdown
                dropdownIcon="search"
                :suggestions="listingArtists"
                :suggestionsTotal="listingArtistsTotal"
                @complete="searchArtists($event)"
              />
              <Button icon="pi pi-plus" class="p-button ml-1" @click="openNewArtistForm()"/>
            </span>
          </div>
        </div>

        <div class="field grid">
          <label for="genres" class="col-12 md:col-3">{{$t('medias.genres')}}</label>
          <div class="col md:col-8">
            <span class="p-fluid">
              <AutoCompleteSearch
                v-model="genres"
                itemValue="id"
                field="name"
                :multiple="true"
                dropdown
                dropdownIcon="search"
                :suggestions="listingGenres"
                :suggestionsTotal="listingGenresTotal"
                @complete="searchGenres($event)"
              />
            </span>
          </div>
        </div>

        <div class="field grid">
          <label for="rating" class="col-12 md:col-3">{{ $t('medias.rating') }}</label>
          <div class="col md:col-8">
            <RatingEditor v-model="rating" />
          </div>
        </div>

        <div class="field grid">
          <label for="isViolence" class="col-12 md:col-6">{{$t('medias.violence')}}</label>
          <div class="col md:col-6">
            <InputSwitch v-model="hasViolence " id="isViolence" />
          </div>
        </div>

        <div class="field grid">
          <label for="isOffensiveLanguage" class="col-12 md:col-6">{{ $t('medias.offensiveLanguage') }}</label>
          <div class="col md:col-6">
            <InputSwitch v-model="hasOffensiveLanguage" id="isOffensiveLanguage"  />
          </div>
        </div>

      </div>
      <div class="col-12 md:col-5">

        <div class="field grid">
          <label for="year" class="col-12 md:col-6">{{ $t('common.year') }}</label>
          <div class="col md:col-6">
            <YearEditor v-model="year" />
          </div>
        </div>

        <div class="field grid">
          <label for="country" class="col-12 md:col-6">{{ $t('common.countries') }}</label>
          <div class="col md:col-6">
            <span class="p-fluid">
              <AutoCompleteSearch
                v-model="countries"
                itemValue="id"
                field="name"
                dropdown
                dropdownIcon="search"
                multiple
                :suggestions="listingCountries"
                @complete="searchCountries($event)"
                :suggestionsTotal="listingCountriesTotal"
              />
            </span>
          </div>
        </div>

        <div class="field grid">
          <label for="runtime" class="col-12 md:col-6 font-bold">{{ $t('medias.runtime') }}</label>
          <div class="col md:col-6" >
            <RuntimeEditor disabled :modelValue="runtime" />
          </div>
        </div>

      </div>
    </div>
    <ArtistNew v-model="this.ui.showCreateArtist" @newArtist="addArtist($event)" no-router />
  </div>
</template>

<script>
import { computed } from 'vue'
import AutoCompleteSearch from '@/components/base/AutoCompleteSearch.vue'
import InputSwitch from 'primevue/inputswitch'
import InputText from 'primevue/inputtext'
import RatingEditor from '@/components/resource/RatingEditor.vue'
import YearEditor from '@/components/resource/YearEditor.vue'
import RuntimeEditor from '@/components/resource/RuntimeEditor.vue'
import { getDirective as vueDebounce } from 'vue-debounce'
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import ArtistNew from '@/pages/resources/artists/components/ArtistNew.vue'

import { useFlowCruder, useFlowEditor } from '@/compositions'

const KFlow = 'resource:artist'

export default {
  name: 'EditingMediaAlbum',
  props: { editorKey: String },
  setup (props) {
    // hasUpdate, hasError, isSaving, fieldGet, fieldSet, fieldPush, fieldSplice, checkHasUpdate, save
    const use = useFlowEditor(props.editorKey)

    const name = computed({
      get () { return use.fieldGet('name') },
      set (val) { use.fieldSet({field: 'name', value: val}) }
    })

    const rules = {
      name: {
        required,
      },
    }

    const v$ = useVuelidate(rules, { name }, { $scope: false })

    return {
      ...use,
      name,
      v$,
      ...useFlowCruder()
    }
  },
  directives: {
    debounce: vueDebounce(3)
  },
  components: {
    AutoCompleteSearch, RatingEditor, InputSwitch, RuntimeEditor, YearEditor, InputText, ArtistNew
  },
  data: () => ({
    listingArtists: [],
    listingArtistsTotal: 0,
    listingCountries: [],
    listingCountriesTotal: 0,
    listingGenres: [],
    listingGenresTotal: 0,
    ui: {
      showCreateArtist: false,
    }
  }),
  watch: {
    'v$.$invalid': function (val) {
      val ? this.setError(new Error('InvalidForm'), this.$options.name) : this.setError(null, this.$options.name)
    }
  },
  computed: {
    rating: {
      get () { return this.fieldGet('media.rating') },
      set (val) { this.fieldSet({field: 'media.rating', value: val}) }
    },
    year: {
      get () { return this.fieldGet('media.year') },
      set (val) { this.fieldSet({field: 'media.year', value: val}) }
    },
    runtime: {
      get () {
        return this.fieldGet('media.runtime')
          || this.doc.media.tracks?.map((t) => (t.media.runtime)).reduce((runtime, r) => (runtime + r), 0)
          || 0
      },
    },
    artists: {
      get () { return this.fieldGet('media.artists') },
      set (val) { this.fieldSet({field: 'media.artists', value: val}) }
    },
    genres: {
      get () { return this.fieldGet('media.genres') },
      set (val) { this.fieldSet({field: 'media.genres', value: val}) }
    },
    countries: {
      get () { return this.fieldGet('media.countries') },
      set (val) { this.fieldSet({field: 'media.countries', value: val}) }
    },
    hasViolence: {
      get () { return this.fieldGet('media.hasViolence') },
      set (val) { this.fieldSet({field: 'media.hasViolence', value: val || false}) }
    },
    hasOffensiveLanguage: {
      get () { return this.fieldGet('media.hasOffensiveLanguage') },
      set (val) { this.fieldSet({field: 'media.hasOffensiveLanguage', value: val || false}) }
    },
    nameError () {
      if (this.v$.name.required.$invalid) return this.$t('forms.rules.required', {field: this.$t('common.name')})
      return ''
    },
  },
  methods: {
    async searchArtists ({query}) {
      try {
        const qryBuilder = this.$pxstream.tools.createQueryBuilder()
        qryBuilder.setFilterSearch(query)
        const {data, total} = await this.$pxstream.resource.getArtists(qryBuilder.build())
        if (total <= 0) {
          this.listingArtists = []
        } else {
          this.listingArtists = data
          this.listingArtistsTotal = total
        }
      } catch (err) {
        this.$toast.add({severity: 'error', summary: this.$t('resources.artist.list.failedToGet'), detail: this.$pxstream.http.getHttpErrorShortMessage(err), life: 4000})
      }
    },
    async searchGenres ({query}) {
      try {
        const qryBuilder = this.$pxstream.tools.createQueryBuilder()
        qryBuilder.setFilterSearch(query)
        qryBuilder.addFilter('contentTypes.id', 'contains', [this.fieldGet('contentType.id')])
        qryBuilder.addFilter('isBlocked', 'contains', ['false'])
        const {data, total} = await this.$pxstream.resource.getGenres(qryBuilder.build())
        if (total <= 0) {
          this.listingGenres = []
        } else {
          this.listingGenres = data
          this.listingGenresTotal = total
        }
      } catch (err) {
        this.$toast.add({severity: 'error', summary: this.$t('resources.genre.list.failedToGet'), detail: this.$pxstream.http.getHttpErrorShortMessage(err), life: 4000})
      }
    },
    async searchCountries ({query}) {
      try {
        const qryBuilder = this.$pxstream.tools.createQueryBuilder()
        qryBuilder.setFilterSearch(query)
        if (this.countries && this.countries.length !== 0) {
          qryBuilder.addFilter('id', 'nin', this.countries.map((country) => country.id))
        }
        const {data, total} = await this.$pxstream.resource.getCountries(qryBuilder.build())
        if (total <= 0) {
          this.listingCountries = []
        } else {
          this.listingCountries = data
          this.listingCountriesTotal = total
        }
      } catch (err) {
        this.$toast.add({severity: 'error', summary: this.$t('resources.countries.list.failedToGet'), detail: this.$pxstream.http.getHttpErrorShortMessage(err), life: 4000})
      }
    },
    async addArtist(event) {
      const { data } = await this.flowGet(KFlow, event)
      const name = `${data.firstname} ${data.name}`

      let newArtists = JSON.parse(JSON.stringify(this.artists)) || []
      newArtists.push({id: event, name})
      this.artists = newArtists
      this.$toast.add({severity: 'success', detail: this.$t('resources.artist.save.success'), summary: data.name, life: 4000})

      this.ui.showCreateArtist = false
    },
    openNewArtistForm () {
      this.ui.showCreateArtist = true
    },
  }
}
</script>