<template>
  <div v-if="editorKey">
    <MultiSelect
      v-model="system"
      :options="rscIfeProducts"
      :showToggleAll="false"
      :placeholder="$t('mediafiles.selectSystemsCompliance')"
      optionLabel="name"
      optionGroupLabel="name"
      optionGroupChildren="products"
      class="w-full"
    >
      <template #optiongroup="{ option }">
        <div class="flex">
          <Checkbox v-if="false" v-model="groupedByIfe[option.id]" :binary="true" @click="addAllIfe(option)" class="mr-2"/>
          <div>{{ option.name }}</div>
        </div>
      </template>

    </MultiSelect>
  </div>
</template>

<script>
import { useFlowEditor } from '@/compositions/useFlowEditor'

import MultiSelect from 'primevue/multiselect'
import Checkbox from 'primevue/checkbox'

export default {
  props: {
    editorKey: String,
    placeholderKey: String
  },
  components: { MultiSelect, Checkbox },
  data: () => ({
    groupedByIfe: {},
    rscIfeProducts: []
  }),
  setup (props) {
    return {
      ...useFlowEditor(props.editorKey)
    }
  },
  computed: {
    system: {
      get () { return this.fieldGet('content.systemsCompliance') },
      set (val) { this.fieldSet({field: 'content.systemsCompliance', value: val}) }
    }
  },
  methods: {
    addAllIfe (ife) {
      const byId = {}
      this.system = this.system || []
      this.system.forEach(one => {
        byId[one.id] = one
      })
      for (let i = 0; i < this.rscIfeProducts.length; i++) {
        if (this.rscIfeProducts[i].id === ife.id) {
          this.system = this.system || []
          this.rscIfeProducts[i].products.forEach(one => {
            if (this.groupedByIfe[ife.id]) {
              delete byId[one.id]
            } else {
              if (!byId[one.id]) byId[one.id] = one
            }
          })
          this.groupedByIfe[ife.id] = !this.groupedByIfe[ife.id]
          break
        }
      }

      this.system = Object.keys(byId).map(key => byId[key])
    },
    async searchIfeProducts ({query}) {
      try {
        const qryBuilder = this.$pxstream.tools.createQueryBuilder()
        qryBuilder.setFilterSearch(query)
        const {data} = await this.$pxstream.resource.getIfeProducts(qryBuilder.build())
        const byIFE = {}
        const grouped = []

        data.forEach(one => {
          const key = one.ife.id
          if (!byIFE[key]) {
            const ife = {
              ...one.ife,
              products: []
            }

            byIFE[key] = ife
            grouped.push(ife)
          }

          byIFE[key].products.push({
            id: one.id,
            name: one.name
          })
        })

        this.rscIfeProducts = grouped
      } catch (err) {
        this.$toast.add({severity: 'error', summary: this.$t('ifeProducts.list.failedToGet'), detail: this.$pxstream.http.getHttpErrorShortMessage(err), life: 4000})
      }
    }
  },
  mounted () {
    this.searchIfeProducts({})
  }
}
</script>