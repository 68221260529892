<template>
  <div v-if="editorKey">
    <div class="field grid">
      <label for="runtime" :class="{'p-error':runtimeError, 'col-12 mb-2 md:col-2 md:mb-0 font-bold': true}">{{ $t('medias.runtime') }}</label>
      <div class="col md:col-6">
        <RuntimeEditor :invalid="runtimeError" v-model="runtime" />
      </div>
    </div>
    <div class="field grid">
      <label for="licensor" class="col-12 mb-2 md:col-2 md:mb-0">{{ $t('medias.licensor') }}</label>
      <div class="col-2">
        <AutoCompleteSearch
          id="licensor"
          v-model="licensor"
          itemValue="id"
          field="name"
          dropdown
          dropdownIcon="search"
          :suggestions="listingLicensors"
          :suggestionsTotal="listingLicensorsTotal"
          @complete="searchLicensors($event)"
        />
      </div>
    </div>
    <div class="field grid">
      <label for="content_owner" class="col-12 mb-2 md:col-2 md:mb-0">{{ $t('medias.contentOwner') }}</label>
      <div class="col-2">
        <AutoCompleteSearch
          id="content_owner"
          v-model="contentOwner"
          itemValue="id"
          field="name"
          dropdown
          dropdownIcon="search"
          :suggestions="listingContentOwners"
          :suggestionsTotal="listingContentOwnersTotal"
          @complete="searchContentOwners($event)"
        />
      </div>
    </div>
    <div class="field grid">
      <label for="provider" class="col-12 mb-2 md:col-2 md:mb-0">{{ $t('medias.provider') }}</label>
      <div class="col-2">
        <AutoCompleteSearch
          id="provider"
          v-model="provider"
          itemValue="id"
          field="name"
          dropdown
          dropdownIcon="search"
          :suggestions="listingProviders"
          :suggestionsTotal="listingProvidersTotal"
          @complete="searchProviders($event)"
        />
      </div>
    </div>
    <div class="field grid">
        <label for="copyright" class="col-12 mb-2 md:col-2 md:mb-0">{{ $t('common.copyright') }}</label>
        <div class="col md:col-8">
            <InputText id="copyright" type="text" class="w-full" v-model="copyright" />
        </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'

import InputText from 'primevue/inputtext'
import AutoCompleteSearch from '@/components/base/AutoCompleteSearch.vue'
import { mapActions, mapGetters } from 'vuex'

import RuntimeEditor from '@/components/resource/RuntimeEditor.vue'

import { useFlowCruder, useFlowEditor } from '@/compositions'

import { useVuelidate } from '@vuelidate/core'
import { required, minValue } from '@vuelidate/validators'

const KOptionsName = 'EditingMediaBroadcastAndAdvert'

export default {
  name: KOptionsName,
  props: { editorKey: String },
  setup (props) {
    // hasUpdate, isSaving, fieldGet, fieldSet, fieldPush, fieldSplice, checkHasUpdate, save
    const {setError, ...use} = useFlowEditor(props.editorKey)

    const runtime = computed({
      get () { return use.fieldGet('media.runtime') },
      set (val) { use.fieldSet({field: 'media.runtime', value: val}) }
    })

    const rules = {
      runtime: {
        required,
        minValue: minValue(1)
      }
    }

    const v$ = useVuelidate(rules, { runtime }, { $scope: false })

    if (v$.value.$invalid) {
      setError(new Error('InvalidForm'), KOptionsName)
    }

    return {
      ...use,
      runtime,
      v$,
      setError,
      ...useFlowCruder()
    }
  },
  components: {
    InputText, AutoCompleteSearch,
    RuntimeEditor
  },
  data: () => ({
    listingLicensors: [],
    listingLicensorsTotal: 0,
    listingContentOwners: [],
    listingContentOwnersTotal: 0,
    listingProviders: [],
    listingProvidersTotal: 0
  }),
  watch: {
    'v$.$invalid': function (val) {
      val ? this.setError(new Error('InvalidForm'), KOptionsName) : this.setError(null, KOptionsName)
    }
  },
  computed: {
    ...mapGetters('flowPortalmanagerMedia', ['doc', 'fieldGet']),
    licensor: {
      get () { return this.fieldGet('licensor') },
      set (val) { this.fieldSet({field: 'licensor', value: val}) }
    },
    contentOwner: {
      get () { return this.fieldGet('contentOwner') },
      set (val) { this.fieldSet({field: 'contentOwner', value: val}) }
    },
    provider: {
      get () { return this.fieldGet('provider') },
      set (val) { this.fieldSet({field: 'provider', value: val}) }
    },
    copyright: {
      get () { return this.fieldGet('copyright') },
      set (val) { this.fieldSet({field: 'copyright', value: val}) }
    },
    runtimeError () {
      if (this.v$.runtime.required.$invalid || this.v$.runtime.minValue.$invalid) return this.$t('forms.rules.required', {field: this.$t('medias.runtime')})
      return ''
    }
  },
  methods: {
    ...mapActions('flowPortalmanagerMedia', ['fieldSet', 'refresh', 'force']),
    async searchLicensors ({query}) {
      try {
        const qryBuilder = this.$pxstream.tools.createQueryBuilder()
        qryBuilder.setFilterSearch(query)
        qryBuilder.addFilter('contentTypes.id', 'contains', [this.fieldGet('contentType.id')])
        qryBuilder.sortField = 'name'
        qryBuilder.sortOrder = 1
        const {data, total} = await this.$pxstream.resource.getLicensors(qryBuilder.build())
        if (total <= 0) {
          this.listingLicensors = []
        } else {
          this.listingLicensors = data
          this.listingLicensorsTotal = total
        }
      } catch (err) {
        this.$toast.add({severity: 'error', summary: 'Failed to get licensors', detail: this.$pxstream.http.getHttpErrorShortMessage(err), life: 4000})
      }
    },
    async searchContentOwners ({query}) {
      try {
        const qryBuilder = this.$pxstream.tools.createQueryBuilder()
        qryBuilder.setFilterSearch(query)
        qryBuilder.addFilter('contentTypes.id', 'contains', [this.fieldGet('contentType.id')])
        qryBuilder.sortField = 'name'
        qryBuilder.sortOrder = 1
        const {data, total} = await this.$pxstream.resource.getContentOwners(qryBuilder.build())
        if (total <= 0) {
          this.listingContentOwners = []
        } else {
          this.listingContentOwners = data
          this.listingContentOwnersTotal = total
        }
      } catch (err) {
        this.$toast.add({severity: 'error', summary: 'Failed to get content owners', detail: this.$pxstream.http.getHttpErrorShortMessage(err), life: 4000})
      }
    },
    async searchProviders ({query}) {
      try {
        const qryBuilder = this.$pxstream.tools.createQueryBuilder()
        qryBuilder.setFilterSearch(query)
        qryBuilder.addFilter('contentTypes.id', 'contains', [this.fieldGet('contentType.id')])
        qryBuilder.sortField = 'name'
        qryBuilder.sortOrder = 1
        const {data, total} = await this.$pxstream.resource.getProviders(qryBuilder.build())
        if (total <= 0) {
          this.listingProviders = []
        } else {
          this.listingProviders = data
          this.listingProvidersTotal = total
        }
      } catch (err) {
        this.$toast.add({severity: 'error', summary: 'Failed to get providers', detail: this.$pxstream.http.getHttpErrorShortMessage(err), life: 4000})
      }
    }
  }
}
</script>