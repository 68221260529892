export default {
  "common": {
    "welcome": "Welcome",
    "home": "Home",
    "search": {
      "result": "No result | 1 result | {count} Results"
    },
    "createdBy": "Created By",
    "all": "ALL",
    "selectAll": "Select @.lower:common.all",
    "and": "and",
    "name": "Name",
    "title": "Title",
    "selectLang": "Select a lang",
    "year": "Year",
    "country": "Country",
    "countries": "Countries",
    "actions": "Actions",
    "draft": "Draft",
    "reloading": "Reloading",
    "firstname": "Firstname",
    "notAvailable": "Not available",
    "color": "Color | Colors",
    "text": "Text",
    "main": "Main",
    "contrast": "Contrast",
    "copyright": "Copyright",
    "background": "Background",
    "backgroundDark": "Background dark mode",
    "logo": "Logo",
    "logoDark": "Logo dark mode",
    "file": "File",
    "lastname": "Lastname",
    "username": "Username",
    "password": "Password",
    "media": "Media",
    "comments": "Comments",
    "prefixFile": "Prefix File",
    "userBlocked": "User Blocked",
    "language": "Language",
    "defaultLanguage": "Default Language",
    "selectDefaultLanguage": "Select default language",
    "selectALang": "Select a lang",
    "selectSections": "Select Sections",
    "email": "Email",
    "roles": "Roles",
    "groups": "Groups",
    "tryAgainLater": "Please, try again later",
    "documentLanguage": "Document Language",
    "pleaseWait": "Please wait",
    "primary": "Primary",
    "secondary": "Secondary",
    "content": "Content",
    "custom": "Custom",
    "warning": "Warning",
    "error": "Error",
    "success": "Success",
    "areYouSure" : "Are you sure ?",
    "subtitles": "Subtitles",
    "availableForAllLanguages": "Available for all languages",
    "codec": "Codec",
    "encrypted": "Encrypted",
    "resolution": "Resolution",
    "burnedInSub": "Burned in Sub",
    "dynamicsub": "Dynamic Sub",
    "dynamicsubs": "Dynamic Subs",
    "encoding": "Encoding",
    "videoTrickModes": "Video trick modes",
    "format": "Format",
    "embeddedFilename": "Embedded filename",
    "parentTitle": "Parent Title",
    "embeddedTrailerFilename": "Embedded Trailer Filename",
    "colorType": "Color Type",
    "boxType": "Box Type",
    "credit": "Credit",
    "pictureLanguage": "Picture Language",
    "systemsCompliance": "Systems compliance",
    "selectSystemsCompliance": "Select systems compliance",
    "videoBitrate": "Video Bitrate",
    "grayLevel": "Shades of grey",
    "offAll": "Video Panel Off – All keys functional",
    "offStop": "Video Panel Off – Stop Key disabled",
    "offFfrew": "Video Panel Off – FF.REW Keys disabled",
    "onAll": "Video Panel On – All keys functional",
    "onStop": "Video Panel On – Stop Key disabled",
    "onFfrew": "Video Panel On – FF.REW Keys disabled",
    "selectVideoTrickMode": "Select video trick mode",
    "selectCodec": "Select a codec",
    "selectVideoBitrate": "Select video Bitrate",
    "selectResolution": "Select resolution",
    "selectRatio": "Select ratio",
    "lightModeColors": "Light Mode Colors",
    "darkModeColors": "Dark Mode Colors",
    "selectBoxType": "Select box type",
    "selectEncoding": "Select encoding",
    "selectBitrate": "Select bitrate",
    "selectColor": "Select color",
    "selectFormat": "Select format",
    "selectType": "Select type",
    "pleaseEnterName": "Please enter a name",
    "pleaseSelectAudiotrackLanguage": "Please select an audiotrack language",
    "pleaseSelectBurnedSubsLanguage": "Please select a burned subs language",
    "pleaseSelectDynamicSubsLanguage": "Please select a dynamic subs language",
    "pleaseSelectLanguage": "Please select a language",
    "pleaseSelectFormat": "Please select a format",
    "pleaseSelectColorType": "Please select a color type",
    "pleaseSelectAnotherLanguage": "Please select another language",
    "othersMenus": "others menus",
    "typeOfFile": "Type of file",
    "selectFileType": "Select a type of file",
    "path": "Path",
    "loadingStages": "Loading stages",
    "time": {
      "past": "{time} ago",
      "day": "day | {n} days",
      "hour": "1 hour | {n} hours",
      "minute": "1 minute | {n} minutes",
      "lessThanOneMinute": "less than one minute"
    }
  },

  "login": {
    "forgotPassword": "Forgot password?",
    "signIn": "Sign in",
    "signOut": "Sign out"
  },

  "medias": {
    "unknownType": "Unknown Media type",
    "get": {
      error: "Failed to get medias",
    },
    "remove": {
      "impactInfo": "There are {total} media impacted",
      "header": "Delete {name}",
      "message": "Are you sure you want to delete this {name}?",
      "success": "Removed with success",
      "error": "Failed to remove media {name}",
    },
    "loadingMedia": "Loading media...",
    "artists": "Artists",
    "sex": "Sex",
    "trailer": "Trailer",
    "tracks": "Tracks",
    "genres": "Genres",
    "violence": "Violence",
    "offensiveLanguage": "Offensive Language",
    "files": "Files",
    "shortSynopsis": "Short Synopsis",
    "synopsis": "Synopsis",
    "licensor": "Licensor",
    "contentOwner": "Content Owner",
    "provider": "Provider",
    "runtime": "Runtime",
    "rating": "Rating",
    "ratingUs": "Rating US",
    "yearError": "Please enter a numeric year",
    "multiplayer": "Multiplayer",
    "noMediaFound": "No media found",
    "noMediaFiles": "No media files",
    "noMediaCorresponding": "No media with corresponding mediafile found.",
    "mediaPresentInMenu": "The media is currently present in the menus",
    "areYouSureDeleteMediafile": "Are you sure you want to delete this mediafile and all overrides made ?",
    "mediafilePresentInMenu": "The mediafile is currently present in the menus",
    "episode": {
      "label": "episode | episodes",
      "add": "Add Episode"
    },
    "track": {
      "add": "Add Track",
    },
    "contentType": "Content Type",
    "audioTracks": "Audio Tracks",
    "burnedSubs": "Burned Subs",
    "dynamicSubs": "Dynamic Subs",
  },

  "mediafiles": {
    "unknownType": "Unknown mediafile Type",
    "audioTrack": "Audio track",
    "systemsCompliance": "Systems Compliance",
    "selectSystemsCompliance": "Select systems compliance",
    "encoding": "Encoding",
    "bitrate": "Bitrate",
    "videoBitrate": "Video Bitrate",
    "pictureLanguage": "Picture Language",
    "uploadingANewFileReset": "Uploading a new file will reset and regenerate some old file related data"
  },

  "menus": {
    "new": "Add Menu",
    label: 'Menu | Menus',
    field: {
      "currentCycle": "Current Cycle",
      "cycleName": "Cycle Name",
      "cycleStartDate": "Cycle Start date",
      "cycleEntryEndDate": "Cycle Entry End Date",
      "cycleEndDate": "Cycle End Date",
      "updatePreviousCycle": "Update Previous Cycle",
      "messageUpdatePreviousCycle": "Do you really want to change items in previous cycle?",
      "warningEditingPreviousCycle": "Warning !! You are editing the previous cycle !",
      "backCurrentCycle": "Back current cycle",
      "confirmGroupRemoveForMediafiles": "Are you sure you want to delete these mediafiles?",
      "confirmGroupRemoveForMedias": "Are you sure you want to delete these medias?",
      "confirmEditMediafileWithNoPosibleOvercharge": "You are about to edit a mediafile that cannot be overcharged due to an uploaded file. The modifications you are about to enter will be applied in all menus. Proceed?",
      "svgIcon": "SVG Code",
      "selectCycle": "Select a cycle"
    },
    "create": {
      "mode": "Creation mode",
      "menuToDuplicate": "Menu to duplicate",
      "modes": {
        "manual": "Manual",
        "duplicate": "Duplicate"
      }
    },
    "unknownMenuItemType": "Unknown menu item type",
    "section": {
      "new": "Add Item",
      "delete": "Delete Item",
      "closeAndCreate": "Close and create new cycle",
      "saveBeforeCloseAndCreate": "Save menu before close and create new cycle",
      "noSectionSelected": "No Selected Section",
      "selectTypeIcon": "Select icon type",
      "field": {
        "type": "Type",
        "enable": "Enable",
        "displayTitle": "Display Title",
        "description": "Description",
        "param": "Param",
        "pxapp": "PXApp",
        "microsite": "Microsite",
        "template": "Template",
        "mediaTypes": "Medias Type",
        "screen": "Screen",
        "image": "Image",
        "typeIcon": "Type Icon",
        "ionIcon": "Ion-Icon",
        "svgIcon": "Svg Icon",
        "svgError": "Svg Icon is required",
        "typesError": "Please select at least one media type",
        "formatsError": "Please select at least one media format"
      },
      "tooltips": {
        "deleteChildrenOrMoveIt": "Please delete children sections or move it"
      },
      "remove": {
        message: "Are you sure you want to delete this media section?",
        header: "Delete {name}?"
      },
      "types": {
        "get": {
          error: 'Failed to get types'
        }
      }
    },
    "list": {
      "empty": "No menu found.",
      "loading": "Loading Menus...",
    },
    "one": {
      "loading": "Loading Menu...",
      "save": {
        "success": "Saved with success",
        "error": "Failed to save menu"
      },
      get: {
        error: 'Failed to get menu'
      }
    }
  },

  "projects": {
    "label": "Project | Projects",
    "new": "Add Project",
    "loadingProjects": "Loading projects",
    "error": {
      "existingProject": 'This project name already exist',
      "noProjectFound": "No project found"
    },
    "one": {
      "loading": "Loading @:projects.label ...",
      "reload": "Reloading @:projects.label ...",
      get: {
        error: 'Failed to get project',
      },
      save: {
        success: 'Saved with success',
        error: 'Failed to save project'
      }
    },
    "export": {
      "noIfeProduct": "No IFE Product",
      "exportProject": "Export Project",
      "rules": "Rules",
      "genres": "Genres",
      "changeMenuWarning": {
        "header": 'All rules with no All sections will be removed!',
        "message": 'Do you really want to change menu?',
      }
    },
    "rules": {
      "new": "ADD RULE",
      "empty": "No rule found.",
      "loading": "Loading rules. @:common.pleaseWait",
      "field": {
        type: "Type | Types",
        value: "Value",
        section: "Section | Sections",
        compatibleSystems: 'Compatible Systems',
        startDate: 'Start date',
        endDate: 'End date',
      },
      remove: {
        header: 'Delete {name}?',
        message: 'Are you sure you want to delete this rule?',
        success: '',
        error: ''
      }
    },
    "overloadedGenres": {
      "moviesAndTV": "Movies / TV",
      "audio": "Audio"
    },
    "field": {
      "product": "Product",
      "ifeSystem": "IFE System",
      "type": "Type",
      "description": "Description",
      "version": "Version",
      "selectVersion": "Select a version",
      "date": "Date",
      "comment": "Comment",
      "airlineCode": "Airline Code",
      "selectIFESystem": "Select an IFE System"
    }
  },

  "ifeProduct": {
    "field": {
      "type": "IFE Type",
      "product": "Product",
      "ifeSystem": "IFE System",
      "version": "Version",
      "target": "Target"
    },
    "list": {
      "empty": 'No product found.',
      "loading": 'Loading products. @:common.pleaseWait',
      "failedToGet": "Failed to get ife products"
    },
    "new": "Add IFE System",
  },

  "users": {
    "label": "User | Users",
    "new": "New user",
    "one": {
      "get": {
        "error": 'Failed to get user'
      },
      "create": {
        "error": "Failed to create user. @:common.tryAgainLater"
      },
      "save": {
        "success": 'User saved with success',
        "error": 'Failed to save user'
      }
    },
    "list": {
      "failedToGet": 'Failed to get users',
      "empty": "No data available",
    }
  },

  "resources": {

    "actor": {
      "label": "Actor",
      "new": 'New Actor',
      "translations": {
        "empty": "No translations",
        "loading": "Loading translations. @:common.pleaseWait",
        "remove": {
          "header": "Delete {language}",
          "message": "Are you sure you want to delete this translation?",
        }
      },
      "list": {
        "empty": "No actor found.",
        "loading": "Loading actors. @:common.pleaseWait"
      },
      "one": {
        "loading": "Loading actor...",
        save: {
          success: 'Saved with success',
          error: 'Failed to save actor'
        },
        get: {
          error: 'Failed to get actor'
        }
      }
    },

    "artist": {
      "label": "Artist | Artists",
      "new": "New Artist",
      "translations": {
        "empty": "No translations",
        "loading": "Loading translations. @:common.pleaseWait",
        "remove": {
          "header": "Delete {language}",
          "message": "Are you sure you want to delete this translation?",
        }
      },
      "list": {
        "failedToGet": "Failed to get artists",
        "empty": "No artist found.",
        "loading": "Loading artists. @:common.pleaseWait",
      },
      "one": {
        "loading": "Loading artist...",
        "get": {
          "error": 'Failed to get artist',
        },
        "save": {
          success: "Saved with success",
          erroor: "Failed to save artist",
        },
        remove: {
          header: '',
          message: '',
          success: '',
          error: ''
        }
      },
      "save": {
        "success": "Artist saved with success"
      },
      "remove": {
        header: "Delete {name}",
        "message": "Are you sure you want to delete this artist?",
        success: 'Removed with success',
        error: "Failed to remove media {name}",
      }
    },

    "genre": {
      "new": "New Genre",
      "list": {
        "failedToGet": "Failed to get genres",
        "empty": "No genre found.",
        loading: "Loading genres. Please wait."
      },
      "one": {
        "loading": "Loading genre...",
        "save": {
          "success": 'Saved with success',
          "error": 'Failed to save genre'
        },
        "get": {
          "error": 'Failed to get genre'
        }
      },
      field: {
        "blocked": "Blocked ?",
      }
    },

    "countries": {
      "list": {
        "failedToGet": "Failed too get countries"
      }
    },

    "contentTypes": {
      "label": "Content Type | Content Types",
      "Advert": "Advert",
      "Album": "Album",
      "Audio Simple": "Audio Simple",
      "Podcast": "Podcast",
      "Radio": "Radio",
      "BGM": "BGM",
      "Prams": "Prams",
      "Safety": "Safety",
      "VPA": "VPA",
      "Game": "Game",
      "Movie": "Movie",
      "Short Movie": "Short Movie",
      "Trailer": "Trailer",
      "TV Simple": "TV Simple",
      "TV Series": "TV Series"
    }
  },

  "actions": {
    "submit": "Submit",
    "cancel": "Cancel",
    "create": "Create",
    "update": "Update",
    "save": "Save",
    "delete": "Delete",
    "export": "Export",
    "download": "Download",
    "exporting": "Exporting",
    "back": "Back",
    "remove": "Remove",
    "groupRemove": "Group remove",
    "removeSelection": "Remove selection",
    "add": "Add",
    "no": "No",
    "yes": "Yes",
    "addLanguage": "Add language",
    "addFile": "Add file",
    "search": "Search",
    "areYouSure" : "Are you sure you want to proceed ?"
  },

  "tabs": {
    "general": "General",
    "details": "Details",
    "ifeProducts": "IFE Products",
    "exportCsp": "Export CSP",
    "generalInformation": "General Information",
    "rights": "Rights",
    "metadata": "Metadata",
    "pictures": "Pictures",
    "tracks": "Tracks",
    "history": "History",
    "mediaSections": "Media Sections",
    "medias": "Medias",
    "homeConfig": "Home Config",
    "staticTranslations": "Static Translations"
  },

  // Modules | Tabs
  "history": {
    "empty": "No history available"
  },

  // Side Menu
  "navbar": {
    "Account": "Account",
    "Notifications": "Notifications",
    "Logout": "Logout",
    "Dashboard": "Dashboard",
    "Projects": "Projects",
    "Portal Manager": "Portal Manager",
    "Media Library": "Media Library",
    "Menus": "Menus",
    "Artists": "Artists",
    "Actors": "Actors",
    "Genres": "Genres",
    "Languages": "Languages",
    "Resources": "Resources",
    "Admin": "Admin",
    "Users": "Users"
  },

  "forms": {

    "rules": {
      "required": "{field} is required",
      "email": "Wrong email format",
      "datePriorTo": "{field} must be prior to {toField}",
      "dateAfter": "{field} must be after the {toField}",
      "between": "{field} must be between {start} and {end}"
    },
  },

  "buttons": {
    "search": "Search",
    "upload": "Upload",
    "newUser": "New user",
    "newGenre": "New genre",
    "newActor": "New actor",
    "newProject": "New project",
    "newMedia": "New media",
    "newMenu": "New menu",
    "addTranslation": "Add translation",
    "addIfeProduct": "Add Ife Product",
    "addMetadata": "Add metadata",
    "addMediafile": "Add mediafile",
    "addWithoutPhysicalFile": "Add without physical file"
  },

  "i18n": {
    "selectedLanguage": "Selected Language"
  },

  "guards": {
    "leaveEditor": {
      "header": 'You have unsaved changes!',
      message: 'Do you really want to leave?'
    }
  }

}