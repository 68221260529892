<template>
  <div v-if="editorKey">
    <div class="grid">
      <div class="col-12 md:col-7">

        <div class="field grid">
          <label for="name" :class="{'p-error': titleError, 'col-12 md:col-3 font-bold': true}">{{ $t('common.title') }}</label>
          <div class="col md:col-8">
            <span class="p-fluid">
              <InputText id="title" type="text"
                :modelValue="title"
                v-debounce:300ms="(val) => (title = val)"
                :class="{'p-invalid': titleError}"
                :disabled="isLoading"
              />
              <small v-if="titleError" class="p-error">{{titleError}}</small>
            </span>
          </div>
        </div>

        <div class="field grid">
          <label for="seasonNumber" :class="{'p-error': seasonNumberError, 'col-12 md:col-3 font-bold': true}">Season Number</label>
          <div class="col md:col-8">
            <span class="p-fluid">
              <InputText id="seasonNumber" type="text"
                :modelValue="seasonNumber"
                v-debounce:300ms="(val) => (seasonNumber = val)"
                :class="{'p-invalid': seasonNumberError}"
                :disabled="isLoading"
              />
              <small v-if="seasonNumberError" class="p-error">{{seasonNumberError}}</small>
            </span>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import InputText from 'primevue/inputtext'
import { required, numeric } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useFlowEditor } from '@/compositions/useFlowEditor'
import { getDirective as vueDebounce } from 'vue-debounce'

export default {
  name: 'EditingMediaSeason',
  props: { editorKey: String },
  setup (props) {
    // hasUpdate, isSaving, fieldGet, fieldSet, fieldPush, fieldSplice, checkHasUpdate, save, set/get/has Error
    const use = useFlowEditor(props.editorKey)
    use.v$ = useVuelidate() 
    return use
  },
  directives: {
    debounce: vueDebounce(3),
  },
  components: {
    InputText
  },
  data: () => ({
    listingArtists: [],
    listingCountries: [],
    listingGenres: []
  }),
  computed: {
    title: {
      get () { return this.fieldGet(`metadata.${this.fieldGet('defaultLanguage.id')}.title`) },
      set (val) { this.fieldSet({field: `metadata.${this.fieldGet('defaultLanguage.id')}.title`, value: val}) }
    },
    seasonNumber: {
      get () { return this.fieldGet('seasonNumber') },
      set (val) { this.fieldSet({field: 'seasonNumber', value: val}) }
    },
    isLoading () {
      return this.isSaving
    },
    titleError () {
      if (this.v$.title.required.$invalid) return 'Title is required'
      return ''
    },
    seasonNumberError () {
      if (this.v$.seasonNumber.required.$invalid) return 'Season number is required'
      if (this.v$.seasonNumber.numeric.$invalid) return "Season number isn't a number"
      return ''
    }
  },
  watch: {
    'v$.$invalid': function (val) {
      val ? this.setError(new Error('InvalidForm'), this.$options.name) : this.setError(null, this.$options.name)
    }
  },
  validations: {
    title: {
      required
    },
    seasonNumber: {
      required,
      numeric
    }
  }
}
</script>