<template>
  <div class="card">
    <DataTable
      :value="mediafiles"
      dataKey="id"
      selectionMode="single"
      responsiveLayout="scroll"
      @row-click="edit($event.data)"
      :reorderableColumns="true">

      <template #empty>
        {{ $t('medias.noMediaFiles') }}
      </template>

      <Column field="filename" header="Filename"></Column>
      <Column v-if="!isImageType" field="lab" header="Lab">
        <template #body="{data}">
          <div>
            {{ data.lab.name }}
          </div>
        </template>
      </Column>

      <!-- audio -->
      <Column header="Language" v-if="showLanguage">
        <template #body="{data}">
          <div v-if="data.content.language && data.content.language.id !== '' && data.content.language.id !== '0' ">
            <Flag :id="data.content.language.id"/>
          </div>
        </template>
      </Column>
      <!-- /audio -->

      <Column v-if="isVideoType" :header="$t('medias.audioTracks')" >
        <template #body="{data}">
          <div v-if="data && data.content && data.content.audioTracks" class="flex">
            <div v-for="(one, idx) in data.content.audioTracks" :key="idx">
              <Flag :id="one.language.id" class="mr-2"/>
            </div>
          </div>
        </template>
      </Column>

      <Column v-if="isVideoType" :header="$t('medias.burnedSubs')" >
        <template #body="{data}" >
          <div v-if="data && data.content && data.content.burnedSubs" class="flex">
            <div v-for="(one, idx) in data.content.burnedSubs" :key="idx">
              <Flag :id="one.language.id" class="mr-2"/>
            </div>
          </div>
        </template>
      </Column>

      <Column v-if="isVideoType" :header="$t('medias.dynamicSubs')" >
        <template #body="{data}" >
          <div v-if="data && data.content && data.content.dynamicSubs" class="flex">
            <div v-for="(one, idx) in data.content.dynamicSubs" :key="idx">
              <Flag :id="one.language.id" class="mr-2"/>
            </div>
          </div>
        </template>
      </Column>

      <Column>
        <template #header>
          <div class="flex justify-content-end w-full">
            <div>{{ $t('common.actions') }}</div>
          </div>
        </template>

        <template #body="{data}">
          <div class="flex justify-content-end w-full">
            <Button v-if="data.pxfile?.uuid" icon="pi pi-eye" class="p-button-rounded p-button-primary p-button-outlined mr-2" @click="view(data)" />
            <Button icon="pi pi-pencil" class="p-button-rounded p-button-primary p-button-outlined mr-2" @click="edit(data)" />
            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-outlined" @click="confirmRemove(data)" />
          </div>
        </template>
      </Column>

    </DataTable>

    <div class="flex">
      <Button
        :label="$t('buttons.addMediafile')"
        class="mt-3 p-button-outlined"
        icon="pi pi-plus"
        :loading="isLoading"
        @click="confirmAdd"
      />
    </div>

    <Sidebar
      v-model:visible="editMediafileShowIt"
      position="right"
      class="p-sidebar-lg"
      :loading="isLoading"
      :dismissable="false"
      :showCloseIcon="false"
    >
      <EditingMediafilesEditor
        v-model="editMediafileData"
        :mediaId="docId"
        :isInitiating="isInitiating"
        :mediafileInitFrom="firstMediafileEditorKey"
        :pxfileInit="pxfile"
        @close="onCloseMediafileEditor()"
        @child-submit="updateDoc"
      />
    </Sidebar>

    <Dialog :header="$t('actions.addFile')" v-model:visible="showAddChoice" :style="{width: '500px'}" :modal="true">
      <div class="flex justify-content-around">
        <!-- <Button @click="openUpload()" label="Upload" icon="pi pi-upload" class="" /> -->
        <FileUpload mode="basic" :accept="uploadAuthorizedExtensions" :chooseLabel="$t('buttons.upload')" chooseIcon="pi pi-upload" name="UploadFile" :auto="true" :customUpload="true" @uploader="upload" :disabled="isLoading" />
        <Button @click="create()" :label="$t('buttons.addWithoutPhysicalFile')" class="p-button-text" :loading="isLoading" />
        <Button @click="closeAdd()" :label="$t('actions.cancel')" icon="pi pi-times" class="p-button-text" :loading="isLoading" />
      </div>
    </Dialog>

    <Dialog :header="`${$t('actions.delete')} ${selectedMediafileFilename}`" v-model:visible="showRemove" :style="{width: '350px'}" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span>{{$t('medias.areYouSureDeleteMediafile')}}</span>
        <div v-if="selectedMediafile && selectedMediafile.menus">
          <p class="mt-4 mb-1">{{ $t('medias.mediafilePresentInMenu') }}:</p>

          <ul class="m-0">
            <li v-for="(menu, index) in selectedMediafile.menus.list" :key="`selectedMediafileMenu-${index}`">{{ menu.name }}</li>
            <li v-if="selectedMediafile.menus.total > 20">{{ $t('common.and') }} {{ selectedMediafile.menus.total - 20 }} {{ $t('common.othersMenus') }}</li>
          </ul>
        </div>

      </div>
      <template #footer>
        <Button @click="closeRemove()" :label="$t('actions.no')" icon="pi pi-times"  class="p-button-text"/>
        <Button @click="onMediafileRemove()" :label="$t('actions.yes')" icon="pi pi-check" class="p-button-text" autofocus />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { computed, ref, inject, watch } from 'vue'
import { useToast } from 'primevue/usetoast'

import { useFlowPMMediafileBuilder, useFlowEditor } from '@/compositions'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import Sidebar from 'primevue/sidebar'
import Dialog from 'primevue/dialog'
import FileUpload from 'primevue/fileupload'

import Flag from '@/components/resource/Flag.vue'
import EditingMediafilesEditor from './EditingMediafilesEditor.vue'

const KFlowMediafile = 'portalmanager:mediafile'

export default {
  name: 'EditingMediafiles',
  props: { editorKey: String },
  components: {
    DataTable, Column, Button, Sidebar, Dialog, FileUpload,
    Flag,
    EditingMediafilesEditor
  },
  setup (props) {
    const pxstream = inject('pxstream')
    const toast = useToast()

    const isLoading = ref(false)
    const editMediafileShowIt = ref(false)
    const editMediafileData = ref(null)
    const isInitiating = ref(false)
    const showRemove = ref(false)
    const showAddChoice = ref(false)
    const selectedMediafile = ref({})
    const pxfile = ref(null)

    const uploadAuthorizedExtensions = ref('')

    const mediaEditor = computed(() => useFlowEditor(props.editorKey))

    const contentType = computed(() => mediaEditor.value?.fieldGet('contentType') || {})

    const mediafiles = computed(() => mediaEditor.value?.fieldGet('media.mediafiles') || [])

    const firstMediafileEditorKey = computed(() => {
      if (mediafiles.value.length > 0) {
        const {editorKey} = useFlowPMMediafileBuilder({
          propDoc: mediafiles.value[0],
          onFieldChange: () => {},
          onUpdateChange: () => {}
        })
        return editorKey
      }
      return null
    })

    const selectedMediafileFilename = computed(() => selectedMediafile.value.filename?.length > 30 ? `${selectedMediafile.value.filename.substring(0,30)}...` : selectedMediafile.value.filename)

    const uploadFieldName = computed(() => pxstream.storage.getExtensionsFieldName(contentType.value?.name))

    const closeRemove = () => {
      showRemove.value = false
    }

    const closeAdd = () => {
      showAddChoice.value = false
    }

    const confirmRemove = async (data) => {
      showRemove.value = true
      const qryBuilder = pxstream.tools.createQueryBuilder()
      qryBuilder.addFilter('cycle.sections.data.mediafiles', 'contains', [data.id])
      const {data: menus, total} = await pxstream.portalmanager.getMenuList(qryBuilder.build())
      selectedMediafile.value = {...data}
      selectedMediafile.value.menus = null
      if (menus) {
        menus.sort((a, b) => a.name > b.name)
        selectedMediafile.value.menus = {}
        selectedMediafile.value.menus.list = menus.splice(0, 20)
        selectedMediafile.value.menus.total = total
      }
    }

    const confirmAdd = () => {
      showAddChoice.value = true
    }

    const onCloseMediafileEditor = () => {
      isInitiating.value = false
      editMediafileData.value = null
      editMediafileShowIt.value = false
      pxfile.value = null
    }

    const view = (mediafile) => {
      const url = mediafile.pxfile?.uuid ? pxstream.storage.getFileUrl(mediafile.pxfile.uuid) : ''
      window.open(url, '_blank').focus()
    }

    const edit = (mediafile) => {
      isInitiating.value = false
      pxfile.value = null
      editMediafileData.value = mediafile
      editMediafileShowIt.value = true
    }

    const create = async () => {
      isInitiating.value = true
      isLoading.value = true
      const { data } = await pxstream.portalmanager.createEmptyMediafile({contentType: contentType.value?.id})
      data.id = 'new'
      editMediafileData.value = data
      editMediafileShowIt.value = true
      closeAdd()
      isLoading.value = false
    }

    const upload = async (data) => {
      if (data?.files?.length > 0) {
        const formData = new FormData()
        formData.append('file', data.files[0])
        formData.append('collection', KFlowMediafile)
        formData.append('fieldName', uploadFieldName.value)

        try {
          isLoading.value = true
          const promises = []
          promises.push(pxstream.storage.upload(formData))
          promises.push(pxstream.portalmanager.createEmptyMediafile({contentType: contentType.value?.id}))

          const apiRes = await Promise.all(promises)
          if (apiRes.length !== 2) {
            throw 'Invalid number of api response'
          }

          isInitiating.value = true
          const uploadRes = apiRes[0]
          const { data: mediafile } = apiRes[1]
          pxfile.value = uploadRes
          mediafile.id = 'new'
          editMediafileData.value = mediafile

          editMediafileShowIt.value = true
          closeAdd()
        } catch (err) {
          console.error(err)
          toast.add({severity: 'error', summary: 'Failed to get mediafile with upload', detail: pxstream.http.getHttpErrorShortMessage(err), life: 4000})
        }
        isLoading.value = false
      }
    }

    watch(() => uploadFieldName.value, async () => {
      const extensions = await pxstream.storage.getExtensions(KFlowMediafile, uploadFieldName.value)
      uploadAuthorizedExtensions.value = extensions.map(val => '.'+val).join()
    })

    if (uploadFieldName.value !== "") {
      pxstream.storage.getExtensions(KFlowMediafile, uploadFieldName.value).then(extensions => {
        uploadAuthorizedExtensions.value = extensions.map(val => '.'+val).join()
      })
    }

    return {
      isLoading,
      editMediafileShowIt,
      editMediafileData,
      isInitiating,
      mediaEditor,
      mediafiles,
      pxfile,
      uploadAuthorizedExtensions,
      firstMediafileEditorKey,
      ...mediaEditor.value,
      closeRemove,
      closeAdd,
      confirmRemove,
      confirmAdd,
      onCloseMediafileEditor,
      view,
      edit,
      create,
      upload,
      selectedMediafile,
      selectedMediafileFilename,
      showRemove,
      showAddChoice
    }
  },
  computed: {
    docId () {
      return this.fieldGet('id')
    },
    showLanguage () {
      switch (this.fieldGet('mediaType')) {
        case 'audio-simple':
        case 'audio-podcast':
        case 'audio-radio':
        case 'broadcast-bgm':
        case 'broadcast-prams':
        case 'document-pdf':
        case 'image':
          return true
        default:
          return false
      }
    },
    isVideoType () {
      switch (this.fieldGet('mediaType')) {
        case 'movie':
        case 'tv-simple':
        case 'advert':
        case 'short-movie':
        case 'trailer':
        case 'broadcast-safety':
        case 'broadcast-vpa':
          return true
        default:
          return false
      }

    },
    isImageType () {
      switch (this.fieldGet('mediaType')) {
        case 'image' :
          return true
        default:
          return false
      }
    }
  },
  methods: {
    updateDoc (req) {
      if (req.type === 'new') {
        this.fieldAddToSet({field: 'media.mediafiles', value: req.doc, key: 'id'})
      }
      // Update fields info of listing
      for (let i = 0; i < this.mediafiles.length; i++) {
        if (this.mediafiles[i].id === req.id) {
          this.mediafiles[i].filename = req.doc.filename
          this.mediafiles[i].lab = req.doc.lab
          this.mediafiles[i].content = req.doc.content
          this.mediafiles[i].pxfile = req.doc.pxfile
          break
        }
      }
      this.saveFlowAddLink(req)
      this.editMediafileShowIt = false
    },
    onMediafileRemove () {
      this.fieldSplice({ field: 'media.mediafiles', filter: one => one.id !== this.selectedMediafile.id })
      this.saveFlowDeleteLinkElement({
        flow: KFlowMediafile,
        id: this.selectedMediafile.id
      })
      this.closeRemove()
    }
  }
}
</script>
