export default {
  "common": {
    "welcome": "Bienvenue",
    "home": "Accueil",
    "search": {
      "result": "Aucun résultat | 1 résultat | {count} résultats"
    },
    "createdBy": "Créé par",
    "all": "TOUT",
    "selectAll": "Sélectionnez @.lower:common.all",
    "and": "et",
    "name": "Nom",
    "title": "Titre",
    "selectLang": "Selectionner une langue",
    "year": "Année",
    "country": "Pays",
    "countries": "Pays",
    "actions": "Actions",
    "draft": "Brouillon",
    "reloading": "Chargement",
    "firstname": "Prénom",
    "notAvailable": "Pas disponible",
    "color": "Couleur | Couleurs",
    "text": "Texte",
    "copyright": "Droit d'auteur",
    "background": "Arrière-plan",
    "backgroundDark": "Arrière-plan mode sombre",
    "logo": "Logo",
    "logoDark": "Logo mode sombre",
    "main": "Principal",
    "contrast": "Contraste",
    "file": "Fichier",
    "lastname": "Nom",
    "username": "Nom d'utilisateur",
    "password": "Mot de Passe",
    "media": "Média",
    "comments": "Commentaires",
    "prefixFile": "Préfixe de fichier",
    "userBlocked": "Utilisateur bloqué",
    "language": "Langage",
    "defaultLanguage": "Langage par défaut",
    "selectDefaultLanguage": "Sélectionner un langage par défaut",
    "selectALang": "Selectionner une langue",
    "email": "Email",
    "roles": "Rôles",
    "groups": "Groupes",
    "tryAgainLater": "Veuillez réessayer plus tard",
    "pleaseWait": "Veuillez patienter",
    "primary": "Primaire",
    "secondary": "Secondaire",
    "content": "Contenu",
    "custom": "Personnalisé",
    "warning": "Avertissement",
    "error": "Erreur",
    "success": "Succès",
    "audioTrack": "Piste audio",
    "codec": "Codec",
    "encrypted": "Crypté",
    "resolution": "Résolution",
    "encoding": "Codage",
    "burnedInSub": "Sous-titre intégré",
    "dynamicsub": "Sous-titre dynamique",
    "dynamicsubs": "Sous-titres dynamiques",
    "subtitles": "Sous-titres",
    "videoTrickModes": "Modes d'astuce vidéo",
    "credit": "Crédit",
    "boxType": "Type de boîte",
    "pictureLanguage": "Langue de l'image",
    "documentLanguage": "Langue du document",
    "systemsCompliance": "Conformité des systèmes",
    "selectSystemsCompliance": "Sélectionnez la conformité des systèmes",
    "videoBitrate": "Débit binaire vidéo",
    "availableForAllLanguages": "Disponible pour toutes les langues",
    "grayLevel": "Niveaux de gris",
    "offAll": "Panneau vidéo éteint - Toutes les touches fonctionnelles",
    "offStop": "Panneau vidéo désactivé - Touche d'arrêt désactivée",
    "offFfrew": "Panneau vidéo désactivé - Touches FF.REW désactivées",
    "onAll": "Panneau vidéo activé - Toutes les touches fonctionnelles",
    "onStop": "Panneau vidéo activé - touche d'arrêt désactivée",
    "onFfrew": "Panneau vidéo activé - Touches FF.REW désactivées",
    "selectVideoTrickMode": "Sélectionner le mode d'astuce vidéo",
    "selectCodec": "Sélectionner un codec",
    "selectVideoBitrate": "Sélectionner le débit binaire de la vidéo",
    "selectResolution": "Sélectionner une résolution",
    "selectRatio": "Sélectionner le ratio",
    "lightModeColors": "Couleurs du mode clair",
    "darkModeColors": "Couleurs du mode sombre",
    "selectBoxType": "Sélectionner le type de boîte",
    "selectEncoding": "Sélectionner l'encodage",
    "selectBitrate": "Sélectionner le débit",
    "selectColor": "Sélectionner la couleur",
    "selectFormat": "Sélectionner le format",
    "selectType": "Sélectionner le type",
    "selectSections": "Sélectionner les sections",
    "pleaseEnterName": "Veuillez entrer un nom",
    "pleaseSelectAudiotrackLanguage": "Veuillez sélectionner une langue pour la piste audio",
    "pleaseSelectBurnedSubsLanguage": "Veuillez sélectionner une langue pour les sous-titres incrustés",
    "pleaseSelectDynamicSubsLanguage": "Veuillez sélectionner une langue pour les sous-titres dynamiques",
    "pleaseSelectLanguage": "Veuillez sélectionner une langue",
    "pleaseSelectFormat": "Veuillez sélectionner un format",
    "pleaseSelectColorType": "Veuillez sélectionner un type de couleur",
    "pleaseSelectAnotherLanguage": "Veuillez sélectionner une autre langue",
    "othersMenus": "d'autres menus",
    "areYouSure" : "Êtes-vous sûr ?",
    "embeddedFilename": "Nom de fichier intégré",
    "parentTitle": "Titre du parent",
    "colorType": "Type de couleur",
    "embeddedTrailerFilename": "Nom de fichier de la bande-annonce intégrée",
    "path": "Chemin",
    "typeOfFile": "Type de fichier",
    "selectFileType": "Sélectionnez un type de fichier",
    "loadingStages": "Étapes de chargement",
    "time": {
      "past": "Il y a {time}",
      "day": "jour | {n} jours",
      "hour": "1 heure | {n} heures",
      "minute": "1 minute | {n} minutes",
      "lessThanOneMinute": "Il y a moins d'une minute"
    }
  },

  "login": {
    "forgotPassword": "Mot de Passe oublié?",
    "signIn": "Connexion",
    "signOut": "Déconnexion"
  },

  "medias": {
    "unknownType": "Type de média inconnu",
    "get": {
      error: "Impossible d'obtenir les médias",
    },
    "remove": {
      "impactInfo": "Il y a {total} média(s) impacté(s)",
      "header": "Supprimer {name}",
      "message": "Êtes-vous sûr de vouloir supprimer {name}?",
      "success": "Supprimé avec succès",
      "error": "Échec de la suppression du média {name}",
    },
    "loadingMedia": "Chargement du média...",
    "artists": "Artistes",
    "sex": "Sexe",
    "trailer": "Trailer",
    "tracks": "Pistes",
    "genres": "Genres",
    "violence": "Violence",
    "offensiveLanguage": "Langage vulgaire",
    "files": "Fichiers",
    "shortSynopsis": "Synopsis court",
    "synopsis": "Synopsis",
    "licensor": "Concédant",
    "contentOwner": "Propriétaire du contenu",
    "provider": "Fournisseur",
    "runtime": "Durée",
    "rating": "Note",
    "ratingUs": "Note US",
    "yearError": "Veuillez entrer une date numérique",
    "multiplayer": "Multijoueur",
    "noMediaCorresponding": "Aucun média avec le fichier multimédia correspondant n'a été trouvé.",
    "mediaPresentInMenu": "Le média est actuellement présent dans les menus",
    "areYouSureDeleteMediafile": "Voulez-vous vraiment supprimer ce fichier multimédia et tous les remplacements effectués ?",
    "mediafilePresentInMenu": "Le fichier média est actuellement présent dans les menus",
    "noMediaFound": "Aucun média trouvé",
    "noMediaFiles": "Aucun fichier multimédia",
    "episode": {
      "label": "épisode | épisodes",
      "add": "Ajouter un épisode"
    },
    "track": {
      "add": "Ajouter une piste",
    },
    "contentType": "Type de contenu",
    "audioTracks": "Piste audio",
    "burnedSubs": "jcp",
    "dynamicSubs": "jcp",
  },

  "mediafiles": {
    "unknownType": "Type de fichier multimédia inconnu",
    "audioTrack": "Piste audio",
    "systemsCompliance": "conformité des systèmes",
    "selectSystemsCompliance": "Sélectionnez la conformité des systèmes",
    "uploadingANewFileReset": "Le téléchargement d'un nouveau fichier réinitialisera et régénérera certaines anciennes données liées au fichier",
    "videoBitrate": "Débit binaire vidéo",
    "pictureLanguage": "Langue de l'image",
    "encoding": "Codage",
    "bitrate": "Débit binaire"
  },

  "menus": {
    "new": "Ajouter un Menu",
    "loadingMenu": "Chargement du Menu...",
    "loadingMenus": "Chargement des Menus...",
    label: 'Menu | Menus',
    field: {
      "currentCycle": "Cycle actuel",
      "cycleName": "Nom du cycle",
      "cycleStartDate": "Date de début de cycle",
      "cycleEntryEndDate": "Date de fin d'entrée de cycle",
      "cycleEndDate": "Date de fin de cycle",
      "updatePreviousCycle": "Mettre à jour le cycle précédent",
      "messageUpdatePreviousCycle": "Voulez-vous vraiment modifier les éléments du cycle précédent ?",
      "warningEditingPreviousCycle": "Avertissement !! Vous éditez le cycle précédent !",
      "backCurrentCycle": "Retour cycle courant",
      "confirmGroupRemoveForMediafiles": "Voulez-vous vraiment supprimer ces fichiers multimédia ?",
      "confirmGroupRemoveForMedias": "Voulez-vous vraiment supprimer ces médias ?",
      "confirmEditMediafileWithNoPosibleOvercharge": "Vous êtes sur le point de modifier un fichier multimédia qui ne peut pas être surchargé en raison d'un fichier téléchargé. Les modifications que vous êtes sur le point d'entrer seront appliquées dans tous les menus. Continuer ?",
      "svgIcon": "Code SVG",
      "selectCycle": "Select a cycle"
    },
    "create": {
      "mode": "Mode création",
      "menuToDuplicate": "Menu à dupliquer",
      "modes": {
        "manual": "Manuel",
        "duplicate": "Dupliquer"
      }
    },
    "unknownMenuItemType": "Type d'élément de menu inconnu",
    "section": {
      "new": "Ajouter un élément",
      "delete": "Supprimer un élément",
      "closeAndCreate": "Fermer et créer un nouveau cycle",
      "saveBeforeCloseAndCreate": "Enregistrer le menu avant de fermer et créer un nouveau cycle",
      "noSectionSelected": "Aucune section sélectionnée",
      "selectTypeIcon": "Sélectionner un type d'icône",
      "field": {
        "type": "Type",
        "enable": "Activer",
        "displayTitle": "Afficher le titre",
        "description": "Description",
        "param": "Param",
        "pxapp": "PXApp",
        "microsite": "Microsite",
        "format": "Format",
        "template": "Modèle",
        "mediaTypes": "Type de média",
        "screen": "Écran",
        "image": "Image",
        "typeIcon": "Type d'icône",
        "ionIcon": "Ion-Icon",
        "svgIcon": "Svg Icon",
        "svgError": "Le code SVG est requis",
      },
      "tooltips": {
        "deleteChildrenOrMoveIt": "Veuillez supprimer les sections enfants ou les déplacer"
      },
      "remove": {
        message: "Voulez-vous vraiment supprimer cette section média ?",
        header: "Supprimer {name}?"
      },
      "types": {
        "get": {
          error: "Impossible d'obtenir les types"
        }
      }
    },
    "list": {
      "empty": "Aucun menu trouvé.",
      "loading": "Chargement des menus...",
    },
    "one": {
      "loading": "Chargement du menu...",
      "save": {
        "success": "Sauvegardé avec succès",
        "error": "Échec de l'enregistrement du menu"
      },
      get: {
        error: "Impossible d'obtenir le menu"
      }
    }
  },

  "projects": {
    "label": "Projet | Projets",
    "new": "Ajouter un Projet",
    "loadingProjects": "Chargement des projets",
    "error": {
      "existingProject": 'Ce nom de projet existe déjà',
      "noProjectFound": "Aucun projet trouvé"
    },
    "one": {
      "loading": "Chargement @:projects.label ...",
      "reload": "Chargement @:projects.label ...",
      get: {
        error: "Impossible d'obtenir le projet",
      },
      save: {
        success: 'Sauvé avec succès',
        error: "Échec de l'enregistrement du projet"
      }
    },
    "export": {
      "noIfeProduct": "Aucun produit IFE",
      "exportProject": "Exporter le projet",
      "rules": "Règles",
      "genres": "Genres",
      "changeMenuWarning": {
        "header": 'Toutes les règles sans toutes les sections seront supprimées !',
        "message": 'Voulez-vous vraiment changer le menu ?',
      }
    },
    "rules": {
      "new": "Ajouter une règle",
      "empty": "Aucune règle trouvée.",
      "loading": "Chargement règles. @:common.pleaseWait",
      "field": {
        type: "Type | Types",
        value: "Valeur",
        section: "Section | Sections",
        compatibleSystems: 'Systèmes compatibles',
        startDate: 'Date de début',
        endDate: 'Date de fin',
      },
      remove: {
        header: 'Supprimer {name}?',
        message: 'Voulez-vous vraiment supprimer cette règle ?',
        success: '',
        error: ''
      }
    },
    "overloadedGenres": {
      "moviesAndTV": "Films / TV",
      "audio": "Audio"
    },
    "field": {
      "product": "Produit",
      "ifeSystem": "Système IFE",
      "type": "Type",
      "description": "Description",
      "version": "Version",
      "selectVersion": "Sélectionner une version",
      "date": "Date",
      "comment": "Commentaire",
      "airlineCode": "Code Airline",
      "selectIFESystem": "Sélectionnez un système IFE"
    }
  },

  "ifeProduct": {
    "field": {
      "type": "Type d'IFE",
      "product": "Produit",
      "ifeSystem": "Système IFE",
      "version": "Version",
      "target": "Target"
    },
    "list": {
      "empty": 'Aucun produit trouvé.',
      "loading": 'Chargement produits. @:common.pleaseWait',
      "failedToGet": "Impossible d'obtenir les produits ife"
    },
    "new": "Ajouter un système IFE",
  },

  "users": {
    "label": "Utilisateur | Utilisateurs",
    "new": "Nouvel utilisateur",
    "one": {
      "get": {
        "error": "Impossible d'obtenir l'utilisateur"
      },
      "create": {
        "error": "Échec de la création de l'utilisateur. @:common.tryAgainLater"
      },
      "save": {
        "success": 'Utilisateur enregistré avec succès',
        "error": "Échec de l'enregistrement de l'utilisateur"
      }
    },
    "list": {
      "failedToGet": "Échec de l'obtention des utilisateurs",
      "empty": "Pas de données disponibles",
    }
  },

  "resources": {

    "actor": {
      "label": "Acteurr",
      "new": 'Nouvel Acteur',
      "translations": {
        "empty": "Aucune traduction",
        "loading": "Chargement des traductions. @:common.pleaseWait",
        "remove": {
          "header": "Supprimer {language}",
          "message": "Voulez-vous vraiment supprimer cette traduction ?",
        }
      },
      "list": {
        "empty": "Aucun acteur trouvé.",
        "loading": "Chargement des acteurs. @:common.pleaseWait"
      },
      "one": {
        "loading": "Chargement de l'acteur...",
        save: {
          success: 'Sauvegardé avec succès',
          error: "Échec de la sauvegarde de l'acteur"
        },
        get: {
          error: "Impossible d'obtenir l'acteur"
        }
      }
    },

    "artist": {
      "label": "Artiste | Artistes",
      "new": "Nouvel Artiste",
      "translations": {
        "empty": "Aucune traduction",
        "loading": "Chargement des traductions. @:common.pleaseWait",
        "remove": {
          "header": "Supprimer {language}",
          "message": "Voulez-vous vraiment supprimer cette traduction ?",
        }
      },
      "list": {
        "failedToGet": "Échec de l'obtention des artistes",
        "empty": "Aucun artiste trouvé.",
        "loading": "Chargement des artistes. @:common.pleaseWait",
      },
      "one": {
        "loading": "Chargement de l'artiste...",
        "get": {
          "error": "Impossible d'obtenir l'artiste",
        },
        "save": {
          success: "Sauvegardé avec succès",
          erroor: "Échec de la sauvegarde de l'artiste",
        },
        remove: {
          header: '',
          message: '',
          success: '',
          error: ''
        }
      },
      "save": {
        "success": "Artiste sauvegardé avec succès"
      },
      "remove": {
        header: "Supprimer {name}",
        "message": "Voulez-vous vraiment supprimer cet artiste ?",
        success: 'Supprimé avec succès',
        error: "Échec de la suppression du média {name}",
      }
    },

    "genre": {
      "new": "Nouveau Genre",
      "list": {
        "failedToGet": "Impossible d'obtenir les genres",
        "empty": "Aucun genre trouvé.",
        loading: "Chargement des genres. Veuillez patienter."
      },
      "one": {
        "loading": "Chargement du genre...",
        "save": {
          "success": 'Sauvegardé avec succès',
          "error": 'Échec de la sauvegarde du genre'
        },
        "get": {
          "error": "Impossible d'obtenir le genre"
        }
      },
      field: {
        "blocked": "Bloqué ?",
      }
    },

    "countries": {
      "list": {
        "failedToGet": "Échec de l'obtention des pays"
      }
    },

    "contentTypes": {
      "label": "Content Type | Content Types",
      "Advert": "Advert",
      "Album": "Album",
      "Audio Simple": "Audio Simple",
      "Podcast": "Podcast",
      "Radio": "Radio",
      "BGM": "BGM",
      "Prams": "Prams",
      "Safety": "Safety",
      "VPA": "VPA",
      "Game": "Game",
      "Movie": "Movie",
      "Short Movie": "Short Movie",
      "Trailer": "Trailer",
      "TV Simple": "TV Simple",
      "TV Series": "TV Series"
    }
  },

  "actions": {
    "submit": "Soumettre",
    "cancel": "Annuler",
    "create": "Créer",
    "update": "Mise à jour",
    "save": "Sauvegarder",
    "delete": "Supprimer",
    "export": "Export",
    "download": "Télécharger",
    "exporting": "Exporter",
    "back": "Retour",
    "remove": "Supprimer",
    "groupRemove": "Suppression de groupe",
    "removeSelection": "Supprimer la sélection",
    "add": "Ajouter",
    "no": "Non",
    "yes": "Oui",
    "addLanguage": "Ajouter un langage",
    "addFile": "Ajouter un fichier",
    "search": "Rechercher",
    "areYouSure" : "Êtes-vous sur de vouloir continuer ?"
  },

  "tabs": {
    "general": "Général",
    "details": "Détails",
    "ifeProducts": "Produits IFE",
    "exportCsp": "Export CSP",
    "generalInformation": "Informations générales",
    "rights": "Droits",
    "metadata": "Métadonnées",
    "pictures": "Images",
    "tracks": "Pistes",
    "history": "Historique",
    "mediaSections": "Séctions du média",
    "medias": "Médias",
    "homeConfig": "Config Accueil",
    "staticTranslations": "Traduction statique"
  },

  // Modules | Tabs
  "history": {
    "empty": "Aucun historique disponible"
  },

  // Side Menu
  "navbar": {
    "Account": "Compte",
    "Notifications": "Notifications",
    "Logout": "Déconnexion",
    "Dashboard": "Tableau de bord",
    "Projects": "Projets",
    "Portal Manager": "Portal Manager",
    "Media Library": "Médiathèque",
    "Menus": "Menus",
    "Artists": "Artistes",
    "Actors": "Acteurs",
    "Genres": "Genres",
    "Languages": "Langages",
    "Resources": "Ressources",
    "Admin": "Administrateur",
    "Users": "Utilisateurs"
  },

  "forms": {

    "rules": {
      "required": "{field} requis",
      "email": "Mauvais format d'e-mail",
      "datePriorTo": "{field} doit être antérieur à {toField}",
      "dateAfter": "{field} doit être après le {toField}",
      "between": "{field} doit être compris entre {start} et {end}"
    },
  },

  "buttons": {
    "search": "Rechercher",
    "upload": "Téléverser",
    "newUser": "Nouvel utilisateur",
    "newGenre": "Nouveau genre",
    "newActor": "Nouvel actor",
    "newProject": "Nouveau projet",
    "newMedia": "Nouveau média",
    "newMenu": "Nouveau menu",
    "addTranslation": "Ajouter une traduction",
    "addIfeProduct": "Ajouter un produit IFE",
    "addMetadata": "Ajouter une métadata",
    "addMediafile": "Ajouter un fichier multimédia",
    "addWithoutPhysicalFile": "Ajouter sans fichier physique"
  },

  "i18n": {
    "selectedLanguage": "Selected Langage sélectionné"
  },

  "guards": {
    "leaveEditor": {
      "header": 'Vous avez des changements non enregistrés!',
      message: 'Voulez-vous vraiment partir ?'
    }
  }

}