<template>
  <div>
    <Dialog
    v-model:visible="showIt"
    style="width:400px"
    @hide="$emit('update:modelValue', false);resetFields()"
    modal
    dismissableMask
    :closable="false"
    :draggable="false">
      <template #header>
        <div class="flex flex-auto flex-column text-700">
          <i class="pi pi-file-o text-center" style="fontSize: 3em"/>
          <h3 class="text-center">New Media</h3>
        </div>
      </template>

      <form class="p-fluid" @keyup.enter="create">
        <div class="fluid">
          <div class="field">
            <label for="contentFormat" :class="{'p-error':contentFormatError}" >Format</label>
            <Dropdown
              v-model="contentFormat"
              :options="contentFormats"
              :class="{'p-invalid': contentFormatError}"
              :disabled="isLoading"
              optionLabel="name"
              :placeholder="$t('common.selectFormat')" />
            <small v-if="contentFormatError" class="p-error">{{contentFormatError}}</small>
          </div>
          <div class="field" v-if="contentFormat">
            <label for="contentType" :class="{'p-error':contentTypeError}">{{ $t('resources.contentTypes.label') }}</label>
            <Dropdown
              v-model="contentType"
              :options="contentFormat.contentTypes"
              :class="{'p-invalid': contentTypeError}"
              :disabled="isLoading"
              optionLabel="name"
            :placeholder="$t('common.selectType')" />
            <small v-if="contentTypeError" class="p-error">{{contentTypeError}}</small>
          </div>
          <div class="field">
            <label for="defaultLanguage" :class="{'p-error':defaultLanguageError}">{{ $t('common.defaultLanguage') }}</label>
            <Dropdown
              v-model="defaultLanguage"
              :options="languages"
              :class="{'p-invalid': defaultLanguageError}"
              :disabled="isLoading"
              optionLabel="name"
              :placeholder="$t('common.selectDefaultLanguage')"
            />
            <small v-if="defaultLanguageError" class="p-error">{{defaultLanguageError}}</small>
           </div>
          <div class="field">
            <label for="name" :class="{'p-error':nameError}">{{ $t('common.name') }}</label>
            <InputText id="name" type="text"
              v-model="name"
              :class="{'p-invalid': nameError}"
              :disabled="isLoading"
            />
            <small v-if="nameError" class="p-error">{{nameError}}</small>
          </div>
        </div>
      </form>

      <Message v-if="errorCaught" severity="error" :closable="false">
        {{ errorCaught }}
      </Message>

      <template #footer>
        <div class="flex justify-content-between">
          <Button :label="$t('actions.cancel')" icon="pi pi-times" class="px-button-cancel p-button-text" @click="showIt = false"/>
          <Button :label="$t('actions.submit')" icon="pi pi-check" :loading="isLoading" @click="create"/>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import InputText from "primevue/inputtext"
import Dropdown from "primevue/dropdown"
import Message from "primevue/message"

import { mapGetters } from 'vuex'
import { required } from "@vuelidate/validators"
import { useVuelidate } from "@vuelidate/core"


export default {
  name: 'MediaNew',
  setup: () => ({ v$: useVuelidate() }),
  props: {
    modelValue: Boolean,
    tabOpen: {
      type: Boolean,
      default: false
    }
  },

  components: {
    InputText, Message, Dropdown
  },

  data: () => ({
    contentFormat: '',
    contentType: '',
    name: '',
    defaultLanguage: '',

    showIt: false,
    isLoading: false,
    errorCaught: ''
  }),

  methods: {
    resetFields () {
      this.v$.$reset()
      this.errorCaught = ''
      this.contentFormat = ''
      this.contentType = ''
      this.name = ''
      this.defaultLanguage = this.defaultInitLanguage
    },

    create () {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }

      this.errorCaught = ''
      this.isLoading = true

      this.$pxstream.portalmanager.createEmptyMedia({
        name: this.name,
        contentType: this.contentType.id,
        defaultLanguage: this.defaultLanguage.id
      })
      .then(res => {
        sessionStorage.setItem('portalmanager:media:new', JSON.stringify(res))
        if (this.tabOpen) {
          const routeData = this.$router.resolve(`/portal-manager/media/new`)
          window.open(routeData.href, '_blank')
          this.resetFields()
          this.$emit('update:modelValue', false)
        } else {
          this.$router.push(`/portal-manager/media/new`)
        }
      })
      .catch(({message}) => {
        this.errorCaught = message
      })
      .finally(() => {
        this.isLoading = false
      })
    }
  },

  computed: {
    ...mapGetters('resource', ['contentFormats', 'languages', 'languageById', 'defaultInitLanguage']),
    contentFormatError () {
      if (!this.v$.contentFormat.$dirty) return ''
      if (this.v$.contentFormat.required.$invalid) return 'Content Format is required.'
      return ''
    },
    contentTypeError () {
      if (!this.v$.contentType.$dirty) return ''
      if (this.v$.contentType.required.$invalid) return 'Content Type is required.'
      return ''
    },
    nameError () {
      if (!this.v$.name.$dirty) return ''
      if (this.v$.name.required.$invalid) return 'Name is required.'
      return ''
    },
    defaultLanguageError () {
      if (!this.v$.defaultLanguage.$dirty) return ''
      if (this.v$.defaultLanguage.required.$invalid) return 'Default language is required.'
      return ''
    }
  },

  validations: {
    contentFormat: { required },
    contentType: { required },
    name: { required },
    defaultLanguage: { required }
  },

  watch: {
    showIt (show) {
      if (show) {
        this.defaultLanguage = this.defaultInitLanguage
      }
    },
    modelValue: function (val) {
      this.showIt = val
    }
  }
}
</script>