<template>
  <div class="collections-edition panels">
    <draggable v-model="seasonsEditor" item-key="id" handle=".handle">
      <template #item="{element, index}">
        <Panel :collapsed="panelIsCollapsed(index)">
          <template #icons>
            <Button class="p-panel-header-icon mr-2" @click="panelToggle(index)">
              <span :class="`pi pi-${panelIsCollapsed(index) ? 'plus' : 'minus'}`"></span>
            </Button>
            <Button class="p-panel-header-icon mr-2" @click="deleteSeason(element.element, $event)">
              <span class="pi pi-trash text-danger"></span>
            </Button>
            <OverlayPanel class="delete-overlay" :ref="`op_${element.element.id}`" :dismissable="true">
              <span @click="onSeasonRemove(element.element, $event)" class="cursor-pointer underline text-danger">{{ $t('actions.remove') }}</span>
            </OverlayPanel>
          </template>
          <template #header>
            <div class="flex align-items-center">
              <button class="p-panel-header-icon handle cursor-move">
                <span class="pi pi-bars"></span>
              </button>
              <span class="pl-3" :class="{'p-error': hasLinksError(`portalmanager:media-tv-season:${element.element.id}`)}">
                <i v-if="hasLinksError(`portalmanager:media-tv-season:${element.element.id}`)" class="mr-1 pi pi-exclamation-triangle"></i>
                <span>{{ element.name.value }}</span> <span v-if="element.seasonNumber.value > 0">- S{{element.seasonNumber.value}}</span>
                <Tag class="ml-2 px-1" rounded>
                  <span class="mx-1" style="font-size: 1.1em">{{ element.episodes.value?.length || 0 }}</span>
                  <span class="mx-1" style="color: #eac1ff">{{$t('medias.episode.label', element.episodes.value)}} </span>
                </Tag>
              </span>
            </div>
          </template>
          <editing-season
            :doc="element.element"
            :editorMain="editorKey"
          />
        </Panel>
      </template>
      <template #footer>
        <div class="flex mt-3">
          <Button
            label="Add Season"
            class="p-button-outlined"
            icon="pi pi-plus"
            iconPos="left"
            @click="showNewSeason = true"
            />
          </div>
      </template>
    </draggable>
    <SeasonNew v-model="showNewSeason" :doc="doc" :editorMain="editorKey" @onSeasonAdded="onSeasonAdded" />
  </div>
</template>

<script>
import { ref, computed, onMounted, provide } from 'vue'
import { useDebounceFn } from '@vueuse/core'
import draggable from 'vuedraggable'
import Panel from 'primevue/panel'
import OverlayPanel from 'primevue/overlaypanel'
import Tag from 'primevue/tag'

import EditingSeason from '../EditingSeason.vue'
import SeasonNew from '../SeasonNew.vue'

import { useFlowEditor } from '@/compositions/useFlowEditor'
import { useFlowPMTVSeasonBuilder } from '@/compositions/useFlowPMTVSeasonEditor'
import { usePanelToggle } from '@/compositions/usePanelToggle'

const KFlowMedia = "portalmanager:media"
const KFlowMediaTVSeason = "portalmanager:media-tv-season"

export default {
  name: 'EditingSeasons',
  props: { editorKey: String },
  components: {
    draggable,
    Panel,
    OverlayPanel,
    Tag,
    EditingSeason,
    SeasonNew
  },
  setup (props, {emit}) {
    const deletedSeasons = ref([])
    const showNewSeason = ref(false)

    const { fieldGet, fieldSet, ...flowEditorAPI } = useFlowEditor(props.editorKey)
    const { panelSet, ...panelToggleAPI } = usePanelToggle()

    const seasons = computed({
      get: () => (fieldGet('media.seasons') || []),
      set: (value) => (fieldSet({field: 'media.seasons', value }))
    })

    let episodeRuntimes = {}

    const debouncedRuntimeUpdate = useDebounceFn(() => {
      fieldSet({field: 'media.runtime', value: Object.values(episodeRuntimes).reduce((serieRuntime, runtime) => serieRuntime + runtime, 0) })
    }, 500)

    provide('updateRuntime', (episodeId, runtime) => {
      episodeRuntimes[episodeId] = runtime
      debouncedRuntimeUpdate()
    })

    onMounted(() => {
      if (seasons.value.length == 1) {
        panelSet(0)
      }
    })
    const seasonsEditor = computed({
      get: () => {
        return seasons.value.map(season => {
          const {fieldGet, editorKey} = useFlowPMTVSeasonBuilder(season, props.editorKey, data => {
            emit('field-change', data)
          })
          return {
            editorKey,
            element: season,
            name: computed( () => fieldGet(`metadata.${season.defaultLanguage.id}.title`) || 'Untitled'),
            seasonNumber: computed( ()=> parseInt(fieldGet('seasonNumber'))),
            episodes: computed( ()=> fieldGet('episodes')),
          }
        })
      },
      set: (value) => {
        fieldSet({field: 'media.seasons', value: value.reduce((reOrderedSeasons, editor) => {
          reOrderedSeasons.push(seasons.value.find((season) => (editor.element.id == season.id)))
          return reOrderedSeasons
        }, []) })
      }
    })

    return {
      seasonsEditor,
      seasons,
      flow: KFlowMedia,
      deletedSeasons,
      showNewSeason,
      fieldGet,
      fieldSet,
      ...flowEditorAPI,
      panelSet,
      ...panelToggleAPI,
    }
  },
  methods: {
    onFieldChange (season, {field, value}) {
      if (field === 'episodes') {
        season.episodes = value
        return
      }
      const fields = field.split('.')
      if (fields[0] === 'metadata') {
        season.metadata[fields[1]][fields[2]] = value
      }
    },
    onSeasonAdded (season, { title, seasonNumber }) {
      const { saveFlowBuild, fieldSet } = useFlowPMTVSeasonBuilder(season, this.editorKey, data => {
        this.onFieldChange(season, data)
      })

      fieldSet({field: `metadata.${season.defaultLanguage.id}.title`, value: title})
      fieldSet({field: 'seasonNumber', value: seasonNumber})

      const req = saveFlowBuild()
      this.fieldPush({field: 'media.seasons', value: req.doc})
      this.saveFlowAddLink(req)
      this.panelSet(this.seasons.length - 1)
      // cleanup()
    },
    onSeasonRemove (e, $event) {
      this.fieldSplice({field: 'media.seasons', filter: (season) => {
        return season.id !== e.id
      }})
      this.saveFlowDeleteLinkElement({
        flow: KFlowMediaTVSeason,
        id: e.id
      })
      this.$refs[`op_${e.id}`].toggle($event)
    },
    deleteSeason(e, $event) {
      this.$refs[`op_${e.id}`].toggle($event)
    }
  }
}
</script>
