<template>
  <div class="field grid">
    <label class="col-12">{{$t('common.logo')}}:</label>
    <div class="col relative">
      <ImageUpload v-model:pxfile="logo" collection="portalmanager:menu" filename="logo" :height="100" :width="400" />
    </div>
  </div>
  <div class="field grid">
    <label class="col-12">{{$t('common.logoDark')}}</label>
    <div class="col relative">
      <ImageUpload v-model:pxfile="logoDark" collection="portalmanager:menu" filename="logo_dark" :height="100" :width="400" />
    </div>
  </div>
  <div class="field grid">
    <label class="col-12">{{$t('common.background')}}:</label>
    <div class="col relative">
      <ImageUpload v-model:pxfile="background" collection="portalmanager:menu" filename="background" :height="100" :width="400" />
    </div>
  </div>
  <div class="field grid">
    <label class="col-12">{{$t('common.backgroundDark')}}:</label>
    <div class="col relative">
      <ImageUpload v-model:pxfile="backgroundDark" collection="portalmanager:menu" filename="background_dark" :height="100" :width="400" />
    </div>
  </div>
</template>

<script>
import ImageUpload from '@/components/base/ImageUpload.vue'
import { useFlowEditor } from '@/compositions/useFlowEditor'
import { computed } from 'vue'

export default {
  props: { editorKey: String },
  components: {
    ImageUpload
  },
  setup (props) {
    const { fieldSet, fieldGet } = useFlowEditor(props.editorKey)

    const logo = computed({
      get: () => (fieldGet('Logo')),
      set: (value) => (fieldSet({field: 'Logo', value}))
    })
    const logoDark = computed({
      get: () => (fieldGet('LogoDark')),
      set: (value) => (fieldSet({field: 'LogoDark', value}))
    })
    const background = computed({
      get: () => (fieldGet('Background')),
      set: (value) => (fieldSet({field: 'Background', value}))
    })
    const backgroundDark = computed({
      get: () => (fieldGet('BackgroundDark')),
      set: (value) => (fieldSet({field: 'BackgroundDark', value}))
    })

    return {
      logo,
      logoDark,
      background,
      backgroundDark
    }
  }
}
</script>
