<template>
  <tr v-if="runtime >= 1" class="" role="row">
    <td class="column-title" role="cell">
      <span>{{$t('medias.runtime')}}:</span>
    </td>
    <td class="column-data flex" role="cell">
      {{ display }}
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    runtime: {
      type: Number,
      default: 0
    },
  },
  computed: {
    display () {
      return `${('0'+this.hour).slice(-2)} : ${('0'+this.minute).slice(-2)} : ${('0'+this.second).slice(-2)}`
    },
    hour () {
      return Math.floor(this.runtime / 3600)
    },
    minute () {
      return Math.floor(Math.floor(this.runtime % 3600) / 60)
    },
    second () {
      return Math.floor(this.runtime % 3600) % 60
    }
  },
}
</script>

<style lang="postcss" scoped>
  .column-title {
    font-weight: bold;
    font-size: 1rem;
  }

  .column-data {
    padding-left: 1.5rem;
  }
</style>