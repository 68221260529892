<template>
  <div class="col-12">
    <DataTable
      ref="dt"
      class="p-datatable-sm media-modal-datatable"
      stripedRows
      showGridlines
      filterDisplay="menu"
      responsiveLayout="scroll"
      rowGroupMode="rowspan"
      :groupRowsBy="mediaColumnsToSort"
      :value="mediafilesList"
      @row-reorder="onRowReorder">

      <Column field="0.name" header="Type" bodyClass="top-align">
        <template #body="{data}">
          <div v-if="data" class="flex">
            {{data.type}}
          </div>
        </template>
      </Column>

      <Column v-for="val in mediaColumns" :key="val.field" :field="val.field" :header="val.header" :bodyClass="val.class"></Column>

      <Column :rowReorder="!isPreviousCycle" headerStyle="width: 3rem"/>

      <Column field="mediafile" header="Mediafile" bodyClass="top-align">
        <template #body="{data}">
          <div class="flex">
            {{data.settings?.embeddedFilename ? data.settings.embeddedFilename : data.mediafile}}
          </div>
        </template>
      </Column>

      <Column :header="$t('medias.tracks')">
        <template #body="{data}">
          <ViewFlagRow field="id" :flags="data.audioTracks" :max="3" />
        </template>
      </Column>

      <Column header="Burned in Sub" v-if="!mediasAreOnlyAudio">
        <template #body="{data}">
          <ViewFlagRow field="id" :flags="data.burnedSubs" :max="3" />
        </template>
      </Column>

      <Column header="Dynamic Sub" v-if="!mediasAreOnlyAudio">
        <template #body="{data}">
          <ViewFlagRow field="language.id" :flags="data.dynamicSubs" :max="3" useCC />
        </template>
      </Column>

      <Column>
        <template #header>
          <div class="flex w-full justify-content-end">
            <Button type="button" :label="$t('actions.groupRemove')" :class="{ 'uppercase': true, 'p-button-outlined': !isGroupDelete }" @click="toggleGroupDelete()" />
          </div>
        </template>
        <template #body="{data}">
          <div class="flex justify-content-end align-items-center">
            <Button
              class="mr-2"
              icon="pi pi-pencil"
              :loading="(additionalMediafileOptions[data.id] || {}).isLoading"
              :disabled="isSomeMediafileLoading"
              @click="editMediafile(data)"
            />
            <Button
              class="mr-2"
              icon="pi pi-cog"
              :loading="(additionalMediafileOptions[data.id] || {}).isParametersLoading"
              @click="editSettings(data)"
            />
            <BtnConfirm
              v-if="!isGroupDelete"
              :class="{ 'disabled': data.isDeletionDisabled, 'mr-2': true }"
              :btnClass="'p-button-danger'"
              :loading="data.id === removingMediafileId"
              :disabled="data.isDeletionDisabled || !!removingMediafileId || isPreviousCycle"
              @confirm="onMediafileRemove(data)"
            />
            <Checkbox
              v-else
              :value="data"
              v-model="mediafilesToRemove"
              :disabled="data.isDeletionDisabled || !!removingMediafileId || isPreviousCycle"
            />
          </div>
        </template>
      </Column>
    </DataTable>
    <div class="flex mt-2 w-full justify-content-end">
      <div>
        <Button type="button" icon="pi pi-trash" :label="$t('actions.removeSelection')" class="uppercase p-button-outlined" @click="confirmGroupRemove()" :disabled="(mediafilesToRemove.length === 0)" />
      </div>
    </div>
    <Sidebar
      v-model:visible="editMediafileShowIt"
      position="right"
      class="p-sidebar-lg"
      :dismissable="false"
      :showCloseIcon="false"
    >
      <EditingMediafilesEditor
        v-model="editMediafileData"
        :isInitiating="editMediafileInitFromEditorKey !== null"
        :mediafileInitFrom="editMediafileInitFromEditorKey"
        :initList="editMediafileInitList"
        @close="editMediafileShowIt = false"
        @child-submit="updateDoc"
      />
    </Sidebar>
    <Dialog :header="`${$t('actions.groupRemove')}`" v-model:visible="showConfirmGroupRemove" :style="{width: '350px'}" modal>
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span>{{ $t('menus.confirmGroupRemoveForMediafiles')}}</span>

        <div v-if="mediafilesToRemove">
          <ul class="mt-2">
            <li v-for="(mediafile, index) in mediafilesToRemove" :key="`mediafileToRemove-${index}`">{{ mediafile[0].name }}</li>
          </ul>
        </div>

      </div>
      <template #footer>
        <Button @click="closeConfirmGroupRemove()" :label="$t('actions.no')" icon="pi pi-times"  class="p-button-text"/>
        <Button @click="onMediafileGroupRemove()" :label="$t('actions.yes')" icon="pi pi-check" class="p-button-text" autofocus />
      </template>
    </Dialog>
    <Dialog v-model:visible="editMediafileNoPossibleOverchargeModalWarningShowIt" :style="{width: '350px'}" modal>
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span>{{ $t('menus.confirmEditMediafileWithNoPosibleOvercharge')}}</span>

      </div>
      <template #footer>
        <Button @click="editMediafileNoPossibleOverchargeModalWarningShowIt = false" :label="$t('actions.no')" icon="pi pi-times"  class="p-button-text"/>
        <Button @click="confirmShowMediafile()" :label="$t('actions.yes')" icon="pi pi-check" class="p-button-text" autofocus />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { ref, computed, inject } from 'vue'
import { useRoute } from 'vue-router'
import { useFlowEditor, useFlowPMMediafileBuilder, useFlowPMMenuMediafileSettingsBuilder } from '@/compositions'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import Sidebar from 'primevue/sidebar'
import Checkbox from 'primevue/checkbox'
import Dialog from 'primevue/dialog'
import { useToast } from 'primevue/usetoast'

import BtnConfirm from '@/components/BtnConfirm.vue'
import Log from '@/services/logger'

import EditingMediafilesEditor from '@/pages/portalmanager/media/components/EditingMediafilesEditor.vue'

import ViewFlagRow from './tableRows/ViewFlagRow.vue'

const KFluxMedia = 'portalmanager:media'
const KFluxMediafile = 'portalmanager:mediafile'
const KFluxMediafileSettings = 'portalmanager:menu-mediafile-settings'

export default {
  name: 'MenuMediasTableMediafiles',
  setup: (props) => {
    const toast = useToast()
    const route = useRoute()
    const pxstream = inject('pxstream')
    const isPreviousCycle = inject('isPreviousCycle')
    const editSettings = inject('editSettings')

    const isGroupDelete = ref(false)
    const showConfirmGroupRemove = ref(false)
    const mediafilesToRemove = ref([])
    const removingMediafileId = ref()
    const additionalMediafileOptions = ref({})
    const editMediafileShowIt = ref(false)
    const editMediafileNoPossibleOverchargeModalWarningShowIt = ref(false)
    const editMediafileData = ref(null)
    const editMediafileInitFromEditorKey = ref(null)
    const editMediafileSettingsEditorKey = ref(null)

    const editMediafileInitList = ref([
      { field: 'filename' },
      { field: 'content.codec' },
      { field: 'content.bitrate' },
      { field: 'content.videoBitrate' },
      { field: 'content.encoding' },
      { field: 'content.encrypted' },
      { field: 'content.resolution' },
      { field: 'content.systemsCompliance' },
      { field: 'content.ratio' },
      { field: 'content.boxType' },
      { field: 'content.videoTrickMode' },
      { field: 'content.language' },
      { field: 'content.audioTracks' },
      { field: 'content.burnedSubs' },
      { field: 'content.dynamicSubs' },
      { field: 'lab' }
    ])

    const confirmGroupRemove = () => {
      showConfirmGroupRemove.value = true
    }

    const closeConfirmGroupRemove = () => {
      showConfirmGroupRemove.value = false
    }

    const getAudioTracksFromMediafileContent = (content) => {
      if (content.language) {
        if (content.language.id !== "0") {
          return [content.language]
        } else {
          return []
        }
      } else {
        return content.audioTracks.map(audioTrack => audioTrack.language)
      }
    }

    const getBurnedSubsFromMediafileContent = (content) => {
      if (content.burnedSubs) {
        return content.burnedSubs.map(burnedSub => burnedSub.language)
      } else {
        return []
      }
    }

    const getDynamicSubsFromMediafileContent = (content) => {
      if (content.dynamicSubs) {
        return content.dynamicSubs.map(dynamicSub => ({
          language: dynamicSub.language,
          cc: dynamicSub.CC
        }))
      } else {
        return []
      }
    }

    const editor = computed(() => { return useFlowEditor(props.editorKey) })

    const medias = computed({
      get () { return editor.value?.fieldGet('data.medias') || [] },
      set (value) { editor.value?.fieldSet({ field: 'data.medias', value }) }
    })
    
    const mediasById = computed(() => {
      return medias.value.reduce((acc, val) => {
        acc[val.id] = val
        return acc
      }, {})
    })

    const mediafiles = computed({
      get () { return editor.value?.fieldGet('data.mediafiles') || [] },
      set (value) { editor.value?.fieldSet({ field: 'data.mediafiles', value }) }
    })

    const mediafilesById = computed(() => {
      return mediafiles.value.reduce((acc, val) => {
        acc[val.id] = val
        return acc
      }, {})
    })

    const mediasAreOnlyAudio = computed(() => {
      return mediafiles.value.every(media => ['Album', 'Audio Simple', 'Podcast', 'Radio'].includes(media.contentType.name))
    })

    const mediafilesTree = computed({
      get () { return editor.value?.fieldGet('data.mediafilesTree') || [] },
      set (value) { editor.value?.fieldSet({ field: 'data.mediafilesTree', value }) }
    })

    const mediafilesSettings = computed({
      get () { return editor.value?.fieldGet('data.mediafilesSettings') || [] },
      set (value) { editor.value?.fieldSet({ field: 'data.mediafilesSettings', value }) }
    })
    const mediafilesSettingsByMediaAndMediafileId = computed(() => mediafilesSettings.value.reduce((acc, settings) => {
      if (settings?.media.id && settings?.mediafile.id) {
        acc[settings?.media.id+'.'+settings?.mediafile.id] = settings
      }
      return acc
    }, {}))

    const generateTVSimpleNameFromTVSeries = (tvSeries, tvSimple) => {
      const tvSeason = tvSeries.media.seasons.find(season => season.episodes.find(ep => ep.id === tvSimple.id))
      if (tvSeason && tvSeason.seasonNumber >= 1 && tvSimple.media.episodeNumber >= 1) {
        return `${tvSimple.name} S${tvSeason.seasonNumber} Episode ${tvSimple.media.episodeNumber}`
      }
      return tvSimple.name
    }

    const getFullMediafiles = (fathers) => (acc, val, idx) => {
      if (val.collection === KFluxMedia) {
        const fullMedia = mediasById.value[val.id]
        if (fullMedia) {
          const id = val.id
          let name = fullMedia.name
          if (fullMedia.mediaType === 'tv-simple' && fathers.length > 0) {
            const fullFatherMedia = mediasById.value[fathers[fathers.length-1].id]
            if (fullFatherMedia?.mediaType === 'tv-series') {
              name = generateTVSimpleNameFromTVSeries(fullFatherMedia, fullMedia)
            }
          }
          acc = (val.children || []).reduce(getFullMediafiles(fathers.concat([{id, name, idx}])), acc)
        }
      } else if (val.collection === KFluxMediafile) {
        const fullMediafile = mediafilesById.value[val.id]
        const settings = mediafilesSettingsByMediaAndMediafileId.value[fathers[fathers.length-1].id+"."+val.id] || null
        if (fullMediafile) {
          const newMediafileListItem = {
            type: mediasById.value[fathers[0].id].contentType.name,
            ...fathers,
            fathers,
            id: fullMediafile.id,
            mediafile: settings?.overchargedMediafile?.filename || fullMediafile.filename,
            isDeletionDisabled: mediasById.value[fathers[0].id].mediaType === 'audio-album',
            settings,
            isParametersLoading: false,
            audioTracks: getAudioTracksFromMediafileContent(settings?.overchargedMediafile?.content || fullMediafile.content),
            burnedSubs: getBurnedSubsFromMediafileContent(settings?.overchargedMediafile?.content || fullMediafile.content),
            dynamicSubs: getDynamicSubsFromMediafileContent(settings?.overchargedMediafile?.content || fullMediafile.content),
          }
          acc.push(newMediafileListItem)
        }
      }
      return acc
    }

    const mediafilesList = computed(() => {
      return mediafilesTree.value.reduce(getFullMediafiles([]), [])
    })

    const mediafilesNbMediaFathers = computed(() => { return Math.max(...mediafilesList.value.map((val) => val.fathers.length)) })

    const mediaColumns = computed(() => {
      const res = []
      for (let i = 0; i < mediafilesNbMediaFathers.value; i++) {
        res.push({
          header: `Name`,
          field: `${i}.name`,
          class: i === 0 ? 'top-align' : ''
        })
      }
      return res
    })

    const mediaColumnsToSort = computed(() => mediaColumns.value.map(col => col.field))

    const isSomeMediafileLoading = computed(() => !!Object.values(additionalMediafileOptions.value).find(value => value.isLoading))

    const getThreeFirstElements = (array) => {
      return (array || []).filter((val, idx) => idx < 3)
    }

    const onRowReorder = (e) => {
      let newMediafilesTree = mediafilesTree.value

      const direction = e.dragIndex < e.dropIndex ? 'down' : 'up'
      const dragValue = mediafilesList.value[e.dragIndex]
      const dropValue = mediafilesList.value[e.dropIndex]

      const isDroppingNextToMediafileFromSameMedia = JSON.stringify(dragValue.fathers) === JSON.stringify(dropValue.fathers)
      if (isDroppingNextToMediafileFromSameMedia) {
        const invertInMap = (fatherIdxCount) => (val, idx) => {
          if (idx === dragValue.fathers[fatherIdxCount].idx) {
            if (fatherIdxCount === dragValue.fathers.length-1) {
              const idxToDrag = val.children.findIndex(child => child.id === dragValue.id)
              const idxToDrop = val.children.findIndex(child => child.id === dropValue.id)
              const toDragFullValue = val.children[idxToDrag]
              val.children.splice(idxToDrag, 1)
              val.children.splice(idxToDrop, 0, toDragFullValue)
            } else {
              val.children = val.children.map(invertInMap(fatherIdxCount+1))
            }
          }
          return val
        }
        newMediafilesTree = newMediafilesTree.map(invertInMap(0))
      } else {
        const invertInMap = (fatherIdxCount) => (val, idx) => {
          if (idx === dragValue.fathers[fatherIdxCount].idx) {
            if (fatherIdxCount === dragValue.fathers.length-1) {
              const idxToDrag = val.children.findIndex(child => child.id === dragValue.id)
              const idxToDrop = direction === 'down' ? val.children.length-1 : 0
              const toDragFullValue = val.children[idxToDrag]
              val.children.splice(idxToDrag, 1)
              val.children.splice(idxToDrop, 0, toDragFullValue)
            } else {
              val.children = val.children.map(invertInMap(fatherIdxCount+1))
            }
          }
          return val
        }
        newMediafilesTree = newMediafilesTree.map(invertInMap(0))
      }
      mediafilesTree.value = newMediafilesTree
    }

    const toggleGroupDelete = () => {
      isGroupDelete.value = !isGroupDelete.value
      mediafilesToRemove.value = []
    }

    const onMediafileGroupRemove = () => {
      mediafilesToRemove.value.forEach(mediafile => {
        onMediafileRemove(mediafile)
      })
      mediafilesToRemove.value = []
      closeConfirmGroupRemove()
    }

    const onMediafileRemove = (data) => {
      removingMediafileId.value = data.id
      pxstream.tools.avoidRenderingStuck(() => {

        const mediasToRm = []

        const reduceMediafilesTreeFct = (acc, val) => {
          if (val.collection === KFluxMedia) {
            val.children = val.children.reduce(reduceMediafilesTreeFct, [])
            if (val.children.length === 0) {
              mediasToRm.push(val.id)
            } else {
              acc.push(val)
            }
          } else if (val.collection === KFluxMediafile) {
            if (data.id !== val.id) {
              acc.push(val)
            }
          }

          return acc
        }

        editor.value?.pauseUpdateChecking()
        mediafilesTree.value = mediafilesTree.value.reduce(reduceMediafilesTreeFct, [])
        medias.value = medias.value.filter(media => !mediasToRm.includes(media.id))
        mediafiles.value = mediafiles.value.filter(mediafile => mediafile.id !== data.id)
        mediafilesSettings.value = mediafilesSettings.value.filter(mfSettings => {
          const isIt = mfSettings.mediafile.id === data.id
          if (isIt) {
            editor.value?.saveFlowDeleteLinkElement({
              flow: KFluxMediafileSettings,
              id: mfSettings.id
            })
          }
          return !isIt
        })
        editor.value?.resumeUpdateChecking()

        removingMediafileId.value = null
      })
    }

    const areEditableMediafiles = async (mediafile) => {
      additionalMediafileOptions.value[mediafile.id] = {
        editable: false,
        isLoading: true,
        isParametersLoading: false,
      }

      if (mediafilesById.value[mediafile.id]?.pxfile?.uuid) {
        additionalMediafileOptions.value[mediafile.id].isLoading = false
        additionalMediafileOptions.value[mediafile.id].editable = true
        return
      }

      const params = {
        filters: {
          id: {
            matchMode: 'nin',
            value: [route.params.id]
          },
          'cycle.sections.data.mediafiles': {
            matchMode: 'contains',
            value: [mediafile.id]
          }
        }
      }

      try {
        const data = await pxstream.portalmanager.getMenuList(params)
        additionalMediafileOptions.value[mediafile.id].editable = (data.total === 0)
      } catch (e) {
        toast.add({severity: 'error', summary: 'Failed to get media with mediafile info', detail: e.toString(), life: 4000})
      }
      additionalMediafileOptions.value[mediafile.id].isLoading = false
    }

    const initializeMediafileSettingsIfNeeded = async (data) => {

      const initMediafileSettings = async (pMediafile) => {
        return pxstream.portalmanager.createEmptyMenuMediafileSettings({media: pMediafile.fathers[pMediafile.fathers.length-1].id, mediafile: pMediafile.id})
        .then((response) => {
          response.data.id = 'new'
          const newSettings = useFlowPMMenuMediafileSettingsBuilder({
            propDoc: response.data,
            editorMain: props.editorKey,
            onFieldChange: () => {
              // DO NOTHING
            },
            onUpdateChange: () => {
              // DO NOTHING
            }
          })
          mediafilesSettings.value = [...mediafilesSettings.value, newSettings.doc]
          data.settings = newSettings.doc
        }).catch((e) => {
          Log.Error(e)
        })
      }

      const noMediafileSettings = !data.settings

      if (noMediafileSettings && !additionalMediafileOptions.value[data.id].editable) {
        await initMediafileSettings(data)
      }
    }

    const editMediafile = async (mediafile) => {
      editMediafileData.value = null
      editMediafileInitFromEditorKey.value = null
      editMediafileShowIt.value = false
      editMediafileSettingsEditorKey.value = null

      await areEditableMediafiles(mediafile)
      await initializeMediafileSettingsIfNeeded(mediafile)

      let editorKey, fieldGet, overchargedMediafile
      if (mediafile.settings) {
        const builder = useFlowPMMenuMediafileSettingsBuilder({
          propDoc: mediafile.settings,
          editorMain: props.editorKey,
          onFieldChange: () => {
            // DO NOTHING
          },
          onUpdateChange: () => {
            // DO NOTHING
          }
        })
        editorKey = builder.editorKey
        fieldGet = builder.fieldGet
        overchargedMediafile = fieldGet('overchargedMediafile')
      }
      if (additionalMediafileOptions.value[mediafile.id].editable && !overchargedMediafile) {
        editMediafileData.value = mediafilesById.value[mediafile.id]
        if (mediafilesById.value[mediafile.id]?.pxfile?.uuid) {
          editMediafileNoPossibleOverchargeModalWarningShowIt.value = true
        } else {
          editMediafileShowIt.value = true
        }
      } else {
        additionalMediafileOptions.value[mediafile.id].isLoading = true
        editMediafileSettingsEditorKey.value = editorKey
        if (!overchargedMediafile) {
          const { data } = await pxstream.portalmanager.createEmptyMediafile({contentType: fieldGet('media.contentType.id')})
          data.id = 'new'
          editMediafileData.value = data
          const { editorKey } = useFlowPMMediafileBuilder({
            propDoc: mediafilesById.value[mediafile.id],
            onFieldChange: () => {},
            onUpdateChange: () => {}
          })
          editMediafileInitFromEditorKey.value = editorKey
        } else {
          editMediafileData.value = overchargedMediafile
        }
        editMediafileShowIt.value = true
        additionalMediafileOptions.value[mediafile.id].isLoading = false
      }
      editMediafileData.value.isOvercharged = overchargedMediafile !== null
    }

    const updateDoc = (req) => {
      if (editMediafileSettingsEditorKey.value) {
        const { fieldSet, saveFlowAddLink, doc } = useFlowEditor(editMediafileSettingsEditorKey.value)
        if (req.type === 'new') {
          fieldSet({field: 'overchargedMediafile', value: req.doc})
        }
        // Update fields info of listing
        for (let i = 0; i < mediafilesSettings.value.length; i++) {
          if (mediafilesSettings.value[i].id === doc.id) {
            if (req.type === 'new') {
              mediafilesSettings.value[i].overchargedMediafile = req.doc
            }
            mediafilesSettings.value[i].overchargedMediafile.filename = req.doc.filename
            mediafilesSettings.value[i].overchargedMediafile.lab = req.doc.lab
            mediafilesSettings.value[i].overchargedMediafile.content = req.doc.content
            break
          }
        }
        saveFlowAddLink(req)
      } else if (editor.value) {
        // Update fields info of listing
        for (let i = 0; i < mediafiles.value.length; i++) {
          if (mediafiles.value[i].id === req.id) {
            mediafiles.value[i].filename = req.doc.filename
            mediafiles.value[i].lab = req.doc.lab
            mediafiles.value[i].content = req.doc.content
            break
          }
        }
        editor.value.saveFlowAddLink(req)
      }
      editMediafileShowIt.value = false
    }

    const localEditSettings = async (data) => {
      if (!additionalMediafileOptions.value[data.id]) {
        additionalMediafileOptions.value[data.id] = {
          editable: false,
          isLoading: false,
          isParametersLoading: true,
        }
      } else {
        additionalMediafileOptions.value[data.id].isParametersLoading = true
      }

      await initializeMediafileSettingsIfNeeded(data)
      await editSettings(data)
      additionalMediafileOptions.value[data.id].isParametersLoading = false
    }

    const confirmShowMediafile = () => {
      editMediafileNoPossibleOverchargeModalWarningShowIt.value = false
      editMediafileShowIt.value = true
    }

    return {
      isPreviousCycle,
      showConfirmGroupRemove,
      removingMediafileId,
      additionalMediafileOptions,
      editMediafileShowIt,
      editMediafileNoPossibleOverchargeModalWarningShowIt,
      editMediafileData,
      editMediafileInitFromEditorKey,
      editMediafileInitList,
      mediafilesList,
      mediaColumns,
      mediaColumnsToSort,
      mediasAreOnlyAudio,
      isSomeMediafileLoading,
      isGroupDelete,
      mediafilesToRemove,
      toggleGroupDelete,
      confirmGroupRemove,
      closeConfirmGroupRemove,
      getThreeFirstElements,
      onRowReorder,
      onMediafileRemove,
      onMediafileGroupRemove,
      editMediafile,
      updateDoc,
      editSettings: localEditSettings,
      confirmShowMediafile
    }
  },
  props: {
    editorKey: String
  },
  components: {
    DataTable,
    Column,
    BtnConfirm,
    Button,
    ViewFlagRow,
    Sidebar,
    Checkbox,
    Dialog,
    EditingMediafilesEditor
  }
}
</script>

<style>
.top-align {
  vertical-align: top;
}
td {
  overflow-wrap: anywhere;
}
</style>
