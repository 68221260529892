<template>
  <a class="p-menuitem-link" role="presentation" :tabindex="index" @click.prevent.stop="onClick">
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: 'TabEditor.Tab',
  props: ['index'],
  emits: ['active'],
  setup (props, {emit}) {
    return {
      onClick: () => emit('active', props.index)
    }
  }
}
</script>

<style scoped>
a.p-menuitem-link {
  min-height: 40px;
  white-space: nowrap;
}
</style>