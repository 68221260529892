<template>
  <div v-if="editorKey" class="grid">
    <div class="col-12 md:col-7">

      <div class="field grid">
        <label for="name" :class="{'p-error': nameError, 'col-12 md:col-3 font-bold': true}">{{ $t('common.name') }}</label>
        <div class="col md:col-8">
          <span class="p-fluid">
            <InputText id="name" type="text"
              :modelValue="name"
              v-debounce:300ms="(val) => (name = val)"
              :class="{'p-invalid': nameError}"
              :disabled="isLoading"
            />
            <small v-if="nameError" class="p-error">{{nameError}}</small>
          </span>
        </div>
      </div>

      <div class="field grid">
        <label for="hasViolence" class="col-12 md:col-3">Violence</label>
        <div class="col md:col-8">
          <InputSwitch v-model="hasViolence" id="hasViolence" :disabled="ratingUS.id === 'PG13' ? 'disabled': ''" />
        </div>
      </div>

      <div class="field grid">
        <label for="ratingUS" class="col-12 md:col-3">{{ $t('medias.ratingUs') }}</label>
        <div class="col md:col-8">
          <span class="p-fluid">
            <AutoComplete
              v-model="ratingUS"
              itemValue="id"
              field="name"
              dropdown
              dropdownIcon="search"
              :suggestions="listingRatingsUS"
              @complete="searchRatingsUS($event)"
              @update:modelValue="checkRatingUS()"
              class="w-full" />
          </span>
        </div>
      </div>

      <div class="field grid">
        <label for="rating" class="col-12 md:col-3">{{ $t('medias.rating') }}</label>
        <div class="col md:col-8">
          <RatingEditor v-model="rating" />
        </div>
      </div>

      <div class="field grid">
        <label for="hasOffensiveLanguage" class="col-12 md:col-3">{{ $t('medias.offensiveLanguage') }}</label>
        <div class="col md:col-8">
          <InputSwitch v-model="hasOffensiveLanguage" id="hasOffensiveLanguage" :disabled="ratingUS.id === 'PG13' ? 'disabled': ''" />
        </div>
      </div>

      <div class="field grid">
        <label for="hasSex" class="col-12 md:col-3">{{ $t('medias.sex') }}</label>
        <div class="col md:col-8">
          <InputSwitch v-model="hasSex" id="hasSex" :disabled="ratingUS.id === 'PG13' ? 'disabled': ''" />
        </div>
      </div>

    </div>
    <div class="col-12 md:col-5">
      <div class="field grid">
        <label for="year" class="col-12 md:col-6">{{ $t('common.year') }}</label>
        <div class="col md:col-6">
            <YearEditor v-model="year" />
        </div>
      </div>

      <div class="field grid">
        <label for="countries" class="col-12 md:col-6">{{ $t('common.countries') }}</label>
        <div class="col md:col-6">
          <span class="p-fluid">
            <AutoCompleteSearch
              v-model="countries"
              itemValue="id"
              field="name"
              dropdown
              dropdownIcon="search"
              multiple
              :suggestions="listingCountries"
              @complete="searchCountries($event)"
              :suggestionsTotal="listingCountriesTotal"
            />
          </span>
        </div>
      </div>

      <div class="field grid">
        <label for="multiplayer" class="col-12 md:col-3">{{ $t('medias.multiplayer') }}</label>
        <div class="col md:col-8">
          <InputSwitch v-model="multiplayer" id="multiplayer" />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'

import InputText from 'primevue/inputtext'
import AutoComplete from 'primevue/autocomplete'
import AutoCompleteSearch from '@/components/base/AutoCompleteSearch.vue'
import InputSwitch from 'primevue/inputswitch'

import YearEditor from '@/components/resource/YearEditor.vue'
import RatingEditor from '@/components/resource/RatingEditor.vue'

import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { getDirective as vueDebounce } from 'vue-debounce'

import { useFlowCruder, useFlowEditor } from '@/compositions'

const KOptionsName = 'EditingMediaGame'


export default {
  name: KOptionsName,
  props: { editorKey: String },
  setup (props) {
    // hasUpdate, isSaving, fieldGet, fieldSet, fieldPush, fieldSplice, checkHasUpdate, save
    const {setError, ...use} = useFlowEditor(props.editorKey)

    const listingRatingsUS = ref([
    { id: 'R', name: 'R' },
    { id: 'NR', name: 'NR' },
    { id: 'NR17', name: 'NR-17' },
    { id: 'G', name: 'G' },
    { id: 'PG', name: 'PG' },
    { id: 'PG13', name: 'PG-13' }
    ])

    const name = computed({
      get () { return use.fieldGet('name') },
      set (val) { use.fieldSet({field: 'name', value: val}) }
    })

    const rating = computed({
        get () { return use.fieldGet('media.rating') },
        set (val) { use.fieldSet({field: 'media.rating', value: val}) }
    })

    const ratingUS = computed({
      get () {
        const ratingUSList = use.fieldGet('media.ratingUS')
        return listingRatingsUS.value.find(rating => rating.id === ratingUSList) || ratingUSList
      },
      set (val) { use.fieldSet({field: 'media.ratingUS', value: val.id}) }
    })

    const multiplayer = computed({
        get () { return use.fieldGet('media.multiplayer') },
        set (val) { use.fieldSet({field: 'media.multiplayer', value: val}) }
    })

    const hasViolence = computed({
        get () { return use.fieldGet('media.hasViolence') },
        set (val) { use.fieldSet({field: 'media.hasViolence', value: val || false}) }
    })

    const hasOffensiveLanguage = computed({
        get () { return use.fieldGet('media.hasOffensiveLanguage') },
        set (val) { use.fieldSet({field: 'media.hasOffensiveLanguage', value: val || false}) }
    })

    const hasSex = computed({
        get () { return use.fieldGet('media.hasSex') },
        set (val) { use.fieldSet({field: 'media.hasSex', value: val || false}) }
    })

    const searchRatingsUS = ({query}) => {
      listingRatingsUS.value = listingRatingsUS.value.filter((v) => v.name.includes(query))
    }

    const checkRatingUS = () => {
      if (ratingUS.value.id === 'PG13') {
        hasViolence.value = true
        hasOffensiveLanguage.value = true
        hasSex.value = true
      }
    }

    const rules = {
      name: {
        required
      }
    }

    const v$ = useVuelidate(rules, { name }, { $scope: false })

    if (v$.value.$invalid) {
      setError(new Error('InvalidForm'), KOptionsName)
    }

    return {
      ...use,
      name,
      rating,
      ratingUS,
      listingRatingsUS,
      multiplayer,
      searchRatingsUS,
      checkRatingUS,
      hasViolence,
      hasOffensiveLanguage,
      hasSex,
      v$,
      setError,
      ...useFlowCruder()
    }
  },
  directives: {
    debounce: vueDebounce(3),
  },
  components: {
    InputText, AutoCompleteSearch, AutoComplete, InputSwitch, YearEditor, RatingEditor
  },
  data: () => ({
    listingCountries: [],
    listingCountriesTotal: 0
  }),
  watch: {
    'v$.$invalid': function (val) {
      val ? this.setError(new Error('InvalidForm'), KOptionsName) : this.setError(null, KOptionsName)
    }
  },
  computed: {
    year: {
      get () { return this.fieldGet('media.year') },
      set (val) { this.fieldSet({field: 'media.year', value: val}) }
    },
    countries: {
      get () { return this.fieldGet('media.countries') },
      set (val) { this.fieldSet({field: 'media.countries', value: val}) }
    },
    isLoading () {
      return this.isSaving
    },
    nameError () {
      if (this.v$.name.required.$invalid) return 'Name is required'
      return ''
    },
  },
  methods: {
    async searchCountries ({query}) {
      try {
        const qryBuilder = this.$pxstream.tools.createQueryBuilder()
        qryBuilder.setFilterSearch(query)
        if (this.countries && this.countries.length !== 0) {
          qryBuilder.addFilter('id', 'nin', this.countries.map((country) => country.id))
        }
        const {data, total} = await this.$pxstream.resource.getCountries(qryBuilder.build())
        this.listingCountries = data
        this.listingCountriesTotal = total
      } catch (err) {
        this.$toast.add({severity: 'error', summary: 'Failed to get countries', detail: this.$pxstream.http.getHttpErrorShortMessage(err), life: 4000})
      }
    }
  }
}
</script>