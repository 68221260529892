
export default {
  install: (app) => {

    const getElapsedTime = (pPreviousDate) => {
      const currentDate = new Date()
      let diff = currentDate - pPreviousDate

      const intervals = {
        second: 1000,
        minute: 1000 * 60,
        hour: 1000 * 60 * 60,
        day: 1000 * 60 * 60 * 24
      }
    
      const days = Math.floor(diff / intervals.day)
      diff -= days * intervals.day
      const hours = Math.floor(diff / intervals.hour)
      diff -= hours * intervals.hour
      const minutes = Math.floor(diff / intervals.minute)
      // diff -= minutes * intervals.minute
      // const secondes = Math.floor(diff / intervals.second)
    
      if (days > 0) {
        return (t) => t('common.time.past', {time: `${t('common.time.day', days, {n: days})} ${t('common.and')} ${t('common.time.hour', hours, {n: hours})}` })
      } else if (hours > 0) {
        return (t) => t('common.time.past', {time: `${t('common.time.hour', hours, {n: hours})} ${t('common.and')} ${t('common.time.minute', minutes, {n: minutes})}` })
      } else if (minutes > 0) {
        return (t) => t('common.time.past', {time: `${t('common.time.minutes', minutes, {n: minutes})}`});
      } else {
        return (t) => t('common.time.lessThanOneMinute')
      }
    }

    app.config.globalProperties.$elapsedTime = getElapsedTime
    app.provide('elapsedTime', getElapsedTime)
  }
}
