<template>
  <div v-if="modelValue">
    {{ fieldGet('mediaType') }}
    <Toolbar>
      <template #left>
        <h1 class="font-light"><i class="pi pi-clone pr-3 text-2xl" v-if="isOvercharged" />{{ name || 'New mediafile' }}</h1>
      </template>

      <template #right>
        <span class="p-buttonset">
          <Button :label="$t('actions.cancel')" class="p-button-outlined" icon="pi pi-times" @click="close"/>
          <Button :label="$t('actions.save')" icon="pi pi-check" iconPos="right" @click="update" :disabled="!hasUpdate" />
        </span>
      </template>
    </Toolbar>

    <br/>

    <div class="grid">
      <div class="col-12 md:col-8">
        <div class="field grid">
          <label for="file" class="col-12 md:col-3 font-bold">{{ $t('common.file') }}</label>
          <div class="col md:col-8">
            <div class="grid no-margin">
              <InputText type="text" :modelValue="pxfile.filename" disabled />
              <Button icon="pi pi-upload" @click="clickUpload()" :loading="isUploading" />
              <FileUpload ref="fileUpload" class="invisible" :auto="true" mode="basic" name="File" chooseLabel=" " uploadLabel="" :accept="uploadAuthorizedExtensions" :customUpload="true" @uploader="upload" />
            </div>
          </div>
        </div>

        <div class="field grid">
          <label for="name" class="col-12 md:col-3 font-bold">{{ $t('common.title') }}</label>
          <div class="col md:col-8">
            <span class="p-fluid">
              <InputText id="name" type="text" v-model="name" />
              <span v-if="nameError" class="p-error">{{nameError}}</span>
            </span>
          </div>
        </div>

      </div>
    </div>

    <component v-if="false" :is="mediafileComponent" :editorKey="editorKey"/>

    <div v-if="isMovieType">
      <div class="grid">
        <div class="col-12 md:col-6">

          <div class="field grid">
            <label for="codec" class="col-12 md:col-4">{{ $t('common.codec') }}</label>
            <div class="col md:col-8">
              <Dropdown v-model="codec" :options="rscCodecsVideo" optionValue="id" optionLabel="name" :placeholder="$t('common.selectCodec')" class="w-full" :disabled="!!pxfile?.uuid && hasCodecFromFile" />
            </div>
          </div>

          <div class="field grid">
            <label for="isEncrypted" class="col-12 md:col-4">{{ $t('common.encrypted') }}</label>
            <div class="col md:col-8">
              <InputSwitch v-model="isEncrypted " id="isEncrypted" />
            </div>
          </div>

          <div class="field grid">
            <label for="systemsCompliance" class="col-12 md:col-4">{{ $t('common.systemsCompliance') }}</label>
            <div class="col md:col-8">
              <SystemComplianceEditor :editorKey="editorKey" />
            </div>
          </div>

          <div class="field grid">
            <label for="embeddedFilename" class="col-12 md:col-4">{{ $t('common.embeddedFilename') }}</label>
            <div class="col md:col-8">
              <span class="p-fluid">
                <InputText id="embeddedFilename" type="text" v-model="embeddedFilename" />
              </span>
            </div>
          </div>

        </div>
        <div class="col-12 md:col-5 md:col-offset-1">

          <div class="field grid">
            <label for="videoBitrate" class="col-12 md:col-4">{{ $t('common.videoBitrate') }}</label>
            <div class="col md:col-8">
              <Dropdown v-model="videoBitrate" :options="rscVideoBitrates" optionValue="id" optionLabel="name" :placeholder="$t('common.selectVideoBitrate')" class="w-full" :disabled="!!pxfile?.uuid" />
            </div>
          </div>

          <div class="field grid">
            <label for="isEncrypted" class="col-12 md:col-4">Lab</label>
            <div class="col md:col-8">
              <AutoComplete
                v-model="lab"
                dropdown
                field="name"
                itemValue="name"
                :suggestions="rscLabs"
                @complete="searchLabs($event)">
              </AutoComplete>
            </div>
          </div>

          <div class="field grid">
            <label for="resolution" class="col-12 md:col-4">{{ $t('common.resolution') }}</label>
            <div class="col md:col-8">
              <Dropdown v-model="resolution" :options="rscResolutions" optionValue="id" optionLabel="name" :placeholder="$t('common.selectResolution')" class="w-full" />
            </div>
          </div>

          <div class="field grid">
            <label for="ratio" class="col-12 md:col-4">Ratio</label>
            <div class="col md:col-8">
              <Dropdown v-model="ratio" :options="rscRatios" optionValue="id" optionLabel="name" :placeholder="$t('common.selectRatio')" class="w-full" />
            </div>
          </div>

          <div class="field grid">
            <label for="boxType" class="col-12 md:col-4">{{ $t('common.boxType') }}</label>
            <div class="col md:col-8">
              <Dropdown v-model="boxType" :options="rscBoxTypes" optionValue="id" optionLabel="name" :placeholder="$t('common.selectBoxType')" class="w-full" />
            </div>
          </div>

        </div>
      </div>

      <div>
        <DataTable :value="audioTracks" @row-reorder="onRowReorder($event, 'audioTracks')" responsiveLayout="scroll" class="p-datatable-sm">
          <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
          <Column headerStyle="width: 10rem">
            <template #body="{index}">
              {{ $t('common.audioTrack') }} {{ index + 1 }}
            </template>
          </Column>
          <Column>
            <template #body="{data}">
              <LanguageSelector
                :modelValue="data.language"
                @update:modelValue="updateLanguage(data.language, $event, audioTracksLanguageListCounter, 'audioTracks')"
                v-model="data.language"
                @change="checkHasUpdate()"
                compact
                :getOnlyID="false"
                :sort="languageSort"
                class="w-full"
                :disabled="!!pxfile?.uuid && data.isFromPXFile"/>
              <span v-if="audioTracksErrorsList.includes(data.uuid)" class="p-error">{{audioTracksError}}</span>
              <span v-if="audioTracksLanguageListCounter[data.language?.id] > 1" class="p-error">{{$t('common.pleaseSelectAnotherLanguage')}}</span>
            </template>
          </Column>
          <Column>
            <template #body="{index}">
              <div class="flex justify-content-end w-full">
                <BtnConfirm @confirm="removeAudioTrack(index)" :btnClass="{'p-button-text': true}" />
              </div>
            </template>
          </Column>
        </DataTable>
        <br/>
        <div class="flex justify-content-center">
          <Button label="Add audio track" class="p-button-outlined" icon="pi pi-plus-circle" @click="addAudioTrack(null, false)"/>
        </div>
      </div>

      <br/>

      <div>
        <DataTable :value="burnedSubs" @row-reorder="onRowReorder($event, 'burnedSubs')" responsiveLayout="scroll" class="p-datatable-sm">
          <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
          <Column headerStyle="width: 10rem">
            <template #body="{index}">
              {{ $t('common.burnedInSub') }} {{ index + 1 }}
            </template>
          </Column>
          <Column>
            <template #body="{data}">
              <LanguageSelector :modelValue="data.language" @update:modelValue="updateLanguage(data.language, $event, burnedSubsLanguageListCounter, 'burnedSubs')" v-model="data.language" @change="checkHasUpdate()" compact :getOnlyID="false" :sort="languageSort" class="w-full"/>
              <span v-if="burnedSubsErrorsList.includes(data.uuid)" class="p-error">{{burnedSubsError}}</span>
              <span v-if="burnedSubsLanguageListCounter[data.language?.id] > 1" class="p-error">{{$t('common.pleaseSelectAnotherLanguage')}}</span>
            </template>
          </Column>
          <Column>
            <template #body="{index}">
              <div class="flex justify-content-end w-full">
                <BtnConfirm @confirm="removeBurnedSub(index)" :btnClass="{'p-button-text': true}" />
              </div>
            </template>
          </Column>
        </DataTable>
        <br/>
        <div v-if="burnedSubsLength < 2" class="flex justify-content-center">
          <Button label="Add burned in sub" class="p-button-outlined" icon="pi pi-plus-circle" @click="addBurnedSub"/>
        </div>
      </div>

      <br/>

      <div>
        <DataTable :value="dynamicSubs" @row-reorder="onRowReorder($event, 'dynamicSubs')" responsiveLayout="scroll" class="p-datatable-sm">
          <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
          <Column headerStyle="width: 10rem">
            <template #body="{index}">
              {{ $t('common.dynamicsubs') }} {{ index + 1 }}
            </template>
          </Column>
          <Column>
            <template #body="{data}">
              <LanguageSelector
                :modelValue="data.language"
                @update:modelValue="updateDynamicSubsLanguage(data, $event)"
                v-model="data.language"
                @change="checkHasUpdate()"
                compact
                :getOnlyID="false"
                :sort="languageSort"
                class="w-full"
                :disabled="!!pxfile?.uuid && data.isFromPXFile"/>
              <span v-if="dynamicSubsErrorsList.includes(data.uuid)" class="p-error">{{dynamicSubsError}}</span>
              <span v-if="dynamicSubsLanguageListCounter[`${data.language?.id}${data.CC}`] > 1" class="p-error">{{$t('common.pleaseSelectAnotherLanguage')}}</span>
            </template>
          </Column>
          <Column>
            <template #body="{data}">
              <div class="grid">
                <label for="isEncrypted" class="col-12 md:col-4">CC</label>
                <div class="col md:col-8">
                  <InputSwitch :modelValue="data.CC" @update:modelValue="updateDynamicSubsCCLanguage(data, $event)" v-model="data.CC" @change="checkHasUpdate()" />
                </div>
              </div>
            </template>
          </Column>
          <Column>
            <template #body="{index}">
              <div class="flex justify-content-end w-full">
                <BtnConfirm @confirm="removeDynamicSub(index)" :btnClass="{'p-button-text': true}" />
              </div>
            </template>
          </Column>
        </DataTable>
        <br/>
        <div class="flex justify-content-center">
          <Button label="Add dynamic sub" class="p-button-outlined" icon="pi pi-plus-circle" @click="addDynamicSub(null)"/>
        </div>
      </div>
    </div>

    <div v-if="isTVType">
      <div class="grid">
        <div class="col-12 md:col-6">

          <div class="field grid">
            <label for="codec" class="col-12 md:col-4">{{ $t('common.codec') }}</label>
            <div class="col md:col-8">
              <Dropdown v-model="codec" :options="rscCodecsVideo" optionValue="id" optionLabel="name" :placeholder="$t('common.selectCodec')" class="w-full" :disabled="!!pxfile?.uuid && hasCodecFromFile" />
            </div>
          </div>

          <div class="field grid">
            <label for="isEncrypted" class="col-12 md:col-4">{{ $t('common.encrypted') }}</label>
            <div class="col md:col-8">
              <InputSwitch v-model="isEncrypted " id="isEncrypted" />
            </div>
          </div>

          <div class="field grid">
            <label for="systemsCompliance" class="col-12 md:col-4">{{ $t('common.systemsCompliance') }}</label>
            <div class="col md:col-8">
              <SystemComplianceEditor :editorKey="editorKey" />
            </div>
          </div>

          <div class="field grid">
            <label for="embeddedFilename" class="col-12 md:col-4">{{ $t('common.embeddedFilename') }}</label>
            <div class="col md:col-8">
              <span class="p-fluid">
                <InputText id="embeddedFilename" type="text" v-model="embeddedFilename" />
              </span>
            </div>
          </div>

        </div>
        <div class="col-12 md:col-5 md:col-offset-1">

          <div class="field grid">
            <label for="videoBitrate" class="col-12 md:col-4">{{ $t('common.videoBitrate') }}</label>
            <div class="col md:col-8">
              <Dropdown v-model="videoBitrate" :options="rscVideoBitrates" optionValue="id" optionLabel="name" :placeholder="$t('common.selectVideoBitrate')" class="w-full" :disabled="!!pxfile?.uuid" />
            </div>
          </div>

          <div class="field grid">
            <label for="isEncrypted" class="col-12 md:col-4">Lab</label>
            <div class="col md:col-8">
              <AutoComplete
                v-model="lab"
                dropdown
                field="name"
                itemValue="name"
                :suggestions="rscLabs"
                @complete="searchLabs($event)">
              </AutoComplete>
            </div>
          </div>

          <div class="field grid">
            <label for="resolution" class="col-12 md:col-4">{{ $t('common.resolution') }}</label>
            <div class="col md:col-8">
              <Dropdown v-model="resolution" :options="rscResolutions" optionValue="id" optionLabel="name" :placeholder="$t('common.selectResolution')" class="w-full" />
            </div>
          </div>

          <div class="field grid">
            <label for="ratio" class="col-12 md:col-4">Ratio</label>
            <div class="col md:col-8">
              <Dropdown v-model="ratio" :options="rscRatios" optionValue="id" optionLabel="name" :placeholder="$t('common.selectRatio')" class="w-full" />
            </div>
          </div>

          <div class="field grid">
            <label for="boxType" class="col-12 md:col-4">{{ $t('common.boxType') }}</label>
            <div class="col md:col-8">
              <Dropdown v-model="boxType" :options="rscBoxTypes" optionValue="id" optionLabel="name" :placeholder="$t('common.selectBoxType')" class="w-full" />
            </div>
          </div>

          <div class="field grid">
            <label for="videoTrickMode" class="col-12 md:col-4">{{ $t('common.videoTrickModes') }}</label>
            <div class="col md:col-8">
              <Dropdown v-model="videoTrickMode" :options="rscVideoTrickModes" optionValue="id" optionLabel="name" :placeholder="$t('common.selectVideoTrickMode')" class="w-full" />
            </div>
          </div>

        </div>
      </div>

      <div>
        <DataTable :value="audioTracks" @row-reorder="onRowReorder($event, 'audioTracks')" responsiveLayout="scroll" class="p-datatable-sm">
          <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
          <Column headerStyle="width: 10rem">
            <template #body="{index}">
              {{ $t('common.audioTrack') }} {{ index + 1 }}
            </template>
          </Column>
          <Column>
            <template #body="{data}">
              <LanguageSelector
                :modelValue="data.language"
                @update:modelValue="updateLanguage(data.language, $event, audioTracksLanguageListCounter, 'audioTracks')"
                v-model="data.language"
                @change="checkHasUpdate()"
                compact
                :getOnlyID="false"
                :sort="languageSort"
                class="w-full"
                :disabled="!!pxfile?.uuid && data.isFromPXFile"/>
              <span v-if="audioTracksErrorsList.includes(data.uuid)" class="p-error">{{audioTracksError}}</span>
              <span v-if="audioTracksLanguageListCounter[data.language?.id] > 1" class="p-error">{{$t('common.pleaseSelectAnotherLanguage')}}</span>
            </template>
          </Column>
          <Column>
            <template #body="{index}">
              <div class="flex justify-content-end w-full">
                <BtnConfirm @confirm="removeAudioTrack(index)" :btnClass="{'p-button-text': true}" />
              </div>
            </template>
          </Column>
        </DataTable>
        <br/>
        <div class="flex justify-content-center">
          <Button label="Add audio track" class="p-button-outlined" icon="pi pi-plus-circle" @click="addAudioTrack(null, false)"/>
        </div>
      </div>

      <br/>

      <div>
        <DataTable :value="burnedSubs" @row-reorder="onRowReorder($event, 'burnedSubs')" responsiveLayout="scroll" class="p-datatable-sm">
          <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
          <Column headerStyle="width: 10rem">
            <template #body="{index}">
              {{ $t('common.burnedInSub') }} {{ index + 1 }}
            </template>
          </Column>
          <Column>
            <template #body="{data}">
              <LanguageSelector :modelValue="data.language" @update:modelValue="updateLanguage(data.language, $event, burnedSubsLanguageListCounter, 'burnedSubs')" v-model="data.language" @change="checkHasUpdate()" compact :getOnlyID="false" :sort="languageSort" class="w-full"/>
              <span v-if="burnedSubsErrorsList.includes(data.uuid)" class="p-error">{{burnedSubsError}}</span>
              <span v-if="burnedSubsLanguageListCounter[data.language?.id] > 1" class="p-error">{{$t('common.pleaseSelectAnotherLanguage')}}</span>
            </template>
          </Column>
          <Column>
            <template #body="{index}">
              <div class="flex justify-content-end w-full">
                <BtnConfirm @confirm="removeBurnedSub(index)" :btnClass="{'p-button-text': true}" />
              </div>
            </template>
          </Column>
        </DataTable>
        <br/>
        <div v-if="burnedSubsLength < 2" class="flex justify-content-center">
          <Button label="Add burned in sub" class="p-button-outlined" icon="pi pi-plus-circle" @click="addBurnedSub"/>
        </div>
      </div>

      <br/>

      <div>
        <DataTable :value="dynamicSubs" @row-reorder="onRowReorder($event, 'dynamicSubs')" responsiveLayout="scroll" class="p-datatable-sm">
          <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
          <Column headerStyle="width: 10rem">
            <template #body="{index}">
              {{ $t('common.dynamicsubs') }} {{ index + 1 }}
            </template>
          </Column>
          <Column>
            <template #body="{data}">
              <LanguageSelector
                :modelValue="data.language"
                @update:modelValue="updateDynamicSubsLanguage(data, $event)"
                v-model="data.language"
                @change="checkHasUpdate()"
                compact
                :getOnlyID="false"
                :sort="languageSort"
                class="w-full"
                :disabled="!!pxfile?.uuid && data.isFromPXFile"/>
              <span v-if="dynamicSubsErrorsList.includes(data.uuid)" class="p-error">{{dynamicSubsError}}</span>
              <span v-if="dynamicSubsLanguageListCounter[`${data.language?.id}${data.CC}`] > 1" class="p-error">{{$t('common.pleaseSelectAnotherLanguage')}}</span>
            </template>
          </Column>
          <Column>
            <template #body="{data}">
              <div class="grid">
                <label for="isEncrypted" class="col-12 md:col-4">CC</label>
                <div class="col md:col-8">
                  <InputSwitch :modelValue="data.CC" @update:modelValue="updateDynamicSubsCCLanguage(data, $event)" v-model="data.CC" @change="checkHasUpdate()" />
                </div>
              </div>
            </template>
          </Column>
          <Column>
            <template #body="{index}">
              <div class="flex justify-content-end w-full">
                <BtnConfirm @confirm="removeDynamicSub(index)" :btnClass="{'p-button-text': true}" />
              </div>
            </template>
          </Column>
        </DataTable>
        <br/>
        <div class="flex justify-content-center">
          <Button label="Add dynamic sub" class="p-button-outlined" icon="pi pi-plus-circle" @click="addDynamicSub(null)"/>
        </div>
      </div>
    </div>

    <!-- start is audio type  -->
    <div v-if="isAudioType">
      <div class="grid">
        <div class="col-12 md:col-6">

          <div class="field grid">
            <label for="isEncrypted" class="col-12 md:col-4">{{ $t('common.codec') }}</label>
            <div class="col md:col-8">
              <Dropdown v-model="codec" :options="rscCodecsAudio" optionValue="id" optionLabel="name" :placeholder="$t('common.selectCodec')" class="w-full" :disabled="!!pxfile?.uuid && hasCodecFromFile" />
            </div>
          </div>

          <div class="field grid">
            <label for="encoding" class="col-12 md:col-4">{{ $t('common.encoding') }}</label>
            <div class="col md:col-8">
              <Dropdown v-model="encoding" :options="rscEncodings" optionValue="id" optionLabel="name" :placeholder="$t('common.selectEncoding')" class="w-full" />
            </div>
          </div>

          <div class="field grid">
            <label for="systemsCompliance" class="col-12 md:col-4">{{ $t('common.systemsCompliance') }}</label>
            <div class="col md:col-8">
              <SystemComplianceEditor :editorKey="editorKey" />
            </div>
          </div>

          <div class="field grid">
            <label for="embeddedFilename" class="col-12 md:col-4">{{ $t('common.embeddedFilename') }}</label>
            <div class="col md:col-8">
              <span class="p-fluid">
                <InputText id="embeddedFilename" type="text" v-model="embeddedFilename" />
              </span>
            </div>
          </div>

        </div>
        <div class="col-12 md:col-5 md:col-offset-1">

          <div class="field grid">
            <label for="bitrate" class="col-12 md:col-4">{{ $t('common.embeddedFilename') }}</label>
            <div class="col md:col-8">
              <Dropdown v-model="bitrate" :options="rscBitrates" optionValue="id" optionLabel="name" placeholder="Select bitrate" class="w-full" :disabled="!!pxfile?.uuid" />
            </div>
          </div>

          <div class="field grid">
            <label for="lab" class="col-12 md:col-4">Lab</label>
            <div class="col md:col-8">
              <AutoComplete
                v-model="lab"
                dropdown
                field="name"
                itemValue="name"
                :suggestions="rscLabs"
                @complete="searchLabs($event)">
              </AutoComplete>
            </div>
          </div>

        </div>
      </div>

      <hr/>

      <div>
        <div class="grid">
          <div class="col-12 md:col-6">
            <div class="field grid">
              <label for="language" class="col-12 md:col-4">{{ $t('common.audioTrack') }}</label>
              <LanguageSelector
                class="col md:col-8"
                v-model="language"
                :add-option="false"
                :getOnlyID="false"
                :placeholder="$t('common.availableForAllLanguages')"
                :sort="languageSort"
                showClear/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end is audio type  -->

    <!-- start is prams bgm type  -->
    <div v-if="isPramsBGMType">
      <div class="grid">
        <div class="col-12 md:col-6">

          <div class="field grid">
            <label for="isEncrypted" class="col-12 md:col-4">{{ $t('common.codec') }}</label>
            <div class="col md:col-8">
              <Dropdown v-model="codec" :options="rscCodecsAudio" optionValue="id" optionLabel="name" :placeholder="$t('common.selectCodec')" class="w-full" :disabled="!!pxfile?.uuid && hasCodecFromFile" />
            </div>
          </div>

          <div class="field grid">
            <label for="embeddedFilename" class="col-12 md:col-4">{{ $t('common.embeddedFilename') }}</label>
            <div class="col md:col-8">
              <span class="p-fluid">
                <InputText id="embeddedFilename" type="text" v-model="embeddedFilename" />
              </span>
            </div>
          </div>

        </div>
        <div class="col-12 md:col-5 md:col-offset-1">

          <div class="field grid">
            <label for="bitrate" class="col-12 md:col-4">{{ $t('mediafiles.bitrate') }}</label>
            <div class="col md:col-8">
              <Dropdown v-model="bitrate" :options="rscBitrates" optionValue="id" optionLabel="name" :placeholder="$t('common.selectBitrate')" class="w-full" :disabled="!!pxfile?.uuid" />
            </div>
          </div>

          <div class="field grid">
            <label for="lab" class="col-12 md:col-4">Lab</label>
            <div class="col md:col-8">
              <AutoComplete
                v-model="lab"
                dropdown
                field="name"
                itemValue="name"
                :suggestions="rscLabs"
                @complete="searchLabs($event)">
              </AutoComplete>
            </div>
          </div>

        </div>
      </div>

      <hr/>

      <div>
        <div class="grid">
          <div class="col-12 md:col-6">
            <div class="field grid">
              <label for="language" class="col-12 md:col-4">{{ $t('common.audioTrack') }}</label>
              <LanguageSelector
                class="col md:col-8"
                v-model="language"
                :add-option="false"
                :getOnlyID="false"
                :placeholder="$t('common.availableForAllLanguages')"
                :sort="languageSort"
                showClear/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end is prams bgm type  -->

  <!-- START DOCUMENT PDF -->
    <div v-if="isPdfType">
      <div class="grid">
        <div class="col-12 md:col-8">
          <div class="grid">
            <div class="col-12 md:col-9">

              <div class="field grid">
                <label for="isEncrypted" class="col-8 md:col-4">Lab</label>
                <div class="col md:col-8">
                  <AutoComplete
                    v-model="lab"
                    dropdown
                    field="name"
                    itemValue="name"
                    :suggestions="rscLabs"
                    @complete="searchLabs($event)">
                  </AutoComplete>
                </div>
              </div>

            </div>
          </div>

          <div class="field grid">
            <label for="embeddedFilename" class="col-12 md:col-3">{{ $t('common.embeddedFilename') }}</label>
            <div class="col md:col-9">
              <span class="p-fluid">
                <InputText id="embeddedFilename" type="text" v-model="embeddedFilename" />
              </span>
            </div>
          </div>

          <div class="field grid">
            <label for="language" class="col-12 md:col-3">{{ $t('common.documentLanguage') }}</label>
            <LanguageSelector
              class="col md:col-9"
              v-model="language"
              :add-option="false"
              :getOnlyID="false"
              :placeholder="$t('common.availableForAllLanguages')"
              :sort="languageSort"
              showClear/>
          </div>

        </div>
      </div>

      <hr/>
    </div>
    <!-- END DOCUMENT PDF -->

    <!-- START IMAGE -->
    <div v-if="isImageType">
      <div class="grid">
        <div class="col-12 md:col-6">

          <div class="field grid">
            <label for="embeddedFilename" class="col-12 md:col-4">{{ $t('common.embeddedFilename') }}</label>
            <div class="col md:col-8">
              <span class="p-fluid">
                <InputText id="embeddedFilename" type="text" v-model="embeddedFilename" />
              </span>
            </div>
          </div>

          <div class="field grid">
            <label for="format" class="col-12 md:col-4">{{ $t('common.format') }}</label>
            <div class="col md:col-8">
              <Dropdown v-model="format" :options="rscFormats" optionValue="id" optionLabel="name" :placeholder="$t('common.selectFormat')" class="w-full" :disabled="!!pxfile?.uuid && hasCodecFromFile" />
              <span v-if="formatError" class="p-error"> {{ formatError }} </span>
            </div>
          </div>
          <div class="field grid">
            <label for="colorType" class="col-12 md:col-4">{{ $t('common.colorType') }}</label>
            <div class="col md:col-8">
              <Dropdown v-model="colorType" :options="rscColorTypes" optionValue="id" optionLabel="name" :placeholder="$t('common.selectColor')" class="w-full" />
              <span v-if="colorTypeError" class="p-error"> {{ colorTypeError }} </span>
            </div>
          </div>

          <div class="field grid">
            <label for="language" class="col-12 md:col-4">{{ $t('common.pictureLanguage') }}</label>
            <LanguageSelector
              class="col md:col-8"
              v-model="language"
              :add-option="false"
              :getOnlyID="false"
              :placeholder="$t('common.availableForAllLanguages')"
              :sort="languageSort"
              showClear/>
          </div>

        </div>
      </div>

      <hr/>
    </div>
    <!-- END IMAGE -->



    <!-- START WEBAPP-GAME-MICROSITE -->
    <div v-if="isGameMicrositeType">
      <div class="grid">
        <div class="col-12 md:col-8">
          <div class="grid">
            <div class="col-12 md:col-9">

              <div class="field grid">
                <label for="isEncrypted" class="col-8 md:col-4">Lab</label>
                <div class="col md:col-8">
                  <AutoComplete
                    v-model="lab"
                    dropdown
                    field="name"
                    itemValue="name"
                    :suggestions="rscLabs"
                    @complete="searchLabs($event)">
                  </AutoComplete>
                </div>
              </div>

            </div>
          </div>

          <div class="field grid">
            <label for="embeddedFilename" class="col-12 md:col-3">{{ $t('common.embeddedFilename') }}</label>
            <div class="col md:col-9">
              <span class="p-fluid">
                <InputText id="embeddedFilename" type="text" v-model="embeddedFilename" />
              </span>
            </div>
          </div>

          <div class="field grid">
            <label for="systemsCompliance" class="col-12 md:col-3">{{ $t('common.systemsCompliance') }}</label>
            <div class="col md:col-9">
              <SystemComplianceEditor :editorKey="editorKey" />
            </div>
          </div>

        </div>
      </div>

      <hr/>
    </div>
    <!-- END WEBAPP-GAME-MICROSITE -->

    <!-- START SAFETY-VPA-ADVERT -->
    <div v-if="isSafetyVPAAdvertType">
      <div class="grid">
        <div class="col-12 md:col-6">

          <div class="field grid">
            <label for="codec" class="col-12 md:col-4">{{ $t('common.codec') }}</label>
            <div class="col md:col-8">
              <Dropdown v-model="codec" :options="rscCodecsVideo" optionValue="id" optionLabel="name" :placeholder="$t('common.selectCodec')" class="w-full" :disabled="!!pxfile?.uuid && hasCodecFromFile" />
            </div>
          </div>

          <div class="field grid">
            <label for="embeddedFilename" class="col-12 md:col-4">{{ $t('common.embeddedFilename') }}</label>
            <div class="col md:col-8">
              <span class="p-fluid">
                <InputText id="embeddedFilename" type="text" v-model="embeddedFilename" />
              </span>
            </div>
          </div>

        </div>
        <div class="col-12 md:col-5 md:col-offset-1">

          <div class="field grid">
            <label for="videoBitrate" class="col-12 md:col-4">{{ $t('common.videoBitrate') }}</label>
            <div class="col md:col-8">
              <Dropdown v-model="videoBitrate" :options="rscVideoBitrates" optionValue="id" optionLabel="name" :placeholder="$t('common.selectVideoBitrate')" class="w-full" :disabled="!!pxfile?.uuid" />
            </div>
          </div>

          <div class="field grid">
            <label for="isEncrypted" class="col-12 md:col-4">Lab</label>
            <div class="col md:col-8">
              <AutoComplete
                v-model="lab"
                dropdown
                field="name"
                itemValue="name"
                :suggestions="rscLabs"
                @complete="searchLabs($event)">
              </AutoComplete>
            </div>
          </div>

          <div class="field grid">
            <label for="ratio" class="col-12 md:col-4">Ratio</label>
            <div class="col md:col-8">
              <Dropdown v-model="ratio" :options="rscRatios" optionValue="id" optionLabel="name" :placeholder="$t('common.selectRatio')" class="w-full" />
            </div>
          </div>

        </div>
      </div>

      <div>
        <DataTable :value="audioTracks" @row-reorder="onRowReorder($event, 'audioTracks')" responsiveLayout="scroll" class="p-datatable-sm">
          <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
          <Column headerStyle="width: 10rem">
            <template #body="{index}">
              {{ $t('common.audioTrack') }} {{ index + 1 }}
            </template>
          </Column>
          <Column>
            <template #body="{data}">
              <LanguageSelector
                :modelValue="data.language"
                @update:modelValue="updateLanguage(data.language, $event, audioTracksLanguageListCounter, 'audioTracks')"
                v-model="data.language"
                @change="checkHasUpdate()"
                compact
                :getOnlyID="false"
                :sort="languageSort"
                class="w-full"
                :disabled="!!pxfile?.uuid && data.isFromPXFile"/>
              <span v-if="audioTracksErrorsList.includes(data.uuid)" class="p-error">{{audioTracksError}}</span>
              <span v-if="audioTracksLanguageListCounter[data.language?.id] > 1" class="p-error">{{$t('common.pleaseSelectAnotherLanguage')}}</span>
            </template>
          </Column>
          <Column>
            <template #body="{index}">
              <div class="flex justify-content-end w-full">
                <BtnConfirm @confirm="removeAudioTrack(index)" :btnClass="{'p-button-text': true}" />
              </div>
            </template>
          </Column>
        </DataTable>
        <br/>
        <div class="flex justify-content-center">
          <Button label="Add audio track" class="p-button-outlined" icon="pi pi-plus-circle" @click="addAudioTrack(null, false)"/>
        </div>
      </div>

      <br/>

      <div>
        <DataTable :value="burnedSubs" @row-reorder="onRowReorder($event, 'burnedSubs')" responsiveLayout="scroll" class="p-datatable-sm">
          <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
          <Column headerStyle="width: 10rem">
            <template #body="{index}">
              {{ $t('common.burnedInSub') }} {{ index + 1}}
            </template>
          </Column>
          <Column>
            <template #body="{data}">
              <LanguageSelector :modelValue="data.language" @update:modelValue="updateLanguage(data.language, $event, burnedSubsLanguageListCounter, 'burnedSubs')" v-model="data.language" @change="checkHasUpdate()" compact :getOnlyID="false" :sort="languageSort" class="w-full"/>
              <span v-if="burnedSubsErrorsList.includes(data.uuid)" class="p-error">{{burnedSubsError}}</span>
              <span v-if="burnedSubsLanguageListCounter[data.language?.id] > 1" class="p-error">{{$t('common.pleaseSelectAnotherLanguage')}}</span>
            </template>
          </Column>
          <Column>
            <template #body="{index}">
              <div class="flex justify-content-end w-full">
                <BtnConfirm @confirm="removeBurnedSub(index)" :btnClass="{'p-button-text': true}" />
              </div>
            </template>
          </Column>
        </DataTable>
        <br/>
        <div v-if="burnedSubsLength < 2" class="flex justify-content-center">
          <Button label="Add burned in sub" class="p-button-outlined" icon="pi pi-plus-circle" @click="addBurnedSub"/>
        </div>
      </div>

      <br/>

      <div>
        <DataTable :value="dynamicSubs" @row-reorder="onRowReorder($event, 'dynamicSubs')" responsiveLayout="scroll" class="p-datatable-sm">
          <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
          <Column headerStyle="width: 10rem">
            <template #body="{index}">
              {{ $t('common.dynamicsubs') }} {{ index + 1 }}
            </template>
          </Column>
          <Column>
            <template #body="{data}">
              <LanguageSelector
                :modelValue="data.language"
                @update:modelValue="updateDynamicSubsLanguage(data, $event)"
                v-model="data.language"
                @change="checkHasUpdate()"
                compact
                :getOnlyID="false"
                :sort="languageSort"
                class="w-full"
                :disabled="!!pxfile?.uuid && data.isFromPXFile"/>
              <span v-if="dynamicSubsErrorsList.includes(data.uuid)" class="p-error">{{dynamicSubsError}}</span>
              <span v-if="dynamicSubsLanguageListCounter[`${data.language?.id}${data.CC}`] > 1" class="p-error">{{$t('common.pleaseSelectAnotherLanguage')}}</span>
            </template>
          </Column>
          <Column>
            <template #body="{data}">
              <div class="grid">
                <label for="isEncrypted" class="col-12 md:col-4">CC</label>
                <div class="col md:col-8">
                  <InputSwitch :modelValue="data.CC" @update:modelValue="updateDynamicSubsCCLanguage(data, $event)" v-model="data.CC" @change="checkHasUpdate()" />
                </div>
              </div>
            </template>
          </Column>
          <Column>
            <template #body="{index}">
              <div class="flex justify-content-end w-full">
                <BtnConfirm @confirm="removeDynamicSub(index)" :btnClass="{'p-button-text': true}" />
              </div>
            </template>
          </Column>
        </DataTable>
        <br/>
        <div class="flex justify-content-center">
          <Button label="Add dynamic sub" class="p-button-outlined" icon="pi pi-plus-circle" @click="addDynamicSub(null)"/>
        </div>
      </div>
    </div>
    <!-- END SAFETY-VPA-ADVERT -->

    <Dialog v-model:visible="isUploadConfirmationVisible" modal :header="$t('common.areYouSure')">
      <p>
        {{ $t('mediafiles.uploadingANewFileReset') }} ({{ popinText }}). {{ $t('actions.areYouSure') }}
      </p>
      <template #footer>
        <Button class="p-button-danger" :label="$t('actions.no')" icon="pi pi-times" @click="isUploadConfirmationVisible = false" text />
        <Button class="p-button-success" :label="$t('actions.yes')" icon="pi pi-check" @click="confirmUpload()" autofocus />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { ref, computed, inject, watch } from 'vue'
import { mapGetters } from 'vuex'
import { useToast } from 'primevue/usetoast'
import { useI18n } from 'vue-i18n'
import { uuid } from 'vue-uuid'

import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

import Toolbar from 'primevue/toolbar'
import InputText from 'primevue/inputtext'
import InputSwitch from 'primevue/inputswitch'
import Dropdown from 'primevue/dropdown'
import AutoComplete from 'primevue/autocomplete'
import MultiSelect from 'primevue/multiselect'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import FileUpload from 'primevue/fileupload'
import Dialog from 'primevue/dialog'

import { Icon } from '@iconify/vue/dist/offline'

import BtnConfirm from '@/components/BtnConfirm.vue'
import LanguageSelector from '@/components/resource/LanguageSelector.vue'

import MediaFileTypeUnknown from './mediafiles/MediaFileTypeUnknown.vue'
import MediaFileTypeMovie from './mediafiles/MediaFileTypeMovie.vue'

import SystemComplianceEditor from './mediafiles/fieldeditors/SystemComplianceEditor.vue'

import { useFlowPMMediafileBuilder } from '@/compositions'

const KFlowMediafile = 'portalmanager:mediafile'

export default {
  name: 'EditingMediafilesEditor',
  components: {
    Toolbar, InputText, InputSwitch, Dropdown, AutoComplete, MultiSelect, DataTable, Column, FileUpload, Dialog,
    Icon,
    LanguageSelector,
    BtnConfirm,
    MediaFileTypeUnknown,
    MediaFileTypeMovie,
    SystemComplianceEditor
  },
  props: {
    modelValue: Object,
    mediafileInitFrom: String,
    isInitiating: Boolean,
    initList: {
      type: Array,
      default: () => [
        { field: 'content.codec' },
        { field: 'content.bitrate' },
        { field: 'content.videoBitrate' },
        { field: 'content.encoding' },
        { field: 'content.encrypted' },
        { field: 'content.resolution' },
        { field: 'content.ratio' },
        { field: 'content.boxType' },
        { field: 'content.videoTrickMode' },
        { field: 'lab' },
        { field : 'content.format'},
        { field : 'content.colorType'}
      ]
    },
    pxfileInit: {
      type: Object,
      default: () => null
    }
  },
  setup (props) {
    // vars section
    const pxstream = inject('pxstream')
    const toast = useToast()
    const { t } = useI18n()

    const fileUpload = ref(null)

    const rscLabs = ref([])

    const uploadAuthorizedExtensions = ref('')
    const isUploadConfirmationVisible = ref(false)
    const isUploading = ref(false)

    const audioTracksErrorsList = ref([])
    const burnedSubsErrorsList = ref([])
    const dynamicSubsErrorsList = ref([])
    const duplicateAudioTracksLanguageError = ref(false)
    const duplicateBurnedSubsLanguageError = ref(false)
    const duplicateDynamicSubsLanguageError = ref(false)
    const audioTracksLanguageListCounter = ref({})
    const burnedSubsLanguageListCounter = ref({})
    const dynamicSubsLanguageListCounter = ref({})

    const mediafileEditor = useFlowPMMediafileBuilder({
      propDoc: props.modelValue,
      onFieldChange: () => {},
      onUpdateChange: () => {},
      initFields: props.mediafileInitFrom && props.isInitiating ? {
        from: props.mediafileInitFrom,
        list: props.initList
      } : null
    })

    // computed section
    const name = computed({
      get () { return mediafileEditor.fieldGet('filename') },
      set (val) { mediafileEditor.fieldSet({field: 'filename', value: val}) }
    })

    const contentType = computed({
      get () { return mediafileEditor.fieldGet('contentType') }
    })

    const codec = computed({
      get () { return mediafileEditor.fieldGet('content.codec') },
      set (val) { mediafileEditor.fieldSet({field: 'content.codec', value: val}) }
    })

    const format = computed({
      get () { return mediafileEditor.fieldGet('content.format')},
      set (val) { mediafileEditor.fieldSet({field: 'content.format', value: val}) }
    })

    const colorType = computed({
      get () { return mediafileEditor.fieldGet('content.colorType')},
      set (val) { mediafileEditor.fieldSet({field: 'content.colorType', value: val}) }
    })

    const hasCodecFromFile = computed({
      get () { return mediafileEditor.fieldGet('content.hasCodecFromFile') },
      set (val) { mediafileEditor.fieldSet({field: 'content.hasCodecFromFile', value: val}) }
    })

    const encoding = computed({
      get () { return mediafileEditor.fieldGet('content.encoding') },
      set (val) { mediafileEditor.fieldSet({field: 'content.encoding', value: val}) }
    })

    const bitrate = computed({
      get () { return mediafileEditor.fieldGet('content.bitrate') },
      set (val) { mediafileEditor.fieldSet({field: 'content.bitrate', value: val}) }
    })

    const videoBitrate = computed({
      get () { return mediafileEditor.fieldGet('content.videoBitrate') },
      set (val) { mediafileEditor.fieldSet({field: 'content.videoBitrate', value: val}) }
    })

    const resolution = computed({
      get () { return mediafileEditor.fieldGet('content.resolution') },
      set (val) { mediafileEditor.fieldSet({field: 'content.resolution', value: val}) }
    })

    const ratio = computed({
      get () { return mediafileEditor.fieldGet('content.ratio') },
      set (val) { mediafileEditor.fieldSet({field: 'content.ratio', value: val}) }
    })

    const pxfile = computed({
      get () { return mediafileEditor.fieldGet('pxfile') },
      set (val) { mediafileEditor.fieldSet({field: 'pxfile', value: val}) }
    })

    const language = computed({
      get () {
        const field = mediafileEditor.fieldGet('content.language')
        return field && field.id && field.id !== "0" ? field : null
      },
      set (val) {
        mediafileEditor.fieldSet({field: 'content.language', value: val})
      }
    })

    const audioTracks = computed({
      get () { return mediafileEditor.fieldGet('content.audioTracks') },
      set (val) { mediafileEditor.fieldSet({field: 'content.audioTracks', value: val}) }
    })

    const burnedSubs = computed({
      get () { return mediafileEditor.fieldGet('content.burnedSubs') },
      set (val) { mediafileEditor.fieldSet({field: 'content.burnedSubs', value: val}) }
    })

    const dynamicSubs = computed({
      get () { return mediafileEditor.fieldGet('content.dynamicSubs') },
      set (val) { mediafileEditor.fieldSet({field: 'content.dynamicSubs', value: val}) }
    })

    const uploadFieldName = computed(() => pxstream.storage.getExtensionsFieldName(contentType.value?.name))

    const isAudioType = computed(() => mediafileEditor.fieldGet('mediafileType') === 'audio')

    const isMovieType = computed(() => mediafileEditor.fieldGet('mediafileType') === 'movie')

    const isTVType = computed(() => mediafileEditor.fieldGet('mediafileType') === 'tv')

    const isPramsBGMType = computed(() => mediafileEditor.fieldGet('mediafileType') === 'prams-bgm')

    const isPdfType = computed(() => mediafileEditor.fieldGet('mediafileType') === 'document-pdf')

    const isSafetyVPAAdvertType = computed(() => mediafileEditor.fieldGet('mediafileType') === 'safety-vpa-advert')

    const isGameMicrositeType = computed(() => mediafileEditor.fieldGet('mediafileType') === 'webapp-microsite-game')

    const isImageType = computed(() => mediafileEditor.fieldGet('mediafileType') === 'image')

    const popinText = computed(() => {
      let result = ''
      const codecText = t('common.codec')
      const languageText = t('common.language')
      const bitrateText = t('mediafiles.bitrate')
      const subtitlesText = t('common.subtitles')
      if (isGameMicrositeType.value) {
        return result
      }
      if (isImageType.value || isPdfType.value) {
        result = `${language.value ? languageText : ''}`
      }
      if (isAudioType.value || isPramsBGMType.value) {
        result = `${codec.value ? codecText : ''}${dynamicSubs.value || burnedSubs.value || language.value || bitrate.value ? ',' : ''} ${bitrate.value ? bitrateText : ''} ${dynamicSubs.value || burnedSubs.value || language.value ? ',' : ''} ${language.value ? languageText : ''}`
      }
      if (isMovieType.value || isTVType.value || isSafetyVPAAdvertType.value) {
        result = `${codec.value ? codecText : ''}${dynamicSubs.value || burnedSubs.value || language.value || bitrate.value ? ',' : ''} ${bitrate.value ? bitrateText : ''} ${dynamicSubs.value || burnedSubs.value || language.value ? ',' : ''} ${language.value ? languageText : ''}${dynamicSubs.value || burnedSubs.value ? ',' : ''} ${dynamicSubs.value || burnedSubs.value ? subtitlesText : ''} `
      }
      return result
    })

    // function section
    const hasDuplicateLanguage = (list) => {
      return Object.values(list.value).find(lang => lang > 1)
    }

    const addAudioTrack = (language, isFromPXFile) => {
      mediafileEditor.fieldPush({field: 'content.audioTracks', value: { language, isFromPXFile, uuid: uuid.v1() }})
    }

    const removeAudioTrack = (index) => {
      duplicateAudioTracksLanguageError.value = false
      // language list update
      audioTracksLanguageListCounter.value[audioTracks.value[index]?.language?.id]--
      // set duplicate language error
      if (hasDuplicateLanguage(audioTracksLanguageListCounter)) {
        duplicateAudioTracksLanguageError.value = true
      }
      mediafileEditor.fieldSplice({ field: 'content.audioTracks', filter: (_, idx) => idx != index})
    }

    const addBurnedSub = () => {
      mediafileEditor.fieldPush({field: 'content.burnedSubs', value: { language: null, uuid: uuid.v1() }})
    }

    const removeBurnedSub = (index) => {
      duplicateBurnedSubsLanguageError.value = false
      // language list update
      burnedSubsLanguageListCounter.value[burnedSubs.value[index]?.language?.id]--
      // set duplicate language error
      if (hasDuplicateLanguage(burnedSubsLanguageListCounter)) {
        duplicateBurnedSubsLanguageError.value = true
      }
      mediafileEditor.fieldSplice({ field: 'content.burnedSubs', filter: (_, idx) => idx != index})
    }

    const addDynamicSub = (language, isFromPXFile) => {
      mediafileEditor.fieldPush({field: 'content.dynamicSubs', value: { language, CC: false, isFromPXFile, uuid: uuid.v1() }})
    }

    const removeDynamicSub = (index) => {
      duplicateDynamicSubsLanguageError.value = false
      // language list update
      const key = `${dynamicSubs.value[index].language?.id}${dynamicSubs.value[index].CC}`
      if (dynamicSubs.value[index]) {
        dynamicSubsLanguageListCounter.value[key]--
      }
      // set duplicate language error
      if (hasDuplicateLanguage(dynamicSubsLanguageListCounter)) {
        duplicateDynamicSubsLanguageError.value = true
      }
      mediafileEditor.fieldSplice({ field: 'content.dynamicSubs', filter: (_, idx) => idx != index})
    }

    const loadPXFileInfos = async () => {
      if (pxfile.value?.uuid) {
        const infos = await pxstream.storage.getInfos(pxfile.value.uuid)

        if (infos?.filename && !name.value) {
          name.value = infos.filename
        }

        if (infos?.metadata.codec) {
          if (isImageType.value) {
            format.value = infos.metadata.codec
          } else {
            codec.value = infos.metadata.codec
          }
          hasCodecFromFile.value = true
        } else {
          hasCodecFromFile.value = false
        }

        if (infos?.metadata.channelLayout) {
          encoding.value = infos.metadata.channelLayout
        } else {
          encoding.value = ''
        }

        if (infos?.metadata.dbBitRate) {
          bitrate.value = infos.metadata.dbBitRate
        } else {
          bitrate.value = ''
        }

        if (infos?.metadata.dbRatio) {
          ratio.value = infos.metadata.dbRatio
        } else {
          ratio.value = ''
        }

        if (infos?.metadata.dbResolution) {
          resolution.value = infos.metadata.dbResolution
        } else {
          resolution.value = ''
        }

        if (infos?.metadata.dbVideoBitRate) {
          videoBitrate.value = infos.metadata.dbVideoBitRate
        } else {
          videoBitrate.value = ''
        }

        if (infos?.metadata.format) {
          format.value = infos.metadata.format
        }

        if (infos?.metadata.colorType) {
          colorType.value = infos.metadata.colorType
        }

        language.value = null
        if (infos?.metadata.language) {
          language.value = infos.metadata.language
        }

        audioTracks.value = []
        if (infos?.metadata.audioTracks) {
          infos.metadata.audioTracks.forEach(lang => {
            addAudioTrack(lang, true)
          })
        }

        dynamicSubs.value = []
        if (infos?.metadata.dynamicSubs) {
          infos.metadata.dynamicSubs.forEach(lang => {
            addDynamicSub(lang, true)
          })
        }
      }
    }

    const clickUpload = () => {
      if (
        format.value || codec.value || encoding.value || bitrate.value ||
        ratio.value || resolution.value || videoBitrate.value || colorType.value ||
        language.value || audioTracks.value || dynamicSubs.value
      ) {
        isUploadConfirmationVisible.value = true
        return
      }
      confirmUpload()
    }

    const confirmUpload = () => {
      isUploadConfirmationVisible.value = false
      fileUpload.value.choose()
    }

    const customLanguageValidation = (errorsList) => (languageListCounter) => {
      if (!languageListCounter || languageListCounter.length === 0) {
        return true
      }
      let result = true
      errorsList.value = []
      languageListCounter.forEach((language) => {
        if (!language.language) {
          errorsList.value.push(language.uuid)
          result = false
        }
      })
      if (duplicateAudioTracksLanguageError.value || duplicateBurnedSubsLanguageError.value || duplicateDynamicSubsLanguageError.value) {
        return false
      }
      return result
    }

    const upload = async (data) => {
      if (data?.files?.length > 0) {
        isUploading.value = true
        const formData = new FormData()
        formData.append('file', data.files[0])
        formData.append('collection', KFlowMediafile)
        formData.append('fieldName', uploadFieldName.value)

        try {
          const uploadRes = await pxstream.storage.upload(formData)

          pxfile.value = uploadRes
        } catch (err) {
          toast.add({severity: 'error', summary: 'Failed to get mediafile with upload', detail: pxstream.http.getHttpErrorShortMessage(err), life: 4000})
        }
        isUploading.value = false
      }
      // This is very important. If not after upload ref to fileUpload is null...
      fileUpload.value.clear()
    }

    const updateLanguage = (item, event, list, error) => {
      error === 'audioTracks' ? duplicateAudioTracksLanguageError.value = false : duplicateBurnedSubsLanguageError.value = false
      // create if there is no old and new lang
      if (!list[item?.id] && !list[event.id]) {
        list[event.id] = 0
      }
      // if old lang exists
      if (list[item?.id]) {
          list[item?.id]--
      }
      // if new lang doesnt exists
      if (!list[event.id]) {
        list[event.id] = 1
      }
      // if new lang exists
      else {
        list[event.id]++
      }
      // set duplicate error for custom validation
      if (list[event.id] > 1) {
        error === 'audioTracks' ? duplicateAudioTracksLanguageError.value = true : duplicateBurnedSubsLanguageError.value = true
      }
    }

    const updateDynamicSubsLanguage = (item, event) => {
      duplicateDynamicSubsLanguageError.value = false
      let newKey = `${event.id}${item?.CC}`
      let oldKey = `${item?.language?.id}${item?.CC}`

      // create if there is no new lang
      if (!dynamicSubsLanguageListCounter.value[newKey]) {
        dynamicSubsLanguageListCounter.value[newKey] = 0
      }
      // if old lang exists
      if (dynamicSubsLanguageListCounter.value[oldKey]) {
          dynamicSubsLanguageListCounter.value[oldKey]--
      }
      // if new lang doesnt exists
      if (!dynamicSubsLanguageListCounter.value[newKey]) {
        dynamicSubsLanguageListCounter.value[newKey] = 1
      }
      // if new lang exists
      else {
        dynamicSubsLanguageListCounter.value[newKey]++
      }
      // set duplicate error for custom validation
      if (dynamicSubsLanguageListCounter.value[newKey] > 1 || dynamicSubsLanguageListCounter.value[oldKey] > 1) {
        duplicateDynamicSubsLanguageError.value = true
      }
    }

    const updateDynamicSubsCCLanguage = (item, event) => {
      duplicateDynamicSubsLanguageError.value = false
      const oldKey = `${item.language?.id}${!event}`
      const newKey = `${item.language?.id}${event}`
      if (!dynamicSubsLanguageListCounter.value[oldKey]) {
        dynamicSubsLanguageListCounter.value[oldKey] = 0
      }
      if (!dynamicSubsLanguageListCounter.value[newKey]) {
        dynamicSubsLanguageListCounter.value[newKey] = 0
      }
        dynamicSubsLanguageListCounter.value[oldKey]--
        dynamicSubsLanguageListCounter.value[newKey]++
      // set duplicate error for custom validation
      if (dynamicSubsLanguageListCounter.value[newKey] > 1 || dynamicSubsLanguageListCounter.value[oldKey] > 1 ) {
        duplicateDynamicSubsLanguageError.value = true
      }
    }

    // watch section
    watch(() => pxfile.value, () => {
      loadPXFileInfos()
    })

    watch(() => uploadFieldName.value, async () => {
      const extensions = await pxstream.storage.getExtensions(KFlowMediafile, uploadFieldName.value)
      uploadAuthorizedExtensions.value = extensions.map(val => '.'+val).join()
    })

    if (uploadFieldName.value !== "") {
      pxstream.storage.getExtensions(KFlowMediafile, uploadFieldName.value).then(extensions => {
        uploadAuthorizedExtensions.value = extensions.map(val => '.'+val).join()
      })
    }

    if (props.pxfileInit) {
      mediafileEditor.fieldSet({ field: 'pxfile', value: props.pxfileInit })
    }

    // vuelidate RULES
    const rules = ref()
    switch (mediafileEditor.fieldGet('mediafileType')) {
      case 'webapp-microsite-game' :
        rules.value = {
          name: {
            required
          }
        }
      break
      case 'tv' :
      case 'movie' :
      case 'safety-vpa-advert' :
        rules.value = {
          name: { required },
          audioTracks: { customLanguageValidation: customLanguageValidation(audioTracksErrorsList) },
          burnedSubs: { customLanguageValidation: customLanguageValidation(burnedSubsErrorsList) },
          dynamicSubs: { customLanguageValidation: customLanguageValidation(dynamicSubsErrorsList) }
        }
      break
      case 'prams-bgm' :
      case 'document-pdf' :
      case 'audio' :
        rules.value = {
          name: {
            required
          }
        }
        break
      case 'image' :
        rules.value = {
          name: {
            required
          },
          format: {
            required
          },
          colorType: {
            required
          }
        }
        break
    }

    const v$ = useVuelidate(rules.value, { name, language, format, colorType, audioTracks, burnedSubs, dynamicSubs }, { $scope: false })

    const nameError = computed(() => {
      if (v$.value.name.$error) {
        return t('common.pleaseEnterName')
      } else {
        return ''
      }
    })

    const audioTracksError = computed(() => {
      if (v$.value.audioTracks?.$error) {
        return t('common.pleaseSelectAudiotrackLanguage')
      } else {
        return ''
      }
    })

    const burnedSubsError = computed(() => {
      if (v$.value.burnedSubs?.$error) {
        return t('common.pleaseSelectBurnedSubsLanguage')
      } else {
        return ''
      }
    })

    const dynamicSubsError = computed(() => {
      if (v$.value.dynamicSubs?.$error) {
        return t('common.pleaseSelectDynamicSubsLanguage')
      } else {
        return ''
      }
    })

    const languageError = computed(() => {
      if (v$.value.language?.$error) {
        return t('common.pleaseSelectLanguage')
      } else {
        return ''
      }
    })

    const formatError = computed(() => {
      if (v$.value.format?.$error) {
        return t('common.pleaseSelectFormat')
      } else {
        return ''
      }
    })

    const colorTypeError = computed(() => {
      if (v$.value.colorType?.$error) {
        return t('common.pleaseSelectColorType')
      } else {
        return ''
      }
    })

    // lists
    // TODO from DB
    const rscCodecsAudio = [
      {id: "MP3", name: "MP3", type: "audio"},
      {id: "MP2", name: "MP2", type: "audio"},
      {id: "ABS", name: "ABS", type: "audio"},
      {id: "WAV", name: "WAV", type: "audio"},
      {id: "AAC", name: "AAC", type: "audio"},
    ]

    // TODO from DB
    const rscCodecsVideo = [
      {id: "MPEG4", name: "MPEG4", type: "video"},
      {id: "MP4", name: "MP4", type: "video"},
      {id: "MPG", name: "MPG", type: "video"}
    ]

    // TODO from DB
    const rscBitrates = [
      {id: "K128", name: "128 Kbps"},
      {id: "K192", name: "192 Kbps"},
      {id: "K256", name: "256 Kbps"}
    ]

    // TODO from DB
    const rscVideoBitrates = [
      {id: "M1_5", name: "1.5 Mbps"}
    ]

    // TODO from DB
    const rscEncodings = [
      {id: "LeftMono", name: "Left-Mono"},
      {id: "RightMono", name: "Right-Mono"},
      {id: "Stereo", name: "Stereo"},
      {id: "Unused", name: "Unused"}
    ]

    // TODO from DB
    const rscColorTypes = [
      {id: "RGB", name: "RGB"},
      {id: "GRAY", name: t("common.grayLevel")}
    ]

    // TODO from DB
    const rscFormats = [
      {id: "JPG", name: "JPG"},
      {id: "JPEG", name: "JPEG"},
      {id: "PNG", name: "PNG"}
    ]

    // TODO from DB
    const rscResolutions = [
      {id: '_480p', name: '480p'},
      {id: '_720p', name: '720p'},
      {id: '_1080p', name: '1080p'},
      {id: '_4K', name: '4K'}
    ]

    // TODO from DB
    const rscRatios = [
      {id: '_16_9', name: '16x9'},
      {id: '_4_3', name: '4x3'}
    ]

    // TODO from DB
    const rscBoxTypes = [
      {id: 'boxed', name: 'Boxed'},
      {id: 'single', name: 'Single'}
    ]

    // TODO from DB
    const rscVideoTrickModes = [
      {id: 'OFF_ALL', name: t('common.offAll')},
      {id: 'OFF_STOP', name: t('common.offStop')},
      {id: 'OFF_FFREW', name: t('common.offFfrew')},
      {id: 'ON_ALL', name: t('common.onAll')},
      {id: 'ON_STOP', name: t('common.onStop')},
      {id: 'ON_FFREW', name: t('common.onFfrew')}
    ]
    // init fields
    switch (mediafileEditor.fieldGet('mediafileType')) {
      case 'image':
        if (!mediafileEditor.fieldGet('content.colorType')) {
          mediafileEditor.fieldSet({ field: 'content.colorType', value: rscColorTypes[0].id })
        }
        break
      case 'safety-vpa-advert':
      case 'tv':
      case 'movie':
        if (!mediafileEditor.fieldGet('content.codec')) {
          mediafileEditor.fieldSet({ field: 'content.codec', value: rscCodecsVideo[0].id })
        }
        if (!mediafileEditor.fieldGet('content.videoBitrate')) {
          mediafileEditor.fieldSet({ field: 'content.videoBitrate', value: rscVideoBitrates[0].id })
        }
        break
      case 'prams-bgm':
      case 'audio':
        if (!mediafileEditor.fieldGet('content.codec')) {
          mediafileEditor.fieldSet({ field: 'content.codec', value: rscCodecsAudio[0].id })
        }
        if (!mediafileEditor.fieldGet('content.bitrate')) {
          mediafileEditor.fieldSet({ field: 'content.bitrate', value: rscBitrates[0].id })
        }
        break
      case 'webapp-microsite-game':
      case 'document-pdf':
        break
      default:
        return
    }
    // init audiotracks language list & uuid
    audioTracks.value?.forEach(audiotrack => {
      if (!audiotrack.uuid) {
        audiotrack.uuid = uuid.v1()
      }
      audioTracksLanguageListCounter.value[audiotrack.language?.id] = 1
    })
    // init burnedsubs language list & uuid
    burnedSubs.value?.forEach(sub => {
      if (!sub.uuid) {
        sub.uuid = uuid.v1()
      }
      burnedSubsLanguageListCounter.value[sub.language?.id] = 1
    })
    // init dynamicsubs language list & uuid
    dynamicSubs.value?.forEach(sub => {
      if (!sub.uuid) {
        sub.uuid = uuid.v1()
      }
      let key = `${sub.language?.id}${sub.CC}`
      dynamicSubsLanguageListCounter.value[key] = 1
    })

    return {
      ...mediafileEditor,
      rules,
      v$,
      fileUpload,
      uploadAuthorizedExtensions,
      isUploadConfirmationVisible,
      isUploading,
      name,
      nameError,
      languageError,
      formatError,
      colorTypeError,
      audioTracksError,
      audioTracksErrorsList,
      audioTracksLanguageListCounter,
      burnedSubsError,
      burnedSubsErrorsList,
      dynamicSubsError,
      dynamicSubsErrorsList,
      codec,
      hasCodecFromFile,
      encoding,
      colorType,
      format,
      bitrate,
      videoBitrate,
      resolution,
      ratio,
      pxfile,
      language,
      audioTracks,
      burnedSubs,
      burnedSubsLanguageListCounter,
      dynamicSubs,
      dynamicSubsLanguageListCounter,
      addAudioTrack,
      updateLanguage,
      updateDynamicSubsLanguage,
      updateDynamicSubsCCLanguage,
      removeAudioTrack,
      addBurnedSub,
      removeBurnedSub,
      addDynamicSub,
      removeDynamicSub,
      clickUpload,
      confirmUpload,
      upload,
      isAudioType,
      isMovieType,
      isTVType,
      isPramsBGMType,
      isPdfType,
      isSafetyVPAAdvertType,
      isGameMicrositeType,
      isImageType,
      popinText,
      rscLabs,
      rscBitrates,
      rscBoxTypes,
      rscCodecsAudio,
      rscCodecsVideo,
      rscColorTypes,
      rscEncodings,
      rscFormats,
      rscRatios,
      rscResolutions,
      rscVideoBitrates,
      rscVideoTrickModes
    }
  },
  computed: {
    ...mapGetters('resource', ['languages', 'languageById']),
    isOvercharged: {
      get () { return this.fieldGet('isOvercharged') },
      set (val) { this.fieldSet({field: 'isOvercharged', value: val}) }
    },
    isEncrypted: {
      get () { return this.fieldGet('content.encrypted') },
      set (val) { this.fieldSet({field: 'content.encrypted', value: val}) }
    },
    boxType: {
      get () { return this.fieldGet('content.boxType') },
      set (val) { this.fieldSet({field: 'content.boxType', value: val}) }
    },
    videoTrickMode: {
      get () { return this.fieldGet('content.videoTrickMode') },
      set (val) { this.fieldSet({field: 'content.videoTrickMode', value: val}) }
    },
    lab: {
      get () { return this.fieldGet('lab') },
      set (val) { this.fieldSet({field: 'lab', value: val}) }
    },
    embeddedFilename: {
      get () { return this.fieldGet('embeddedFilename') },
      set (val) { this.fieldSet({field: 'embeddedFilename', value: val}) }
    },
    mediafileComponent () {
      switch (this.fieldGet('mediafileType')) {
        case 'movie': return 'MediaFileTypeMovie'
      }

      return 'MediaFileTypeUnknown'
    },
    burnedSubsLength () {
      return this.burnedSubs ? this.burnedSubs.length : 0
    }
  },
  methods: {
    update () {
      this.v$.$validate()
      if (!this.v$.$error) {
        this.$emit('child-submit', this.saveFlowBuild())
      }
    },
    close () {
      this.$emit('close', false)
    },
    onRowReorder (event, item) {
      this.fieldSet({field: `content.${item}`, value: event.value})
    },
    async searchLabs ({query}) {
      try {
        const qryBuilder = this.$pxstream.tools.createQueryBuilder()
        qryBuilder.setFilterSearch(query)
        const {data} = await this.$pxstream.resource.getLabs(qryBuilder.build())
        this.rscLabs = data
      } catch (err) {
        this.$toast.add({severity: 'error', summary: 'Failed to get labs', detail: this.$pxstream.http.getHttpErrorShortMessage(err), life: 4000})
      }
    },
    languageSort (a, b) {
      if (!a.order && b.order) {
        return 1
      } else if (a.order && !b.order) {
        return -1
      } else if (a.order && b.order) {
        return a.order-b.order
      }
      return 0
    }
  }
}

</script>
<style scoped>
  .no-margin {
    margin: 0;
  }
</style>
<style>
  .invisible {
    visibility: hidden;
  }
</style>
