<template>
  <div class="grid">
    <label class="col-12">{{$t('common.color', 2)}}:</label>
    <div class="col-12 md:col-6">
      <div class="grid">
        <div class="col-10 text-left col-offset-2">{{$t('common.lightModeColors')}}:</div>
        <div class="col-5 text-right col-offset-2 italic">{{$t('common.contrast')}}</div>
        <div class="col-5 italic">{{$t('common.main')}}</div>
        <div class="col-5 text-right">{{$t('common.primary')}}</div>
        <div class="col-2 text-right"><ColorPicker v-model="lightPrimaryContrast" /></div>
        <div class="col-5 text-left"><ColorPicker v-model="lightPrimaryMain" /></div>
        <div class="col-5 text-right">{{$t('common.secondary')}}</div>
        <div class="col-2 text-right"><ColorPicker v-model="lightSecondaryContrast" /></div>
        <div class="col-5 text-left"><ColorPicker v-model="lightSecondaryMain" /></div>
        <div class="col-5 text-right">{{$t('common.content')}}</div>
        <div class="col-2 text-right"><ColorPicker v-model="lightContentContrast" /></div>
        <div class="col-5 text-left"><ColorPicker v-model="lightContentMain" /></div>
        <div class="col-5 text-right">{{$t('common.custom')}}</div>
        <div class="col-5 col-offset-2"><ColorPicker v-model="lightCustomMain" /></div>
      </div>
    </div>
    <div class="col-12 md:col-6">

      <div class="grid">
        <div class="col-10 text-left col-offset-2">{{$t('common.darkModeColors')}}:</div>
        <div class="col-5 text-right col-offset-2 italic">{{$t('common.contrast')}}</div>
        <div class="col-5 italic">{{$t('common.main')}}</div>
        <div class="col-5 text-right">{{$t('common.primary')}}</div>
        <div class="col-2 text-right"><ColorPicker v-model="darkPrimaryContrast" /></div>
        <div class="col-5 text-left"><ColorPicker v-model="darkPrimaryMain" /></div>
        <div class="col-5 text-right">{{$t('common.secondary')}}</div>
        <div class="col-2 text-right"><ColorPicker v-model="darkSecondaryContrast" /></div>
        <div class="col-5 text-left"><ColorPicker v-model="darkSecondaryMain" /></div>
        <div class="col-5 text-right">{{$t('common.content')}}</div>
        <div class="col-2 text-right"><ColorPicker v-model="darkContentContrast" /></div>
        <div class="col-5 text-left"><ColorPicker v-model="darkContentMain" /></div>
        <div class="col-5 text-right">{{$t('common.custom')}}</div>
        <div class="col-5 col-offset-2"><ColorPicker v-model="darkCustomMain" /></div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import ColorPicker from "@/components/base/ColorPicker"
import { useFlowEditor } from '@/compositions'

export default {
  components: { ColorPicker },
  props: { editorKey: String },
  setup (props) {
    const { fieldGet, fieldSet } = useFlowEditor(props.editorKey)

    const computedColorFactory = function (color) {
      return computed({
        get () { return fieldGet(color) || '' },
        set (value) { fieldSet({ field: color, value })}
      })
    }

    return {
      lightPrimaryMain: computedColorFactory('cycle.colors.light.primary.main'),
      lightPrimaryContrast: computedColorFactory('cycle.colors.light.primary.contrast'),
      lightSecondaryMain: computedColorFactory('cycle.colors.light.secondary.main'),
      lightSecondaryContrast: computedColorFactory('cycle.colors.light.secondary.contrast'),
      lightContentMain: computedColorFactory('cycle.colors.light.content.main'),
      lightContentContrast: computedColorFactory('cycle.colors.light.content.contrast'),
      lightCustomMain: computedColorFactory('cycle.colors.light.custom.main'),

      darkPrimaryMain: computedColorFactory('cycle.colors.dark.primary.main'),
      darkPrimaryContrast: computedColorFactory('cycle.colors.dark.primary.contrast'),
      darkSecondaryMain: computedColorFactory('cycle.colors.dark.secondary.main'),
      darkSecondaryContrast: computedColorFactory('cycle.colors.dark.secondary.contrast'),
      darkContentMain: computedColorFactory('cycle.colors.dark.content.main'),
      darkContentContrast: computedColorFactory('cycle.colors.dark.content.contrast'),
      darkCustomMain: computedColorFactory('cycle.colors.dark.custom.main')
    }
  }
}
</script>
<style scoped>
	.italic {
		font-style: italic;
	}
</style>