<template>
  <div>
    <DataTable
      v-model:filters="lazyParams.filters"
      v-model:selection="selectedMediaComputed"
      ref="dt"
      class="p-datatable-sm media-modal-datatable"
      stripedRows
      showGridlines
      filterDisplay="menu"
      responsiveLayout="scroll"
      :rowHover="true"
      :value="media.list"
      :lazy="true"
      :loading="loading"
      :sortField="lazyParams.sortField"
      :sortOrder="lazyParams.sortOrder"
      @row-click="select($event.data)"
      @filter="onLazyParams($event)">

      <template #header>
        <div class="flex flex-wrap justify-content-between">
          <div class="flex">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText v-model="lazyParams.filters['search'].value" :placeholder="$t('buttons.search')" @keydown.enter="getList()" />
            </span>
          </div>

          <div class="flex flex-wrap">
            <span class="input-table">
              <span class="input-label p-2">{{$t('medias.tracks')}} : </span>
              <MultiLanguageSelector
                v-model="lazyParams.filters.tracks"
                @change="getList()"
              />
            </span>

            <span class="input-table">
              <span class="input-label p-2">{{$t('common.burnedInSub')}} : </span>
              <MultiLanguageSelector
                v-model="lazyParams.filters.burnedSubs"
                @change="getList()"
              />
            </span>

            <span class="input-table">
              <span class="input-label p-2">{{$t('common.dynamicsub')}} : </span>
              <MultiLanguageSelector
                v-model="lazyParams.filters.dynamicSubs"
                @change="getList()"
              />
            </span>
          </div>
        </div>
      </template>

      <template #empty>
          {{$t('medias.noMediaCorresponding')}}
      </template>

      <template #loading>
          {{ $t('medias.loadingMedia') }}
      </template>

      <Column field="contentType.name" header="Type"
        :showFilterOperator="false"
        :showFilterMatchModes="false">
        <template #filter="{filterModel}">
          <div v-for="one in contentTypes" :key="one.id" class="field-checkbox">
            <Checkbox :id="one.id" name="contentTypes" :value="one.id" v-model="filterModel.value" />
            <label :for="one.id">{{one.name}}</label>
          </div>
        </template>
      </Column>

      <Column field="name" :header="$t('common.title')"></Column>

      <Column field="nbMediafiles" header="Nb Mediafiles"></Column>

      <Column :header="$t('medias.tracks')">
        <template #body="{data}">
          <ViewFlagRow field="id" :flags="data.audioTracks" :max="3" />
        </template>
      </Column>

      <Column header="Burned in Sub">
        <template #body="{data}">
          <ViewFlagRow field="id" :flags="data.burnedSubs" :max="3" />
        </template>
      </Column>

      <Column header="Dynamic Sub">
        <template #body="{data}">
          <ViewFlagRow field="language.id" :flags="data.dynamicSubs" :max="3" useCC />
        </template>
      </Column>

      <Column>
        <template #body="{data, index}">
          <div class="flex justify-content-end w-full">
            <Button v-if="data.children.length" :icon="'pi ' + (data.deployed ? 'pi-minus' : 'pi-plus')" class="p-button-rounded p-button-primary p-button-outlined mr-2" @click="deploy(index)" />
            <Button
              v-if="data.contentType.id !== '0'"
              icon="pi pi-info"
              class="p-button-rounded"
              :loading="data.isLoadingMore"
              @click="more(data)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
    <Sidebar
      v-model:visible="sidebarVisible"
      position="right"
      class="p-sidebar-md sidebar-inside"
      :dismissable="true"
      :showCloseIcon="true">
        <component :is="mediaMoreComponent" :editorKey="editorKey" :mediaMore="mediaMore" />
    </Sidebar>

  </div>
</template>

<script>
import { ref, computed, inject, reactive, watch } from 'vue'
import { useFlowEditor } from '@/compositions'
import { useFlowCruder } from '@/compositions/useFlowCruder'
import Log from '@/services/logger'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Checkbox from 'primevue/checkbox'
import Sidebar from 'primevue/sidebar'
import { useToast } from 'primevue/usetoast'

import ViewFlagRow from './tableRows/ViewFlagRow.vue'

import MultiLanguageSelector from '@/components/resource/MultiLanguageSelector.vue'

import ViewMediaMoreAudio from './mediaMore/ViewMediaMoreAudio.vue'
import ViewMediaMoreRadio from './mediaMore/ViewMediaMoreRadio.vue'
import ViewMediaMoreVideo from './mediaMore/ViewMediaMoreVideo.vue'
import ViewMediaMoreBroadcastAndAdvert from './mediaMore/ViewMediaMoreBroadcastAndAdvert.vue'

const KFlowPortalmanagerMedia = "portalmanager:media"
const KFlowPortalmanagerMediaTVSeason = "portalmanager:media-tv-season"

export default {
  name: 'AddMediaListing',
  setup: (props, { emit }) => {
    const toast = useToast()
    const pxstream = inject('pxstream')

    const media = reactive({list: []})
    const isAddingSelectedMedia = ref(false)
    const mediaMore = ref(null)
    const loading = ref(false)
    const isListingMode = ref(true)
    const errorCaught = ref('')
    const lazyParams = ref({
      sortField: 'name',
      sortOrder: 1,
      filters: {
        'search': {value: '', matchMode: 'contains'},
        'tracks': [],
        'burnedSubs': [],
        'dynamicSubs': []
      },
    })

    const { flowGet } = useFlowCruder()

    const editor = computed(() => { return useFlowEditor(props.editorKey) })

    const mediaTypes = computed(() => { return editor.value.fieldGet('data.mediaTypes').map(val => val.id) })

    const mediafiles = computed(() => { return editor.value.fieldGet('data.mediafiles') })

    const sidebarVisible = computed({
      get () { return !!mediaMore.value },
      set (value) { if (value === false) mediaMore.value = null }
    })

    const selectedMediaComputed = computed({
      get () { return props.selectedMedia },
      set () {}
    })

    const getList = async () => {
      const params = JSON.parse(JSON.stringify(lazyParams.value))

      if (params.filters['contentType.name']) {
        params.filters['contentTypes'] = params.filters['contentType.name']
        delete params.filters['contentType.name']
      }

      if (mediaTypes.value.length > 0) {
        params.filters['contentFormats'] = {
          matchMode: 'contains',
          value: mediaTypes.value
        }
      }

      if (mediafiles.value?.length > 0) {
        params.filters['mediafiles'] = {
          matchMode: 'nin',
          value: mediafiles.value.map(mediafile => mediafile.id)
        }
      }

      if (lazyParams.value.filters.tracks.length > 0) {
        params.filters['audioTracks'] = {
          matchMode: 'contains',
          value: lazyParams.value.filters.tracks
        }
      }

      if (lazyParams.value.filters.burnedSubs.length > 0) {
        params.filters['burnedSubs'] = {
          matchMode: 'contains',
          value: lazyParams.value.filters.burnedSubs
        }
      }

      if (lazyParams.value.filters.dynamicSubs.length > 0) {
        params.filters['dynamicSubs'] = {
          matchMode: 'contains',
          value: lazyParams.value.filters.dynamicSubs
        }
      }
      loading.value = true
      try {
        const {data: list} = await pxstream.portalmanager.postMediaWithMediafileInfoList(params)
        const createElementFromDataResult = father => val => {
          if (val.contentType.name === 'TV Season' && father) {
            val.name = `${father.name} - ${val.name}`
          } else if (val.contentType.name === 'TV Simple' && father) {
            val.name = `${father.father.name} - ${val.name}`
          }
          val.deployed = false
          val.isLoadingMore = false
          val.more = null
          val.father = father
          val.children = val.children.map(createElementFromDataResult({ ...val, children: null }))
          return val
        }
        media.list = (list || []).map(createElementFromDataResult(null))
        if (props.mediaAdded && props.mediaAdded.length >= 1) {
          media.list = media.list.filter(media => props.mediaAdded.filter(confirmedMedia => confirmedMedia.id === media.id).length === 0)
        }

      } catch (err) {
        toast.add({severity: 'error', summary: 'Failed to get media with mediafile info', detail: pxstream.http.getHttpErrorShortMessage(err), life: 4000})
      }
      loading.value = false
    }

    const onLazyParams = (e) => {
      if (e) {
        lazyParams.value = e
      }
      getList()
    }

    // Utility findFct. Generate findFct to find the toFind doc.
    const findFct = (toFind, reversed) => value => {
      const res = value.id === toFind.id && value.contentType.id === toFind.contentType.id
      return reversed ? !res : res
    }

    const select = (data) => {
      // Check if clicked element is selected or not and get index to remove it easier
      const indexToRemove = props.selectedMedia.findIndex(findFct(data))
      let newSelectedMedia = props.selectedMedia
      if (indexToRemove !== -1) {
        // Function to get selected children recursively in selectedMedia
        const gettingSelectedChildrenRecursive = (acc, val) => {
          if (newSelectedMedia.findIndex(findFct(val)) !== -1) {
            acc.push(val)
          }
          acc = acc.concat(val.children.reduce(gettingSelectedChildrenRecursive, []))
          return acc
        }
        // Function to generate a search function in order to seek parents of a given document in selectedMedia
        const gettingSelectedParentsRecursive = toFind => (acc, val) => {
          if (val.children.find(findFct(toFind))) {
            acc.push(val)
            acc = acc.concat(newSelectedMedia.reduce(gettingSelectedParentsRecursive(val), []))
          }
          return acc
        }

        // Unselect element
        newSelectedMedia.splice(indexToRemove, 1)
        // Unselect children
        newSelectedMedia = newSelectedMedia.filter(value => !data.children.reduce(gettingSelectedChildrenRecursive, []).find(findFct(value)))
        // Unselect parents as all children are no longer all selected
        newSelectedMedia = newSelectedMedia.filter(value => !newSelectedMedia.reduce(gettingSelectedParentsRecursive(data), []).find(findFct(value)))
      } else {
        // Function to get non selected children recursively in selectedMedia (we have to add only non selected children to avoid duplicate elements in selectedMedia)
        const gettingNonSelectedChildrenRecursive = (acc, val) => {
          if (newSelectedMedia.findIndex(findFct(val)) === -1) {
            acc.push(val)
          }
          acc = acc.concat(val.children.reduce(gettingNonSelectedChildrenRecursive, []))
          return acc
        }

        // Select element
        newSelectedMedia.push(data)
        // Select all children that are not already in selectedMedia
        newSelectedMedia = newSelectedMedia.concat(data.children.reduce(gettingNonSelectedChildrenRecursive, []))
      }

      emit('update:selectedMedia', newSelectedMedia)
    }

    const getThreeFirstElements = (array) => {
      return (array || []).filter((val, idx) => idx < 3)
    }

    const deploy = (idx) => {
      if (!media.list[idx].deployed) {
        media.list.splice(idx+1, 0, ...media.list[idx].children)
      } else {
        const getIdsFunc = (acc, val) => {
          acc.push({
            id: val.id,
            contentType: val.contentType.id
          })
          acc = acc.concat(val.children.reduce(getIdsFunc, []))
          return acc
        }
        const idsToRemove = media.list[idx].children.reduce(getIdsFunc, [])

        const undeployRecursive = (val) => {
          val.deployed = false
          val.children = val.children.map(undeployRecursive)
          return val
        }
        media.list[idx].children = media.list[idx].children.map(undeployRecursive)
        media.list = media.list.filter(val => !idsToRemove.find(idToRemove => idToRemove.id === val.id && idToRemove.contentType === val.contentType.id))
      }
      media.list[idx].deployed = !media.list[idx].deployed
    }

    const more = async (data) => {
      if (!data.more) {
        data.isLoadingMore = true
        try {
          const {data: dataMore} = await flowGet(data.contentType.id === "0" ? KFlowPortalmanagerMediaTVSeason : KFlowPortalmanagerMedia, data.id)
          data.more = dataMore
        } catch (err) {
          toast.add({severity: 'error', summary: 'Failed to get media additional data', detail: pxstream.http.getHttpErrorShortMessage(err), life: 4000})
        }
        data.isLoadingMore = false
      }
      mediaMore.value = data
    }

    const closeSidebar = () => {
      mediaMore.value = null
    }
    getList()

    watch(() => props.removeFromMediaListTrigger, function () {
      const deleteSelectedMediaRecursive = (acc, val) => {
        val.children = val.children.reduce(deleteSelectedMediaRecursive, [])
        if (!props.selectedMedia.find(findFct(val))) {
          acc.push(val)
        }
        return acc
      }

      media.list = media.list.reduce(deleteSelectedMediaRecursive, [])

      emit('update:selectedMedia', [])
    })
    return {
      media,
      isAddingSelectedMedia,
      mediaMore,
      loading,
      isListingMode,
      errorCaught,
      lazyParams,
      sidebarVisible,
      selectedMediaComputed,
      onLazyParams,
      select,
      getThreeFirstElements,
      deploy,
      more,
      closeSidebar,
      getList
    }
  },
  props: {
    editorKey: String,
    selectedMedia: Array,
    mediaAdded: Array,
    removeFromMediaListTrigger: Number
  },
  components: {
    DataTable,
    Column,
    MultiLanguageSelector,
    InputText,
    Button,
    Checkbox,
    ViewFlagRow,
    Sidebar,
    ViewMediaMoreAudio,
    ViewMediaMoreRadio,
    ViewMediaMoreVideo,
    ViewMediaMoreBroadcastAndAdvert,
  },
  computed: {
    mediaMoreComponent () {
      let mediaComponent = 'ViewMediaMoreUnknown'

      switch (this.mediaMore.more.mediaType) {
        case 'audio-album':
        case 'audio-podcast':
        case 'audio-simple': mediaComponent = 'ViewMediaMoreAudio'; break;
        case 'audio-radio': mediaComponent = 'ViewMediaMoreRadio'; break;
        case 'trailer':
        case 'tv-simple':
        case 'tv-series':
        case 'short-movie':
        case 'movie': mediaComponent = 'ViewMediaMoreVideo'; break;
        case 'broadcast-prams':
        case 'broadcast-bgm':
        case 'broadcast-vpa':
        case 'broadcast-safety':
        case 'advert': mediaComponent = 'ViewMediaMoreBroadcastAndAdvert'; break;
        default: Log.Debug('Unknown media more type:', JSON.stringify(this.mediaMore.more.mediaType, null, 2));
      }

      return mediaComponent
    },
  }
}
</script>

<style>
.p-datatable.p-datatable-striped.media-modal-datatable .p-datatable-tbody > tr:nth-child(even).p-highlight,
.p-datatable.media-modal-datatable .p-datatable-tbody tr.p-highlight {
    background: #6cde97;
    color: #000000;
}

.p-badge {
  line-height: 1.5rem;
}

.input-table {
  display: table;
}

.input-label {
  display: table-cell;
  vertical-align: middle;
}
</style>
