<template>
  <div>
    <DataTable
      ref="dt"
      class="p-datatable-sm"
      stripedRows
      showGridlines
      filterDisplay="menu"
      responsiveLayout="scroll"
      :rowHover="true"
      :sortField="lazyParams.sortField"
      :sortOrder="lazyParams.sortOrder"
      :value="mediafiles">

      <Column :style="{width: '36px'}">
        <template #body="{data}">
          <div>
            <Icon :icon="typeIcon(data.media)" />
          </div>
        </template>
      </Column>

      <Column field="title" :header="$t('common.title')"></Column>

      <Column field="filename" header="Mediafile">
        <template #body="{data}">
          <span>
            {{data.filename}}
          </span>
          <Badge v-if="data.isNew" class="p-mr-2 align-self-center left-space" value="NEW"></Badge>
        </template>
      </Column>

      <Column :header="$t('medias.tracks')">
        <template #body="{data}">
          <ViewFlagRow field="id" :flags="data.audioTracks" :max="3" />
        </template>
      </Column>

      <Column header="Burned in Sub">
        <template #body="{data}">
          <ViewFlagRow field="id" :flags="data.burnedSubs" :max="3" />
        </template>
      </Column>

      <Column header="Dynamic Sub">
        <template #body="{data}">
          <ViewFlagRow field="language.id" :flags="data.dynamicSubs" :max="3" />
        </template>
      </Column>

      <Column>
        <template #body="{data}">
          <div class="flex justify-content-evenly align-items-center w-full">
            <Checkbox :id="data.mediafile.id" name="selected" :value="data" v-model="selected" :disabled="data.isUnselectable"/>
            <Button
              icon="pi pi-info"
              class="p-button-rounded ml-2"
              @click="more(data)"
            />
          </div>
        </template>
      </Column>
    </DataTable>

    <Sidebar
      v-model:visible="sidebarVisible"
      position="right"
      class="p-sidebar-lg"
      :dismissable="true"
      :showCloseIcon="true">
      <component :is="mediaFileMoreComponent" :editorKey="editorKey" :mediaFileMore="mediafileMore"/>
    </Sidebar>
  </div>
</template>

<script>
import { useFlowEditor } from '@/compositions/useFlowEditor'
import { ref, computed, watch, inject } from 'vue'
import Log from '@/services/logger'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import Checkbox from 'primevue/checkbox'
import Sidebar from 'primevue/sidebar'

import Badge from 'primevue/badge'
import ViewFlagRow from './tableRows/ViewFlagRow.vue'

import { Icon, addIcon } from '@iconify/vue/dist/offline'
import iconHelp from '@iconify-icons/mdi/help-rhombus-outline'
import iconMusic from '@iconify-icons/mdi/music-box-outline'
import iconMovie from '@iconify-icons/mdi/movie'
import iconPodcast from '@iconify-icons/mdi/podcast'
import iconAlbum from '@iconify-icons/mdi/album'
import iconTv from '@iconify-icons/mdi/television'
import iconRadio from '@iconify-icons/mdi/radio'

import ViewMediaFileMoreMovie from './mediaFileMore/ViewMediaFileMoreMovie.vue'
import ViewMediaFileMoreAudio from './mediaFileMore/ViewMediaFileMoreAudio.vue'
import ViewMediaFileMoreTvSimple from './mediaFileMore/ViewMediaFileMoreTvSimple.vue'
import ViewMediaFileMorePrams from './mediaFileMore/ViewMediaFileMorePrams.vue'
import ViewMediaFileMoreAdvert from './mediaFileMore/ViewMediaFileMoreAdvert.vue'

addIcon('help', iconHelp)
addIcon('music', iconMusic)
addIcon('movie', iconMovie)
addIcon('podcast', iconPodcast)
addIcon('album', iconAlbum)
addIcon('radio', iconRadio)
addIcon('tv', iconTv)

import slugify from 'slugify'

export default {
  name: 'AddMediaCheckout',
    props: {
    editorKey: String,
    selectedMediafiles: Array,
    toAddMedia: Array
  },
  components: {
    DataTable,
    Column,
    Button,
    ViewFlagRow,
    Checkbox,
    Sidebar,
    Icon,
    ViewMediaFileMoreMovie,
    ViewMediaFileMoreAudio,
    ViewMediaFileMoreTvSimple,
    ViewMediaFileMorePrams,
    ViewMediaFileMoreAdvert,
    Badge
  },
  setup(props, { emit }) {
    const menuItemEditorKey = inject('menuItemEditorKey')
    const mediafileMore = ref(null)
    const mediafiles = ref([])

    const lazyParams = ref({
      sortField: 'title',
      sortOrder: 1,
      filters: {
        'search': {value: '', matchMode: 'contains'}
      },
    })

    const menuItemEditor = computed(() => useFlowEditor(menuItemEditorKey.value))
    const menuItemMedias = computed(() => menuItemEditor.value.fieldGet('data.medias'))
    const menuItemMediaFiles = computed(() => menuItemEditor.value.fieldGet('data.mediafiles'))
    const sidebarVisible = computed({
      get () { return !!mediafileMore.value },
      set (value) { if (value === false) mediafileMore.value = null }
    })

    const selected = computed({
      get () { return props.selectedMediafiles },
      set (value) { emit('update:selectedMediafiles', value) }
    })

    const getThreeFirstElements = (array) => {
      return (array || []).filter((val, idx) => idx < 3)
    }

    const typeIcon = (media) => {
      switch (media.mediaType) {
        case 'audio-simple': return 'music';
        case 'audio-album': return 'album';
        case 'audio-podcast': return 'podcast';
        case 'audio-radio': return 'radio';
        case 'short-movie':
        case 'trailer':
        case 'movie': return 'movie';
        case 'tv-series':
        case 'tv-simple': return 'tv';
        default: if (media.mediaType) Log.Debug(`Unknown media : ${media.mediaType}`);
      }

      return 'help'
    }

    const more = (data) => {
      mediafileMore.value = data.mediafile
    }

    const getAllMediafilesFromTracks = (acc, track) => {
      return acc.concat(track.media.mediafiles.length ? track.media.mediafiles : [{ isNew: true, trackId: track.id }])
    }

    const getAudioTracksFromMediafileContent = (content) => {
      if (content.language) {
        if (content.language.id !== "0") {
          return [content.language]
        } else {
          return []
        }
      } else {
        return content.audioTracks.map(audioTrack => audioTrack.language)
      }
    }

    const getBurnedSubsFromMediafileContent = (content) => {
      if (content.burnedSubs) {
        return content.burnedSubs.map(burnedSub => burnedSub.language)
      } else {
        return []
      }
    }

    const getDynamicSubsFromMediafileContent = (content) => {
      if (content.dynamicSubs) {
        return content.dynamicSubs.map(dynamicSub => ({
          language: dynamicSub.language,
          cc: dynamicSub.CC
        }))
      } else {
        return []
      }
    }

    const getAllMediafilesFromMedia = (acc, media) => {
      let mediaMediafiles
      let mediaFather = null
      let title = media.name
      if (media.more.mediaType === 'audio-album') {
        mediaMediafiles = media.more.media.tracks.reduce(getAllMediafilesFromTracks, [])
      } else {
        mediaMediafiles = media.more.media.mediafiles.length ? media.more.media.mediafiles : [{ isNew: true }]
      }
      if (media.more.mediaType === 'tv-simple' && media.father) {
        mediaFather = media.father.father.more
        title = `${mediaFather.name} - S${media.father.more.seasonNumber}E${media.more.media.episodeNumber} - ${media.more.name}`
      }

      mediaMediafiles = mediaMediafiles.filter(val => !menuItemMediaFiles.value.find(menuItemMediafile => val.id === menuItemMediafile.id))

      return acc.concat(mediaMediafiles.map((mediafile) => {
        const isNew = !!mediafile.isNew
        let mediaChild = null
        let isUnselectable = false
        let filename = mediafile.filename
        if (media.more.mediaType === 'audio-album') {
          if (isNew) {
            mediaChild = media.more.media.tracks.find(track => track.id === mediafile.trackId)
            isUnselectable = true
            filename = slugify(mediaChild.name, '')
          } else if (menuItemMedias.value.find(menuItemMedia => menuItemMedia.id === media.id)) {
            mediaChild = media.more.media.tracks.find(track => track.media.mediafiles.find(trackMf => trackMf.id === mediafile.id))
            isUnselectable = false
          } else {
            mediaChild = media.more.media.tracks.find(track => track.media.mediafiles.find(trackMf => trackMf.id === mediafile.id))
            isUnselectable = computed(() => {
              const isSelected = !!selected.value.find(sl => mediafile.id === sl.mediafile.id)
              const nbSelectedOfAudioSimple = selected.value.filter(sl => mediaChild.media.mediafiles.find(childMf => childMf.id === sl.mediafile.id)).length
              return isSelected & nbSelectedOfAudioSimple === 1
            })
          }
        } else if (isNew) {
          filename = slugify(media.more.name, '')
        }
        return {
          isNew,
          filename,
          audioTracks: !isNew ? getAudioTracksFromMediafileContent(mediafile.content) : [],
          burnedSubs: !isNew ? getBurnedSubsFromMediafileContent(mediafile.content) : [],
          dynamicSubs: !isNew ? getDynamicSubsFromMediafileContent(mediafile.content) : [],
          media: media.more,
          mediaFather,
          mediaChild,
          mediafile,
          title,
          isUnselectable
        }
      }))
    }

    watch(() => props.toAddMedia, (newVal, oldVal) => {
      const findFct = (toSearch) => (val) => toSearch.id === val.id && toSearch.contentType.id === val.contentType.id
      const newValues = newVal.filter(n => !oldVal.find(findFct(n)))
      const deletedValues = oldVal.filter(o => !newVal.find(findFct(o)))

      const newMediafiles = newValues.reduce(getAllMediafilesFromMedia, [])
      let changedSelectedMediafiles = selected.value.concat(newMediafiles)
      mediafiles.value = mediafiles.value.concat(newMediafiles).sort((a, b) => a.title.localeCompare(b.title))

      changedSelectedMediafiles = changedSelectedMediafiles.filter(val => !deletedValues.find(findFct(val)))
      mediafiles.value = mediafiles.value.filter(val => !deletedValues.find(findFct(val)))
      selected.value = changedSelectedMediafiles
    })

    return {
      lazyParams,
      mediafileMore,
      mediafiles,
      sidebarVisible,
      selected,
      getThreeFirstElements,
      typeIcon,
      more
    }
  },
  computed: {
    mediaFileMoreComponent () {
      let mediaFileComponent = 'ViewMediaFileMoreUnknown'
      switch (this.mediafileMore.mediafileType) {
        case 'movie': mediaFileComponent = 'ViewMediaFileMoreMovie'; break;
        case 'audio': mediaFileComponent = 'ViewMediaFileMoreAudio'; break;
        case 'tv': mediaFileComponent = 'ViewMediaFileMoreTvSimple'; break;
        case 'prams-bgm': mediaFileComponent = 'ViewMediaFileMorePrams'; break;
        case 'safety-vpa-advert': mediaFileComponent = 'ViewMediaFileMoreAdvert'; break;
        default: Log.Debug('Unknown media file more type:', JSON.stringify(this.mediafileMore.mediafileType, null, 2));
      }

      return mediaFileComponent
    },
  },
}
</script>

<style scoped>
.left-space {
  margin-left: 10px;
}
</style>