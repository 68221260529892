<template>
  <div>
    <Dialog 
    v-model:visible="showIt" 
    style="width:500px"
    @hide="$emit('update:modelValue', false);resetFields()"
    modal
    dismissableMask
    :closable="false"
    :draggable="false">
      <template #header>
        <div class="flex flex-auto flex-column text-700">
          <i class="pi pi-file-o text-center" style="fontSize: 3em"/>
          <h3 class="text-center">{{$t('ifeProduct.new')}}</h3>
        </div>
      </template>

      <form class="p-fluid" @keyup.enter="add" >
        <div class="fluid">

          <div class="field grid">
            <label for="ifeType" :class="{'p-error':ifeTypeError, 'col-fixed font-bold justify-content-end': true}" style="width:150px">{{$t('ifeProduct.field.type')}}</label>
            <div class="col">
              <Dropdown id="type" 
                v-model="ifeType" 
                :options="ifeToDisplay" 
                optionLabel="name" 
                :class="{'p-invalid': ifeTypeError}" 
                :disabled="isLoading" 
                :filter="false" />
              <small v-if="ifeTypeError" class="p-error">{{ifeTypeError}}</small>
            </div>
          </div>

        </div>
      </form>

      <Message v-if="errorCaught" severity="error" :closable="false">
        {{ errorCaught }}
      </Message>

      <template #footer>
        <div class="flex justify-content-between">
          <Button :label="$t('actions.cancel')" icon="pi pi-times" class="p-button-text" @click="showIt = false"/>
          <Button :label="$t('actions.add')" icon="pi pi-check" :loading="isLoading" @click="add()"/>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted, inject } from 'vue'
import { useFlowEditor } from '@/compositions'
import Message from "primevue/message"
import Dropdown from 'primevue/dropdown'

import { useVuelidate } from "@vuelidate/core"
import { required } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'

export default {
  name: 'ProjectIFEProductNew',
  props: ['modelValue', 'editorKey'],
  components: { 
    Message, Dropdown
  },
  setup (props, {emit}) {
    const ifeType = ref(null)
    const ifeList = ref([])
    const showIt = ref(false)
    const isLoading = ref(false)
    const errorCaught = ref('')
    const pxstream = inject('pxstream')
    const { t } = useI18n()

    const rules = {
      ifeType: {
        required
      }
    }

    const { fieldGet } = useFlowEditor(props.editorKey)

    const v$ = useVuelidate(rules, { ifeType }, { $scope: false })

    onMounted(async () => {
      const {data} = await pxstream.resource.getIfes({})
      ifeList.value = data
    })

    const ifeTypeError = computed(() => {
      return v$.value.ifeType.$dirty && v$.value.ifeType.required.$invalid ? t('forms.rules.required', {field: t('ifeProduct.field.type')}) : ''
    })

    const ifeToDisplay = computed(() => {
      return ifeList.value.filter((ife) => !Object.keys(fieldGet('ifeSystems')).includes(ife.id))
    })

    watch(() => props.modelValue, (val) => {
      showIt.value = val
    })

    const resetFields = () => {
      v$.value.$reset()
      errorCaught.value = ''
      ifeType.value = ''
    }

    const add = () => {
      v$.value.$touch()
      if (v$.value.$invalid) {
        return
      }

      errorCaught.value = ''

      showIt.value = false
      let ife = { id: ifeType.value.id, name: ifeType.value.name, targets: ifeType.value.targets }
      emit('addIfeProduct', {ife})
      resetFields()
    }

    return {
      ifeType,
      ifeToDisplay,
      showIt,
      isLoading,
      errorCaught,
      ifeTypeError,
      v$,
      add,
      resetFields
    }
  }
}
</script>
