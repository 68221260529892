<template>
  <div>
    <DataTable
      :loading="!reports"
      :value="reports"
      >
      <template #empty>
        No product found.
      </template>

      <template #loading>
        Loading products. Please wait.
      </template>

      <Column :header="$t('projects.field.product')" field="product" />

      <Column :header="$t('projects.field.ifeSystem')" field="args.IFESystem" />

      <Column :header="$t('projects.field.type')" field="args.FileType" />

      <Column :header="$t('projects.field.version')" field="version" />

      <Column :header="$t('projects.field.date')" field="doneAt"  />

      <Column :header="$t('projects.field.comment')" field="comment" />

      <Column>
        <template #header>
          <div class="flex justify-content-end w-full">
            <div>{{ $t('common.actions') }}</div>
          </div>
        </template>

        <template #body="slotProps">
          <div class="flex justify-content-end w-full">
            <Button icon="pi pi-download" class="p-button-rounded p-button-primary p-button-outlined mr-2" @click="download(slotProps.data)" />
          </div>
        </template>
      </Column>

    </DataTable>

  </div>
</template>

<script>
import { ref, inject } from 'vue'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'

import { userExporterProjectReports } from '@/compositions'
import http from '@/services/http'
import Log from '@/services/logger'

export default {
  name: 'ProjectGeneral',
  props: {
    editorKey: String
  },
  components: {
    DataTable, Column
  },
  setup (props) {
    const pxstream = inject('pxstream')

    const reports = ref([])

    userExporterProjectReports(props.editorKey.replace('project:', '')).then(async ({data}) => {
      await pxstream.resource.getIfes({}).then((ifeList) => {
        data.map((report) => {
          let formatedReport = {...report}
          let currentIfe = ifeList.data.find(ife => ife.iso === report.args.IFESystem)
          formatedReport.product = 'Portal Manager'
          formatedReport.args.IFESystem = currentIfe.name
          formatedReport.args.FileType = currentIfe.fileTypes.find(ife => ife.iso === report.args.FileType).name
          formatedReport.doneAt = `${ new Date(report.doneAt).toLocaleDateString() } at ${new Date(report.doneAt).toLocaleTimeString()}`
          reports.value.push(formatedReport)
        })
      })
    })
    .catch(err => {
      Log.Error(err)
    })

    const download = (data) => {
      Log.Debug("Download From Button")
      pxstream.tools.downloadFile(http.getCoreApiUrl(data.resultUrl))
    }

    return {
      reports,
      download
    }
  }
}
</script>
