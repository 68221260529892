<template>
  <div>
    <Dialog 
    v-model:visible="showIt" 
    style="width:500px"
    @hide="$emit('update:modelValue', false);resetFields()"
    modal
    dismissableMask
    :closable="false"
    :draggable="false">
      <template #header>
        <div class="flex flex-auto flex-column text-700">
          <i class="pi pi-file-o text-center" style="fontSize: 3em"/>
          <h3 class="text-center">Close cycle "{{currentCycleName}}" <br/> and create a new cycle ?</h3>
        </div>
      </template>

      <Message severity='error' :closable="false">If you close "{{currentCycleName}}", you will no longer be able to modify it.</Message>

      <form class="p-fluid" @keyup.enter="create" >
        <div class="fluid">

          <div class="field grid">
            <label for="cycleName" :class="{'p-error': cycleNameError, 'col-fixed font-bold justify-content-end': true}" style="width:150px">Cycle Name</label>
            <div class="col">
              <InputText id="cycleName" type="text"
                v-model="cycleName"
                :class="{'p-invalid': cycleNameError}"
                :disabled="isLoading"
                />
              <small v-if="cycleNameError" class="p-error">{{cycleNameError}}</small>
            </div>
          </div>

          <div class="field grid">
            <label for="cycleStartDate" :class="{'p-error': cycleStartDateError, 'col-fixed font-bold justify-content-end': true}" style="width:150px">{{ $t('menus.field.cycleStartDate') }}</label>
            <div class="col">
              <Calendar id="cycleStartDate" dateFormat="yy-mm-dd" v-model="cycleStartDate"
                :class="{'p-invalid': cycleStartDateError}" :disabled="isLoading" />
              <small v-if="cycleStartDateError" class="p-error">{{cycleStartDateError}}</small>
            </div>
          </div>

          <div class="field grid">
            <label for="cycleEntryEndDate" :class="{'p-error': cycleEntryEndDateError, 'col-fixed justify-content-end': true}" style="width:150px">{{ $t('menus.field.cycleEntryEndDate') }}</label>
            <div class="col">
              <Calendar id="cycleEntryEndDate" dateFormat="yy-mm-dd" v-model="cycleEntryEndDate"
                :class="{'p-invalid': cycleEntryEndDateError}" :disabled="isLoading" />
              <small v-if="cycleEntryEndDateError" class="p-error">{{cycleEntryEndDateError}}</small>
            </div>
          </div>

          <div class="field grid">
            <label for="cycleEndDate" :class="{'p-error': cycleEndDateError, 'col-fixed font-bold justify-content-end': true}" style="width:150px">Cycle End date</label>
            <div class="col">
              <Calendar id="cycleEndDate" dateFormat="yy-mm-dd" v-model="cycleEndDate" 
                :class="{'p-invalid': cycleEndDateError}" :disabled="isLoading" />
              <small v-if="cycleEndDateError" class="p-error">{{cycleEndDateError}}</small>
            </div>
          </div>


        </div>
      </form>

      <Message v-if="errorCaught" severity="error" :closable="false">
        {{ errorCaught }}
      </Message>

      <template #footer>
        <div class="flex justify-content-between">
          <Button :label="$t('actions.cancel')" icon="pi pi-times" class="p-button-text" @click="showIt = false"/>
          <Button :label="$t('actions.submit')" icon="pi pi-check" :loading="isLoading" @click="closeAndCreate"/>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { ref } from 'vue'
import InputText from "primevue/inputtext"
import Calendar from "primevue/calendar"
import Message from "primevue/message"

import { required, between, minValue, maxValue } from "@vuelidate/validators"
import { useVuelidate } from "@vuelidate/core"

export default {
  name: 'DialogCloseCreateCycle',
  props: ['modelValue', 'currentCycleName', 'menuId'],
  emits: ['update:modelValue', 'newCycleCreated'],
  setup: () => {
    return { 
      cycleName: ref(''),
      cycleStartDate: ref(''),
      cycleEntryEndDate: ref(''),
      cycleEndDate: ref(''),
      menuToDuplicate: ref(null),
      showIt: ref(false),
      isLoading: ref(false),
      errorCaught: ref(''),
      v$: useVuelidate(),
    }
  },
  components: { 
    InputText, Message, Calendar
  },

  methods: {
    resetFields () {
      this.v$.$reset()
      this.errorCaught = ''
      this.cycleName = ''
      this.cycleStartDate = ''
      this.cycleEntryEndDate = ''
      this.cycleEndDate = ''
    },

    closeAndCreate () {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }

      this.errorCaught = ''
      this.isLoading = true

      const { cycleName, cycleStartDate, cycleEntryEndDate, cycleEndDate } = this

      this.$pxstream.portalmanager.closeAndCreateCycle(this.menuId, { 
        cycleName,
        cycleStartDate: this.$pxstream.tools.toSimpleDate(cycleStartDate),
        cycleEntryEndDate: this.$pxstream.tools.toSimpleDate(cycleEntryEndDate),
        cycleEndDate: this.$pxstream.tools.toSimpleDate(cycleEndDate)
      })
      .then(() => {
        this.showIt = false
        this.$emit('newCycleCreated')
      })
      .catch(({message}) => {
        this.errorCaught = message
      })
      .finally(() => {
        this.isLoading = false
      })
    }
  },

  computed: {
    cycleNameError () {
      if (!this.v$.cycleName.$dirty) return ''
      if (this.v$.cycleName.required.$invalid) return 'Cycle name is required'
      return ''
    },
    cycleStartDateError () {
      if (!this.v$.cycleStartDate.$dirty) return ''
      if (this.v$.cycleStartDate.required.$invalid) return 'Cycle start date is required'
      if (this.v$.cycleStartDate.maxValue.$invalid) return 'Cycle start date must be prior to end date'
      return ''
    },
    cycleEndDateError () {
      if (!this.v$.cycleEndDate.$dirty) return ''
      if (this.v$.cycleEndDate.required.$invalid) return 'Cycle end date is required'
      if (this.v$.cycleEndDate.minValue.$invalid) return 'Cycle end date must be after the start date'
      return ''
    },
    cycleEntryEndDateError () {
      if (!this.v$.cycleEntryEndDate.$dirty) return ''
      if (this.v$.cycleEntryEndDate.between.$invalid) return 'Cycle entry end date must be between start and end dates'
      return ''
    },
  },

  validations () {
    return {
      cycleName: { 
        required
      },
      cycleStartDate: {
        required,
        maxValue: maxValue(this.cycleEndDate)
      },
      cycleEntryEndDate: {
        between: between(this.cycleStartDate, this.cycleEndDate)
      },
      cycleEndDate: {
        required,
        minValue: minValue(this.cycleStartDate),
      }
    }
  },

  watch: {
    modelValue: function (val) {
      this.showIt = val
    },
    cycleStartDate: function (newValue, oldValue) {
      if (!oldValue || (this.cycleEndDate && (this.cycleEndDate < newValue))) {
        this.cycleEndDate = new Date(newValue.getFullYear(), newValue.getMonth() + 1, 0)
      }
    }
  }
}
</script>