<template>
  <div id="mediaMore__details">
    <h4>{{ mediaMore.name }}</h4>
    <div class="p-datatable-wrapper">
      <table role="table" class="p-datatable-table">
        <tbody class="p-datatable-tbody" role="rowgroup">

          <div class="p-datatable-separation" />
          <ViewRow label="Cast" field="name" :data="mediaMore.more.media.cast" />
          <ViewRow label="Directors" field="name" :data="mediaMore.more.media.directors" />

          <div class="p-datatable-separation" />
          <ViewRow label="Genres" field="name" :data="mediaMore.more.media.genres" />
          <ViewRatingUSRow :rating="mediaMore.more.media.ratingUS" />
          <ViewRatingRow label="Rating" :rating="mediaMore.more.media.rating" />
          <ViewViolenceRow :data="mediaMore.more.media" />
          <ViewRow label="Year" :data="mediaMore.more.media.year" />
          <ViewRow label="Country" :data="mediaMore.more.media.country.name" />
          <ViewRuntimeRow :runtime="mediaMore.more.media.runtime" />
          <ViewRow label="Version" :data="mediaMore.more.media.version" />

          <div class="p-datatable-separation" />
          <ViewRow label="Licensor" field="name" :data="mediaMore.more.licensor" />
          <ViewRow label="Content Owner" field="name" :data="mediaMore.more.contentOwner" />
          <ViewRow label="Providor" field="name" :data="mediaMore.more.provider" />
          <ViewRow :label="$t('common.copyright')" :data="mediaMore.more.copyright" />

          <div class="p-datatable-separation" />
          <ViewRow label="Mediafiles" :data="mediaMore.nbMediafiles" />

          <div class="p-datatable-separation" />
          <ViewFlagRow :label="$t('medias.tracks')" field="id" :flags="mediaMore.audioTracks" flagWidth="25px" flagHeight="20px"/>
          <ViewFlagRow label="Burned In Sub" field="id" :flags="mediaMore.burnedSubs" flagWidth="25px" flagHeight="20px"/>
          <ViewFlagRow label="Dynamic Sub" field="language.id" :flags="mediaMore.dynamicSubs" flagWidth="25px" flagHeight="20px"/>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ViewRow from '../tableRows/ViewRow.vue'
import ViewRatingRow from '../tableRows/ViewRatingRow.vue'
import ViewFlagRow from '../tableRows/ViewFlagRow.vue'
import ViewViolenceRow from '../tableRows/ViewViolenceRow.vue'
import ViewRuntimeRow from '../tableRows/ViewRuntimeRow.vue'
import ViewRatingUSRow from '../tableRows/ViewRatingUSRow.vue'

export default {
  components: {
    ViewRow,
    ViewRatingRow,
    ViewFlagRow,
    ViewViolenceRow,
    ViewRuntimeRow,
    ViewRatingUSRow
  },
  props: {
    mediaMore: {
      type: Object,
      default: () => {}
    }
  },
}
</script>

<style lang="postcss" scoped>
  h4 {
    font-weight: bold;
  }

  .p-datatable-table * {
    border : 0px !important;
  }

  .p-datatable-separation {
    margin-top: 1rem;
  }
</style>
