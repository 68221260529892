<template>
  <div class="grid" v-if="editorKey">
    <div class="col-12 flex justify-content-end">
      <LanguageSelector v-model="currentLang" :onlyLanguages="languages" :getOnlyID="false" :sort="sort" />
    </div>

    <div class="col-12">
      <div class="field grid">
        <label for="enable" class="col-12 md:col-3 font-bold">{{$t('menus.section.field.enable')}}:</label>
        <div class="col md:col-8">
          <div class="p-fluid">
            <InputSwitch id="enable"
            v-model="enableToggle" />
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="field grid">
        <label for="displayTitle" class="col-12 md:col-3 font-bold">{{$t('menus.section.field.displayTitle')}}:</label>
        <div class="col md:col-8">
          <div class="p-fluid">
            <InputText id="displayTitle" :modelValue="displayTitle"
            v-debounce:300ms="(val)=>(displayTitle = val)" :placeholder="displayTitlePlaceholder" />
            <small v-if="displayTitleError" class="p-error">{{displayTitleError}}</small>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="field grid">
        <label for="type" class="col-12 md:col-3 font-bold">{{$t('menus.section.field.type')}}:</label>
        <div class="col md:col-8">
          <span class="p-fluid capitalize">{{type}}</span>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="field grid align-items-start">
        <label for="description" class="col-12 md:col-3">{{$t('menus.section.field.description')}}:</label>
        <div class="col md:col-8">
          <div class="p-fluid">
            <TextArea id="description" rows="5" cols="30" :modelValue="displayDescription"
            v-debounce:300ms="(val)=>(displayDescription = val)" />
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="field grid">
        <label for="pxapp" class="col-12 md:col-3 font-bold">{{$t('menus.section.field.pxapp')}}:</label>
        <div class="col md:col-8">
          <div class="p-fluid">
            <AutoComplete id="pxapp" :suggestions="[]" dropdown />
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="field grid">
        <label for="microsite" class="col-12 md:col-3 font-bold">{{$t('menus.section.field.microsite')}}:</label>
        <div class="col md:col-8">
          <div class="p-fluid">
            <AutoComplete id="microsite" :suggestions="[]" dropdown />
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="field grid">
        <label for="screen" class="col-12 md:col-3">{{$t('menus.section.field.screen')}}:</label>
        <div class="col md:col-8">
          <div class="p-fluid">
            <AutoComplete id="screen" :suggestions="[]" dropdown />
          </div>
        </div>
      </div>
    </div>


    <div class="col-12">
      <div class="field grid">
        <label for="typeIcon" class="col-12 md:col-3">{{$t('menus.section.field.typeIcon')}}:</label>
        <div class="col md:col-8">
          <Dropdown id="typeIcon" v-model="iconType" :options="iconTypeOptions" optionValue="iso" optionLabel="name" :showClear="true" placeholder="Select icon type" class="w-full" />
        </div>
      </div>
    </div>

    <div class="col-12" v-if="isSvgIconSelected">
      <div class="field grid">
        <label for="svgIcon" class="col-12 md:col-3 font-bold">{{ $t('menus.section.field.svgIcon')}}:</label>
        <div class="col md:col-8 p-fluid">
          <TextArea id="description" rows="5" cols="30" :modelValue="svgIcon" v-debounce:300ms="(val)=>(svgIcon = val)" :disabled="isPreviousCycle" />
          <small v-if="mediaSvgError" class="p-error">{{ mediaSvgError }}</small>
        </div>
      </div>
    </div>

    <div class="col-12" v-if="iconType === 'CSS' ">
      <div class="field grid">
        <label for="ion-icon" class="col-12 md:col-3">{{$t('menus.section.field.ionIcon')}}:</label>
        <div class="col md:col-8">
          <div class="p-fluid">
            <InputText id="cssIcon" :modelValue="cssIcon" v-debounce:300ms="(val)=>(cssIcon = val)" />
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="field grid justify-content-center">
        <div class="grid mt-2">
          <div class="flex justify-content-center mt-4" v-tooltip.top="{ value: $t('menus.section.tooltips.deleteChildrenOrMoveIt'), disabled: !hasChildren }">
            <Button icon="pi pi-minus" :label="$t('menus.section.delete')" class="p-button-outlined"
              :class="hasChildren ? 'pointer-events-none p-button-secondary' : 'p-button-danger'"
              @click="confirmRemove()" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="grid">
    <div class="col-12 flex justify-content-center"><span>{{$t('menus.section.noSectionSelected')}}</span></div>
  </div>
</template>

<script>
import { ref, watch, computed, inject } from 'vue'

import { useFlowEditor } from '@/compositions/useFlowEditor'
import InputSwitch from 'primevue/inputswitch'
import InputText from 'primevue/inputtext'
import AutoComplete from 'primevue/autocomplete'
import Dropdown from 'primevue/dropdown'
import LanguageSelector from '@/components/resource/LanguageSelector.vue'
import { useVuelidate } from '@vuelidate/core'
import { getDirective as vueDebounce } from 'vue-debounce'
import { useI18n } from 'vue-i18n'

const KFlow = "portalmanager:menu-item"

const defaultI18nValue = {displayTitle: '', description: ''}

const componentName = 'EditingSectionPxapp'

export default {
  name: componentName,
  components: { InputSwitch, InputText, AutoComplete, Dropdown, LanguageSelector },
  props: { editorKey: String, hasChildren: Boolean },
  directives: {
    debounce: vueDebounce(3)
  },
  setup (props) {
    const onMediaSectionRemove = inject('onMediaSectionRemove')

    const languages = inject('languages')
    const defaultLanguage = inject('defaultLanguage')

    const currentLang = ref(defaultLanguage.value)
    const iconTypeOptions  = ref(RscTypeIcons)

    const showDeleteDialog = ref(false)
    const { t } = useI18n()

    // hasUpdate, isSaving, fieldGet, fieldSet, fieldPush, fieldSplice, checkHasUpdate, save, set/get/has Error
    const { fieldSet, fieldGet, setError, ...flowEditorAPI } = useFlowEditor(props.editorKey)

    const i18n = computed({
      get () { return fieldGet('i18n') }
    })

    const iconType = computed({
      get () { return fieldGet('iconType') === "" ? null : fieldGet('iconType') },
      set (value) { fieldSet({ field: 'iconType', value }) }
    })

    const isSvgIconSelected = computed ({
      get () { return iconType.value === 'SVG' }
    })

    const svgIcon = computed({
      get () { return fieldGet('svgIcon') },
      set (value) {
        if (isSvgIconSelected.value) {
          fieldSet({ field: 'svgIcon', value })
        }
      }
    })

    const initI18n = () => {
      const i18n = fieldGet('i18n')
      if (!i18n) {
        if (defaultLanguage.value.id === currentLang.value.id) {
          fieldSet({field: 'i18n', value: {
            [currentLang.value.id]: {displayTitle: fieldGet('name'), description: ''}
          }})
        } else {
          fieldSet({field: 'i18n', value: {
            [currentLang.value.id]: JSON.parse(JSON.stringify(defaultI18nValue))
          }})
        }
      } else if (!i18n[currentLang.value.id]) {
        fieldSet({field: `i18n.${currentLang.value.id}`, value: JSON.parse(JSON.stringify(defaultI18nValue))})
      }
    }

    watch(currentLang, initI18n)

    initI18n()

    flowEditorAPI.v$ = useVuelidate({
      i18n: {
        customRequired: (value) => {
          return value[defaultLanguage.value.id]?.displayTitle
        }
      },
      svgIcon: {
        required: (value) => !isSvgIconSelected.value ? true : !!value
      }
    }, { i18n, svgIcon })

    flowEditorAPI.v$.value.$invalid ? setError(new Error('InvalidForm'), componentName) : setError(null, componentName)

    return {
      onMediaSectionRemove,
      languages,
      defaultLanguage,
      currentLang,
      iconType,
      iconTypeOptions,
      isSvgIconSelected,
      svgIcon,
      showDeleteDialog,
      fieldSet,
      fieldGet,
      setError,
      t,
      ...flowEditorAPI
    }
  },
  computed: {
    id: {
      get () { return this.fieldGet('id') }
    },
    name: {
      get () { return this.fieldGet('name') },
      set (value) { this.fieldSet({ field: 'name', value }) }
    },
    enableToggle: {
      get () { return this.fieldGet('isEnabled') },
      set (value) { this.fieldSet({ field: 'isEnabled', value }) }
    },
    displayTitlePlaceholder: { get () { return this.name } },
    displayTitle: {
      get () { return this.fieldGet(`i18n.${this.currentLang.id}.displayTitle`) },
      set (value) {
        this.fieldSet({ field: `i18n.${this.currentLang.id}.displayTitle` , value })
        if (this.currentLang.id === this.defaultLanguage.id) {
          this.name = value
        }
      }
    },
    type: {
      get () { return this.fieldGet('dataType') },
      set (value) { this.fieldSet({ field: 'dataType', value }) }
    },
    displayDescription: {
      get () { return this.fieldGet(`i18n.${this.currentLang.id}.description`) },
      set (value) { this.fieldSet({ field: `i18n.${this.currentLang.id}.description`, value }) }
    },
    cssIcon: {
      get () { return this.fieldGet('cssIcon') },
      set (value) { this.fieldSet({ field: 'cssIcon', value }) }
    },
    displayTitleError () {
      if (this.v$.i18n.customRequired.$invalid) return this.$t('forms.rules.required', {field: `${this.$t('menus.section.field.displayTitle')} (${this.defaultLanguage.name})` })
      return ''
    },
    mediaSvgError () {
      if (this.v$.svgIcon.required.$invalid) return this.t('menus.section.field.svgError')
      return ''
    }
  },
  methods: {
    confirmRemove () {
      this.$confirm.require({
        message: this.$t('menus.section.remove.message'),
        header: this.$t('menus.section.remove.header', {name: this.name}),
        acceptLabel: this.$t('actions.yes'),
        rejectLabel: this.$t('actions.no'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.onMediaSectionRemove(this.id, KFlow)
        },
        reject: () => {}
      })
    },
    sort (a, b) {
      return this.languages.findIndex(val => val === a.id) - this.languages.findIndex(val => val === b.id)
    }
  },
  watch: {
    'v$.$invalid': function (val) {
      val ? this.setError(new Error('InvalidForm'), componentName) : this.setError(null, componentName)
    }
  }
}

const RscTypeIcons = [
  { iso: 'ImageFile', name: 'Image File' },
  { iso: 'CSS', name: 'CSS'},
  { iso: 'SVG', name: 'SVG'}
]
</script>
