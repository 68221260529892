import axios from 'axios'
import router from '@/router.js'
import { auth } from '@/plugins/auth'

const isAuthRequest = (url) => {
  const requestUrl = url || ''
  return requestUrl.includes('/session/me')
    || requestUrl.includes('/session/logout')
    || requestUrl.includes('/session/login')
}

axios.interceptors.response.use(
  function (response) {
    return response;
  }, 
  async function (error) {
    const currentRoute = router.currentRoute.value
    if (error.response && error.response.status === 401
      && !isAuthRequest(error.response.config.url)) {
      await auth.logout()
      router.replace({
        name: 'AccountLogin', query: {
        redirect: currentRoute.fullPath
      } })
    }
    return Promise.reject(error);
  }
)

function getCoreApiUrl (url) {
  return '/core' + url
}

function getStorageApiUrl (url) {
  return '/storage' + url
}

function getHttpErrorShortMessage (error) {
  let res = ''
  if (error) {
    res = error.toString()
    if (error.response?.data?.error) {
      const elements = error.response.data.error.split(': ')
      const lastElement = elements[elements.length-1]
      if (lastElement) {
        res = lastElement
      }
    }
  }
  return res
}

const plugin = {
  axios: axios,
  getCoreApiUrl: getCoreApiUrl,
  getStorageApiUrl: getStorageApiUrl,
  getHttpErrorShortMessage: getHttpErrorShortMessage, 
  core: {
    get (url) {
      // console.log(`[GET] ${url} - ${JSON.stringify(buildCfg(cfg))}`)
      const u = getCoreApiUrl(url)
      return axios.get(u)
    },
    delete (url) {
      // console.log(`[DELETE] ${url}`)
      const u = getCoreApiUrl(url)
      return axios.delete(u)
    },
    put (url, data) {
      // console.log(`[PUT] ${url}`)
      const u = getCoreApiUrl(url)
      return axios.put(u, data)
    },
    post (url, data) {
      // console.log(`[POST] ${url}`)
      const u = getCoreApiUrl(url)
      return axios.post(u, data)
    }
  },
  storage: {
    get (url) {
      // console.log(`[GET] ${url} - ${JSON.stringify(buildCfg(cfg))}`)
      const u = getStorageApiUrl(url)
      return axios.get(u)
    },
    delete (url) {
      // console.log(`[DELETE] ${url}`)
      const u = getStorageApiUrl(url)
      return axios.delete(u)
    },
    put (url, data) {
      // console.log(`[PUT] ${url}`)
      const u = getStorageApiUrl(url)
      return axios.put(u, data)
    },
    post (url, data) {
      // console.log(`[POST] ${url}`)
      const u = getStorageApiUrl(url)
      return axios.post(u, data)
    }
  }
}

export default plugin
